export default class StateLoader {

    loadState() {
        try {
            let serializedState = localStorage.getItem("didoReducer");

            //console.log("load state" + serializedState)

            if (serializedState === null) {
                return this.initializeState();
            }

            return {
                didoReducer: JSON.parse(serializedState)};
        }
        catch (err) {
            return this.initializeState();
        }
    }

    saveState(state) {
        try {
            //console.log("save state " + JSON.stringify(state.didoReducer))

            let serializedState = JSON.stringify(state.didoReducer);
            localStorage.setItem("didoReducer", serializedState);

        }
        catch (err) {
        }
    }

    initializeState() {
        return {
            didoReducer: {
                currentTeam: null,
                currentOrg: null
            }
        }
    };
}