import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import { FormRow } from './../../components/FormRow';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export class UnitDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',            
            selectedZpGuid: null,
            selectedZp: null
        };
    }

    handleNameChange = (evt) => this.setState({ name: evt.currentTarget.value });
    currentName = () => this.state.name ?
        this.state.name :
        this.props.editableItem ?
            this.props.editableItem.Name :
            ''

    handleChangeDropDown = async (event) => {
        await this.setState({ selectedZpGuid: event.target.value });
        console.log(`New selected zp ${this.state.selectedZpGuid} is ${JSON.stringify(this.props.profiles.find(s => s.Id === this.currentZpGuid()))}`)
    }

    currentZpGuid = () => this.state.selectedZpGuid ?
        this.state.selectedZpGuid :
        (this.props.editableItem && this.props.editableItem.ZorgProfile) ?
            this.props.editableItem.ZorgProfile.Id :
            this.props.profiles.length > 0 ?
                this.props.profiles[0].Id :
                ""

    handleOkClick = async () => {
        if (!this.state.selectedZpGuid) {
            await this.setState({ selectedZpGuid: this.currentZpGuid() })
        }
        if (!this.state.selectedZp){    
                    
            await this.setState({ selectedZp : this.props.profiles.find(s => s.Id === this.currentZpGuid())})
            console.log("On ok" + JSON.stringify(this.state.selectedZp))
        }
        if (!this.state.name){
            await this.setState({name: this.currentName()})
        }
        await this.setState({type: this.props.type});
        this.props.dialogAction(false, this.state);
        this.setState({ selectedZpGuid: null, selectedZp: null, name: null })
    }

    onCancelClick = () => {
        this.props.dialogAction(true);
        this.setState({ selectedZpGuid: null, selectedZp:null, name: null })
    }

    render = () => {
        
        return (
            <Dialog open={this.props.open}>
                <DialogTitle>
                <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.editableItem ? "Kamer bewerken" : "Nieuwe kamer"}</Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormRow>
                            <TextField
                                name="Name"
                                required
                                label="Naam"
                                defaultValue={this.currentName()}
                                onChange={this.handleNameChange} /><br />
                        </FormRow>
                        <FormRow>
                            <Select
                            fullWidth
                                value={this.currentZpGuid()}
                                onChange={(e) => this.handleChangeDropDown(e)}>
                                {this.props.profiles.map((profile) => {
                                    return (
                                        <MenuItem key={profile.Id} value={profile.Id}>{profile.Name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormRow>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleOkClick()} color="primary">OPSLAAN</Button>
                    <Button onClick={() => this.onCancelClick()} color="primary">TERUG</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
