import React from 'react';
import * as Api from '../services/api/common-api'
import { TOKEN_KEY } from '../constants/common';

class LoginCallback extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            previousSignInData: null
        };
    }

    render() {
        let params = new URLSearchParams(this.props.location.search)
        const data = { token: params.get('token').replace(/\s/g, '+'), email: params.get('email') };
        if (this.state.previousSignInData !== JSON.stringify(data)) {
            this.setState({ previousSignInData: JSON.stringify(data) });

            Api.signInByInvite(data)
                .then(response => {
                    if (response.status === 200) {
                        localStorage.setItem(TOKEN_KEY, response.data);
                        window.location = '/';
                    }
                    else {
                        window.location = '/login';
                    }
                })
                .catch(e => { window.location = '/login' });
        }
        return (
            <h2 style={{ textAlign: 'center', marginTop: '30vh' }}>Uw e-mailtoken verifiëren…</h2>
        );
    }
}

export default LoginCallback;