import '@react-page/editor/lib/index.css';
import '@react-page/plugins-slate/lib/index.css';
import '@react-page/plugins-image/lib/index.css';
import '@react-page/plugins-html5-video/lib/index.css';
import '@react-page/plugins-spacer/lib/index.css';
import '@react-page/plugins-video/lib/index.css';
import '@react-page/plugins-divider/lib/index.css';

import React from 'react';
import Editor from '@react-page/editor';
import slate from '@react-page/plugins-slate';
import {imagePlugin, ImageUploadType} from '@react-page/plugins-image';
import video from '@react-page/plugins-video';

import * as Api from '../services/api/common-api'
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import DidoDrawer from './../components/Drawer';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { NEW_INFO_PAGE_URL, SNACK_SUCCESS } from './../constants/common';
import background, { ModeEnum } from '@react-page/plugins-background';
import divider from '@react-page/plugins-divider';
import html5video from '@react-page/plugins-html5-video';
import spacer from '@react-page/plugins-spacer';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Paper, Typography} from '@mui/material';
import { FormRow } from '../components/FormRow';



const imageUploadFunction = (defaultUrl) => {  
  return (file, reportProgress) => {    
    console.log(file)
    return new Promise(resolve => {
      const formData = new FormData();
      formData.append("FormFile", file)
      Api.uploadImageForPage(formData)
      .then(res => resolve({ url: res.data }))
      .catch(err => Api.logger.error(`Error uploading image`, err))
    });

  };
}

const plugins = [
  slate(), 
  imagePlugin({imageUpload : imageUploadFunction('https://upload.wikimedia.org/wikipedia/commons/a/a8/Derbys_Peter_Pan_peanut_butter_sample_blikje%2C_foto3.JPG')}),
  video,
  html5video,
  background({
    //imageUpload: fakeImageUploadService('/images/sea-bg.jpg'),
    enabledModes:
      ModeEnum.COLOR_MODE_FLAG |
      //ModeEnum.IMAGE_MODE_FLAG |
      ModeEnum.GRADIENT_MODE_FLAG,
  }),
  spacer,
  divider,
];

class InfoPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: null,
      previousContent: null,
      readOnly: !this.isNewPage(),
      loading: false,
      title: 'New page title',
      url: '/page_'+ Math.random().toString(16).slice(2),
      id: null,
      urlVerifyText: ''
    };
  }

  isNewPage = () => window.location.pathname === NEW_INFO_PAGE_URL

  componentDidMount = () => {
    if (!this.isNewPage()) {
      Api.getContentByUrl(window.location.href)
        .then(async (response) => {
          const parsed = JSON.parse(response.data.Content);
          this.setContent(parsed);
          await this.setState({ title: response.data.Title, url: response.data.Url, id: response.data.Id });
        })
        .catch(err => {
          this.setState({ loading: false })
          Api.logger.errorWithSnackbar(`Error get content of page`, this, err)
        });
    }
  }

  setContent = (v) => this.setState({ content: v });

  onEditClick = async (e) => {
    await this.setState({ readOnly: false, previousContent: this.state.content })
  };

  onSaveClick = (e) => {
    if (this.state.urlVerifyText.length > 0) { // validate
      console.log("validate fail")
      return
    }

    this.setState({ readOnly: true, loading: true });
    var params = {
      id: this.state.id,
      url: this.state.url,
      title: this.state.title,
      content: JSON.stringify(this.state.content)
    };

    var promise = this.isNewPage() ? Api.createInfoPage : Api.updateInfoPage;

    promise(params)
      .then((res) => {
        if (res.data) {
          this.props.enqueueSnackbar("Page has been saved", SNACK_SUCCESS)          
          if (window.location.pathname !== this.state.url) {            
            this.props.history.replace(this.state.url)
            this.props.loadRoutes();            
          }
        }
      })
      .catch(err => Api.logger.errorWithSnackbar(`Error on save page`, this, err))
      .finally(() => this.setState({ loading: false }));

  }

  onCancelClick = (e) => { this.setState({ readOnly: true, content: this.state.previousContent }) }

  onTitleChange = async (event) => {
    await this.setState({ title: event.target.value });
  }

  validateUrl = (url) => {
    return (url === NEW_INFO_PAGE_URL) || 
    (url !== window.location.pathname && this.props.allRoutes.includes(url)) ?
      `Url ${url} already exists` : '';
  }

  onUrlChange = async (event) => {
    await this.setState({ url: event.target.value, urlVerifyText:  this.validateUrl(event.target.value.toLowerCase()) });
  }

  render() {
    return (
      <div style={{  marginTop: '10vh' }}>
        <DidoDrawer style={{ zIndex: 2000 }} pageTitle={this.state.title} isInfoPage controlsFragment={
          this.props.currentUser?.IsSystemAdmin ?
            this.state.readOnly ?
              (<Fab style={{ backgroundColor: '#4AB897', zIndex: 1200, color: 'white', right: '8vw', top: '3vh', position: 'fixed' }} aria-label="add">
                <EditIcon onClick={this.onEditClick} />
              </Fab>)
              :
              <>
                <Fab style={{ backgroundColor: '#4AB897', zIndex: 1200, color: 'white', right: '10vw', top: '3vh', position: 'fixed' }} aria-label="add">
                  <SaveIcon onClick={this.onSaveClick} />
                </Fab>
                <Fab style={{ backgroundColor: '#AAA', zIndex: 1200, color: 'white', right: '6vw', top: '3vh', position: 'fixed' }} aria-label="add">
                  <CloseIcon onClick={this.onCancelClick} />
                </Fab>
              </>
            : <></>
        } />
        {!this.state.readOnly &&
          <Grid spacing={3} container sx={{ paddingBottom: '2vw' }}>
            <Grid xs={3} item></Grid>
            <Grid xs={6} item>
              <Paper sx={{
                textAlign: 'left', padding: '1vw'
              }}>
                <Typography variant="h6">Page meta information</Typography>
                <FormRow>
                  <TextField
                    fullWidth
                    name="Title"
                    required
                    label="Title"
                    value={this.state.title}
                    onChange={this.onTitleChange}
                    disabled={this.state.readOnly}
                  />
                </FormRow>
                <FormRow sx={{ marginTop: '2vh' }}>
                  <TextField
                    fullWidth
                    name="Url"
                    required
                    label="Url (should be unique pattern, like /pagename)"
                    value={this.state.url}
                    onChange={this.onUrlChange}
                    disabled={this.state.readOnly}
                    helperText={this.state.urlVerifyText}
                    error={this.state.urlVerifyText.length > 0}
                  />
                </FormRow>
              </Paper>
            </Grid>
            <Grid xs={3} item></Grid>
          </Grid>
        }
        <div style={{textAlign: 'center', marginTop: '2vh'}}>
        <Editor
          cellPlugins={plugins}
          value={this.state.content}
          onChange={this.setContent}
          //lang="nl"
          readOnly={this.state.readOnly}
        />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ currentUser: state.didoReducer.currentUser })

export default connect(mapStateToProps)(withSnackbar(InfoPage));