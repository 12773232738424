import React from 'react';
 import * as Api from '../services/api/common-api'
 import Select from '@mui/material/Select';
 import MenuItem from '@mui/material/MenuItem';
 import { connect } from "react-redux";
 import { withSnackbar } from 'notistack';

class DomainDropDown extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {  domains: [], currentDomainGuid: '' };
    }

    async componentDidMount() {
        if (this.props.currentUser.IsSystemAdmin){
            this.getAllDomains();
        }
        else{
            await this.setState({ currentDomainGuid: this.props.currentUser.CurrentDomainGuid })
            this.props.onDomainSelected(this.props.currentUser.CurrentDomainGuid);
        }
    }

    getAllDomains = () => Api.getDomainsWithoutChilds()
    .then(res => {
        this.setState({ domains: res.data, currentDomainGuid: res.data[0].Id })
        this.props.onDomainSelected(res.data[0].Id);
    })
    .catch(err =>Api.logger.errorWithSnackbar(`Error in getAllDomains`,this, err))


    onChangeDomain = async (event) => {        
        await this.setState({currentDomainGuid : event.target.value});
        this.props.onDomainSelected(event.target.value)
    }


    render(){
        
        let minWidth = '30vw';
        if (window.innerWidth < 900)
        minWidth = '70vw'

        return (
            <Select autoWidth style={{ marginLeft: '1vw', minWidth: minWidth }}
                value={this.state.currentDomainGuid}
                onChange={(e) => this.onChangeDomain(e)}
                >
                {this.state.domains.map((d) => <MenuItem key={d.Id} value={d.Id}>{d.Name}</MenuItem>)}
            </Select>
        )
    }
}

const mapStateToProps = (state) =>  ({ currentUser: state.didoReducer.currentUser })

//export default connect(mapStateToProps)(DomainDropDown);
export default connect(mapStateToProps)(withSnackbar(DomainDropDown));
//export default DomainDropDown;