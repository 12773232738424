import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { FormRow } from './../../components/FormRow';
import  Grid  from '@mui/material/Grid';
import  IconButton  from '@mui/material/IconButton';
import  CloseIcon  from '@mui/icons-material/Close';

export class ActivitiesDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            editableItem: this.props.editableItem
        };
    }

    nameChange = (evt) => this.setState({ name: evt.currentTarget.value });
    onCancelClick = () => this.props.dialogAction(true)
    render = () => {

        return (
            <Dialog open={this.props.open}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.actionTitle}</Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormRow>
                        <TextField
                            name="Name"
                            required
                            label="Naam"
                            defaultValue={(this.props.editableItem) ? this.props.editableItem.Name : ""}
                            onChange={this.nameChange} />
                            </FormRow>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.dialogAction(false, { name: this.state.name, editableItem: this.props.editableItem, type: this.props.type })} color="primary">OPSLAAN</Button>
                    <Button onClick={() => this.props.dialogAction(true)} color="primary">TERUG</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
