import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export default function DeleteDialog(props) {
    return (
        <Dialog
        open={props.open}
        onClose={() => props.onClose(false)}
      >
        <DialogTitle>Delete activity?</DialogTitle>
        <DialogContent>
          <DialogContentText >
              {props.text}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClose(true)} color="primary">
          Delete
          </Button>
          <Button onClick={() => props.onClose(false)} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
}