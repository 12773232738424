import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { KanbanTaskCard } from './KanbanTaskCard';

export class KanbanTaskCardDraggable extends React.Component {

    render() {
        return (
            <Draggable key={this.props.task.Id} draggableId={this.props.task.Id} index={this.props.index}>
                {(provided, snapshot) => (
                    <KanbanTaskCard 
                    key={this.props.task.Id}
                    provided={provided} 
                    snapshot={snapshot} 
                    {...this.props} 
                    />
                )}
            </Draggable>
        );
    }
}
