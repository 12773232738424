/* eslint-disable default-case */
import * as Api from '../../services/api/common-api'
import { SNACK_SUCCESS } from '../../constants/common';
import { SNACK_WARNING } from '../../constants/common';
import { OrgStructType } from '../../constants/OrgStructConst';

export const MenuItemAddOrg = function (owner) {
    this.key = 'addOrg';
    this.title = 'Add new organization';

    this.handle = (param) => {
        Api.createOrganization(param).then((res) => {
            owner.props.enqueueSnackbar("Organisatie is toegevoegd", SNACK_SUCCESS);
            res.data.Visible = true;
            res.data.type = OrgStructType.Location;
            owner.state.topItems.push(res.data)
            owner.forceUpdate()
        })
        .catch(err => Api.logger.errorWithSnackbar(`Error while add Org`, owner, err));
    };

    this.onDialogClose = (dialogResult) => onOrgLocUnitClose(dialogResult, owner)

    this.openDialog = (item) => Api.getSubscriptions().then(result => owner.setState({ editableItem: null, dialogOpen: 0, subscriptions: result.data }))
        .catch(err => Api.logger.errorWithSnackbar(`Error getSubscriptions on add`, owner, err));
}


export const MenuItemAddLocation = function (owner) {
    this.key = 'addLocation';
    this.title = 'Voeg locatie toe'
    this.handle = (param) => {
        Api.createLocation(param).then((res) => {
            owner.props.enqueueSnackbar("Locatie is toegevoegd", SNACK_SUCCESS);
            if (owner.state.parentItem.IconExpanded){
                res.data.Visible = true;
                res.data.type = OrgStructType.Location;
                res.data.parent = owner.state.parentItem;
                owner.state.parentItem.Childs.push(res.data);
                owner.forceUpdate()
            }            
        })
    }
    this.onDialogClose = (dialogResult) => onOrgLocUnitClose(dialogResult, owner)
    this.openDialog = (item) => owner.setState({ editableItem: null, actionTitle: this.title, dialogOpen: 1, parentItem: item })    
}

export const MenuItemAddTeam = (_owner) => ({
    key: 'addTeam',
    title: 'Team toevoegen',
    handle: (param) => Api.createTeamWithUpload(param).then((res) => {
        _owner.props.enqueueSnackbar("Team is toegevoegd", SNACK_SUCCESS);
        if (_owner.state.parentItem.IconExpanded) {
            res.data.Visible = true;
            res.data.type = OrgStructType.Team;
            res.data.parent = _owner.state.parentItem;
            _owner.state.parentItem.Childs.push(res.data);
            _owner.forceUpdate()
        }
    }),

    openDialog: (item) => _owner.setState({ editableItem: null, dialogOpen: 2, parentItem: item }),

    onDialogClose : (dialogResult) => onTeamDialogClose(dialogResult, _owner)

})

export const MenuItemAddUnit = (_owner) => ({
    key: 'addUnit', 
    title: 'Kamer toevoegen', 
    handle: (param) => Api.createUnit(param).then((res) => {
        _owner.props.enqueueSnackbar("Kamer is toegevoegd", SNACK_SUCCESS);
        //console.log(res);
        if (_owner.state.parentItem.IconExpanded) {
            res.data.Visible = true;
            res.data.type = OrgStructType.Unit;
            res.data.parent = _owner.state.parentItem;
            _owner.state.parentItem.Childs.push(res.data);
            _owner.forceUpdate()
        }
    }),
    openDialog: (item) => Api.getAvailableZpForTeam(item.Id)
        .then(result => _owner.setState({ editableItem: null, dialogOpen: 3, parentItem: item, profiles: result.data }))
        .catch(err => Api.logger.errorWithSnackbar(`Error getAvailableZpForTeam`, _owner, err)),
    
    onDialogClose : (dialogResult) => onOrgLocUnitClose(dialogResult, _owner)
})

function onTeamDialogClose(dialogResult, _owner) {
    //_owner.state.currentContextMenuItem.onDialogClose(dialogResult)
    const formData = new FormData();
    formData.append("FormFile", dialogResult.file)
    formData.append("Name", dialogResult.name)
    formData.name = dialogResult.name
    formData.file = dialogResult.file
    formData.preview = dialogResult.preview;

    if (_owner.state.editableItem) {
        formData.append("Id", _owner.state.editableItem.Id)
        formData.type = OrgStructType.Team
    }

    if (_owner.state.parentItem) {
        formData.append("ParentId", _owner.state.parentItem.Id);
    }

    _owner.state.currentContextMenuItem.handle(formData)
}

function onOrgLocUnitClose(dialogResult, _owner){
    let param = {
        name: dialogResult.name,
    }
    if (dialogResult.type === OrgStructType.Organization) { // org            
        param.Subscription = dialogResult.selectedSubscription
        param.Domain = {Id: _owner.state.currentDomainGuid}
        param.IsChatBotEnabled = dialogResult.isChatBotEnabled
    }

    if (dialogResult.type === OrgStructType.Unit) // unit
    {
        
        param.ZorgProfile = dialogResult.selectedZp; //{ Id: dialogResult.selectedZpGuid }
    }

    if (_owner.state.editableItem) {
        param.id = _owner.state.editableItem.Id
        param.type = _owner.state.editableItem.type;
    }
    if (_owner.state.parentItem) {
        param.ParentId = _owner.state.parentItem.Id;
    }


    _owner.state.currentContextMenuItem.handle(param)
}


export const MenuItemEditOrg = (_owner) => ({
    key: 'edit', title: 'Bewerk',
    handle: (param) => {
        Api.updateOrganization(param).then(async (res) => {
            _owner.state.editableItem.Name = param.name;
            _owner.state.editableItem.Subscription = param.Subscription
            _owner.state.editableItem.IsChatBotEnabled = param.IsChatBotEnabled
            _owner.forceUpdate();
        });
    },
    onDialogClose : (dialogResult) => onOrgLocUnitClose(dialogResult, _owner),
    openDialog: (item) => Api.getSubscriptions().then(result => _owner.setState({ editableItem: item, dialogOpen: 0, subscriptions: result.data })).catch(err => Api.logger.error(`Error getSubscriptions on edit`, _owner, err))
})

export const MenuItemDialogTree = (_owner) => ({
    key: 'dialogTree', title: 'Assistant dialog tree',    
    openDialog: (item) => window.location.href = '/dialogTree?orgId=' + item.Id + '&orgName=' + item.Name
})

export const MenuItemEditLocation = (_owner) => ({
    key: 'edit', title: 'Bewerk',
    handle: (param) => {
        Api.updateLocation(param).then(async (res) =>{
            _owner.state.editableItem.Name = param.name;
            _owner.forceUpdate();
        });
    },
    onDialogClose : (dialogResult) => onOrgLocUnitClose(dialogResult, _owner),
    openDialog: (item) => _owner.setState({ editableItem: item,  actionTitle: item.type +' bewerken', dialogOpen: 1 })
})

export const MenuItemEditTeam = (_owner) => ({
    key: 'edit', title: 'Bewerk',
    handle: (param) => {
         Api.updateTeamWithUpload(param).then(async (res) =>{
            //  console.log(res)
            //  console.log(param)
                _owner.state.editableItem.Name = res.data.Name;
                _owner.state.editableItem.Avatar = param.preview !== null ? param.preview : res.data.Avatar;
                _owner.forceUpdate();
            });
    },
    onDialogClose: (dialogResult) => onTeamDialogClose(dialogResult, _owner),
    openDialog: (item) => _owner.setState({editableItem : item, dialogOpen: 2})
})

export const MenuItemEditUnit = (_owner) => ({
    key: 'edit', title: 'Bewerk',
    handle: (param) => {
        Api.updateUnit(param).then(async (res) =>{
            _owner.state.editableItem.Name = param.name;
            _owner.state.editableItem.ZorgProfile = param.ZorgProfile
            _owner.forceUpdate();
        });
    },
    onDialogClose : (dialogResult) => onOrgLocUnitClose(dialogResult, _owner),
    openDialog: (item) => Api.getAvailableZpForTeam(item.parent.Id).then(result => _owner.setState({editableItem : item, dialogOpen: 3, profiles: result.data})).catch(err =>Api.logger.error(`Error getAvailableZpForTeam on edit unit`, _owner, err))
})

export const MenuItemDelete = (_owner) => ({
    key: 'delete', title: 'Verwijderen', 
    
    handle: (item) => {
        //console.log(item.Childs)
            if (item.Childs == null || item.Childs.length === 0) {

            let promise;
            switch (item.type) {
                case OrgStructType.Organization:
                    promise = Api.deleteOrganization; break;//(item.Id).then(res => res.data === true && _owner.setState({ orgs: _owner.state.orgs.filter(o => o.Id !== item.Id) })); break;
                case OrgStructType.Location:
                    promise = Api.deleteLocation; break;
                case OrgStructType.Team:
                    promise = Api.deleteTeam; break;
                case OrgStructType.Unit:
                    promise = Api.deleteUnit; break;
            }
            //console.log(promise)
            return promise(item.Id).then(res => {
                if (res.data === true) {
                    _owner.props.enqueueSnackbar("Succesvol verwijderd", SNACK_SUCCESS);
                    if (item.parent) {
                        item.parent.Childs = item.parent.Childs.filter(l => l.Id !== item.Id)
                    }
                    else {
                        _owner.setState({ topItems: _owner.state.topItems.filter(o => o.Id !== item.Id) })
                    }
                    _owner.forceUpdate()
                }
                else _owner.props.enqueueSnackbar('Item is niet verwijderd ' + item.Name, SNACK_WARNING)
            }).catch(err => Api.logger.errorWithSnackbar(`Error on action handle on delete`, _owner, err));
        }
        else {
            _owner.props.enqueueSnackbar('Verwijder eerst de onderliggende gegevens ' + item.Name, SNACK_WARNING)
        }
    }
})
