import { Card, CardContent } from '@mui/material';
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import ThemeDialog from './Dialog/ThemeDialog'
import Container from '@mui/material/Container';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { connect } from "react-redux";
import actions from "../actions.js";
import { withSnackbar } from 'notistack';
import { SNACK_SUCCESS } from './../constants/common';
import { Heart } from 'react-spinners-css';
import { BasePage } from './BasePage';
import { getBootstrapWidthXs } from '../utils';

class ThemePage extends BasePage {
    
    componentDidMount() {
        this.updateThemesList();
    }


    updateThemesList = () => this.getItems(Api.getThemes, null, res => this.setState({ items: res.data}), `Fout bij ophalen van thema's` );

    deleteTheme = (id) => this.deleteItem(id, Api.deleteTheme, () => {this.updateThemesList(this.state.currentDomainGuid)}, "Succesvol verwijderd", "Error on delete theme")

    renderTheme(theme) {
        let width = getBootstrapWidthXs(this.state.width)
        return (
            <Grid item xs={width} key={theme.Id}>
                <Card elevation={3}>
                    <CardContent>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={2}>
                                <Avatar src={theme.Avatar} style={{ width: 48, height: 48 }} />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}><Typography variant='h6'>{theme.Name}</Typography></Grid>
                        </Grid>
                    </CardContent>
                    <CardActions disableSpacing style={{ borderTop: '1px #EEE solid', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="Bewerk" onClick={() => this.showDialog(theme)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton aria-label="Delete" onClick={() => this.deleteTheme(theme.Id)}>
                            <DeleteIcon />
                        </IconButton>
                    </CardActions>
                </Card>
            </Grid>
        );
    }

    render() {
        return (
            <div>
                <DidoDrawer pageTitle="Verbeter thema's" onAddFabClick={() => this.showDialog()} />

                {this.state.loading ? <div align="center" style={{ marginTop: '300px' }}><Heart color="#4AB897" size={100} /></div> :
                    <Container maxWidth="xl" style={{ marginTop: this.props.currentUser?.IsSystemAdmin ? '140px' : '75px' }}>
                        <Grid spacing={3} container>
                            {
                                this.state.items.map(theme => this.renderTheme(theme))
                            }
                        </Grid>
                        <ThemeDialog
                            open={this.state.dialogOpen}
                            onDialogClosed={this.onDialogClosed}
                            currentItem={this.state.currentItem}
                        />
                    </Container>
                }

            </div>);
    }

    onDialogClosed = (dialogResult) => {

        this.setState({ dialogOpen: false });
        
        //console.log(dialogResult.isCanceled)
        if (dialogResult.isCanceled){
            return;
        }       

        const formData = new FormData();
        console.log(dialogResult.file)
        if (dialogResult.file != null) // was changes
            formData.append("FormFile", dialogResult.file)

        formData.append("Name", dialogResult.name)
        formData.append("OrganizationGuid", this.props.currentOrg) 
        
        let promise; 

        if (!this.state.currentItem) { // dialog for new             
            promise = Api.createThemeWithUpload(formData);
        }
        else {
            formData.append("Id", this.state.currentItem.Id)
            promise = Api.updateThemeWithUpload(formData);
        }

        promise.then(() => {
            this.props.enqueueSnackbar("Wijzigingen zijn toegepast", SNACK_SUCCESS);
            this.updateThemesList()}).catch(err =>Api.logger.errorWithSnackbar(`Error on edit theme`,this, err));
    }
}


export default connect((state)=>{    
    return {        
        currentOrg: state.didoReducer.currentOrg
    }}, actions)(withSnackbar(ThemePage));