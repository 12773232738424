/* eslint-disable default-case */
import React from 'react';
import * as Api from '../../services/api/common-api'
import DidoDrawer from '../../components/Drawer'
import Container from '@mui/material/Container';
import { ContextMenu } from '../../components/ContextMenu';
import { ActivitiesDialog } from '../Dialog/ActivitiesDialog';
import { withSnackbar } from 'notistack';
import '../../../node_modules/font-awesome/css/font-awesome.min.css'
import { OrgDialog } from '../Dialog/OrgDialog';
import { UnitDialog } from '../Dialog/UnitDialog';
import { TeamDialog } from '../Dialog/TeamDialog';
import { connect } from "react-redux";
import {MenuItemAddOrg, MenuItemAddLocation, MenuItemAddTeam, MenuItemAddUnit, 
    MenuItemEditOrg, MenuItemEditLocation, MenuItemEditTeam, MenuItemEditUnit, MenuItemDelete, MenuItemDialogTree} from './MenuItems';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import DomainDropDown from '../../components/DomainDropDown';
import DidoHeart from '../../components/DidoHeart';
import OrgItem from '../../components/OrgItem';
import { OrgStructType } from '../../constants/OrgStructConst';
import { debounce } from "debounce";
import { Checkbox, FormControlLabel, TextField, Grid, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';

const pageSize = 15;

class OrgStructPage extends React.Component {

    

    constructor(props) {
        super(props);
        this.state = { 
            topItems: [], topLevel: 0, 
            dialogOpen : -1, 
            domains: [],currentDomainGuid: '',
            subscriptions:[], profiles: [], anchorEl : null, contextMenuOptions:[], editableItem: null, width: window.innerWidth,
            namePattern: null, isFreemium: null, currentPage: 1, totalCount: 1};

            this.contextMenuItemAddOrg = new MenuItemAddOrg(this)
            this.contextMenuItemAddLocation = new MenuItemAddLocation(this)
            this.contextMenuItemAddTeam = MenuItemAddTeam(this)
            this.contextMenuItemAddUnit = MenuItemAddUnit(this)

            this.contextMenuItemEditOrg = MenuItemEditOrg(this)
            this.contextMenuItemEditLocation = MenuItemEditLocation(this)
            this.contextMenuItemEditTeam = MenuItemEditTeam(this)
            this.contextMenuItemEditUnit = MenuItemEditUnit(this)

            this.contextMenuItemDelete = MenuItemDelete(this)

            this.contextGoToDialogTree = MenuItemDialogTree(this)

            this.contextMenuTree = [
                [this.contextMenuItemAddLocation, this.contextMenuItemEditOrg, this.contextMenuItemDelete, this.contextGoToDialogTree],
                [this.contextMenuItemAddTeam, this.contextMenuItemEditLocation, this.contextMenuItemDelete],
                [this.contextMenuItemAddUnit, this.contextMenuItemEditTeam, this.contextMenuItemDelete],
                [this.contextMenuItemEditUnit, this.contextMenuItemDelete]
            ]
    }

    componentWillMount() {       
        window.addEventListener('resize', this.onWindowSizeChange);
    }

    async componentDidMount() {        
        if (this.props.currentUser && this.props.currentUser.IsSystemAdmin) {
            this.getAllDomains();
        }
        else if (this.props.currentUser.IsOrgAdmin) {
            await this.setState({ currentDomainGuid: this.props.currentUser.CurrentDomainGuid });
            this.updateOrgList();
        }
        else if (this.props.currentUser.BelongsToTeam){
            var team =  this.props.currentUser.BelongsToTeam;
            team.Visible = true;
            team.type = OrgStructType.Team;
            this.setState({topItems: [team], topLevel: 2})
            this.updateUnitsList(this.props.currentUser.BelongsToTeam);
        }
    }

    getAllDomains = () => Api.getDomainsWithoutChilds()
    .then(res => {
        this.setState({ domains: res.data, currentDomainGuid: res.data[0].Id })
        this.updateOrgList();
    })
    .catch(err =>Api.logger.errorWithSnackbar(`Error get domains`, this, err));

    onDomainUpdated = async (newDomainGuid) => {        
        await this.setState({currentDomainGuid : newDomainGuid});
        this.updateOrgList();
    }   

    updateOrgList = () => {
        this.setState({loading: true})
        const filter = {
            domainId : this.state.currentDomainGuid, 
            isFreemium: this.state.isFreemium === true, 
            namePattern: this.state.namePattern, 
            pageNumber: this.state.currentPage,
            pageSize: pageSize
        };

        Api.getOrganizationsByFilter(filter).then(res => {
            console.log(res)
            res.data.PageData.forEach(item => item.type = OrgStructType.Organization)
            
            this.setState({ topItems: res.data.PageData, totalCount: res.data.TotalCount });
        })
        .catch(err => Api.logger.errorWithSnackbar(`Error update org list`, this, err))
        .finally(() => this.setState({loading: false}));
    }

    updateUnitsList = (item) => {
        this.setState({loading: true})
        Api.getByTeamWithZpAsync(item.Id)
        .then(res => {
            item.Childs = res.data;
            item.Childs.forEach((child) => {
                child.Visible = true; 
                child.type = OrgStructType.Unit;
                child.parent = item;})
            item.IconExpanded = true; 
            this.setState({loading: false})
            this.forceUpdate()
        })
        .catch(err =>{
            this.setState({loading: false})
            Api.logger.errorWithSnackbar(`Error update unit list`, this, err)});
    }

    showContextMenu = (event, item, contextMenuOptions) => this.setState({anchorEl: event.currentTarget, contextMenuItem: item, contextMenuOptions:  contextMenuOptions});

    onContextMenuClose = (event, item, action) => {
        if (!action) {
            this.setState({ anchorEl: null})
            return;
        } 
        this.setState({ anchorEl: null, currentContextMenuItem: action })
 
        if (action === this.contextMenuItemDelete)
            action.handle(item)
        else
            action.openDialog(item)
    }

    onDialogAction = (isCanceled, dialogResult) => {
        
        this.setState({ dialogOpen: -1 });

        if (isCanceled) {
            return;
        }

        this.state.currentContextMenuItem.onDialogClose(dialogResult)
    }

    onAddFabClick = (e) => {
        if (this.props.currentUser.IsSystemAdmin) {
            this.onContextMenuClose(e, null, this.contextMenuItemAddOrg)
        } 
        else if (this.props.currentUser.IsOrgAdmin){
            this.onContextMenuClose(e, this.state.topItems[0], this.contextMenuItemAddLocation)
        }
        else if (this.props.currentUser.BelongsToTeam){
            this.onContextMenuClose(e, this.state.topItems[0], this.contextMenuItemAddUnit)
        }                
    }

    onNamePatternChange = async (e) => {
        await this.setState({namePattern : e.target.value});
        await this.setState({currentPage: 1}) 
        this.updateOrgList();
    }

    onFreemiumChechboxChange = async (e) => {
        await this.setState({isFreemium : e.target.checked});
        await this.setState({currentPage: 1}) 
        this.updateOrgList();
    }

    onPageChange = async (event, pageNumber) => {
        await this.setState({currentPage: pageNumber}) 
        this.updateOrgList()
        console.log(pageNumber)
    }

    render() {        
        return (
            <div style={{transform: 'translateZ(0px)', flexGrow: 1, width:'100%'}} >
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle="Bewoners" onAddFabClick={this.onAddFabClick} />
                

                {this.props.currentUser?.IsSystemAdmin ?
                    <AppBar style={{ top: '56px', background: 'white' }}>
                        <Toolbar style={{ background: 'white' }} >

                            <Grid container spacing={0} alignItems="center" justify="center" columns={30}>
                                <Grid item xs={9}>
                                    <DomainDropDown onDomainSelected={(domainGuid) => this.onDomainUpdated(domainGuid)} />
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={5}><TextField
                                fullWidth
                                    label="Organisatie naam"
                                    defaultValue={this.state.namePattern}
                                    onChange={debounce(this.onNamePatternChange, 2000)}
                                /></Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={5} style={{ color: '#333', alignItems: 'start' }}>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.state.isFreemium} onChange={this.onFreemiumChechboxChange} />}
                                        label="Show Freemium"
                                    /></Grid>
                                <Grid item xs={9}></Grid>
                            </Grid>



                        </Toolbar>
                    </AppBar>
                    :
                    ''
                }
                
                {this.state.loading ? <DidoHeart loading={this.state.loading} /> :
                    
                        <Container maxWidth="xl" style={{marginTop: this.props.currentUser?.IsSystemAdmin ? '140px' : '75px', minWidth: '300px', maxWidth:'95vw'}}>
                        
                        {this.state.topItems.map((item) => {
                            return (
                                <OrgItem
                                    key={item.Id}
                                    item={item}
                                    itemLevel={this.state.topLevel}
                                    currentUser={this.props.currentUser}
                                    contextMenuTree={this.contextMenuTree}
                                    width={this.state.width}
                                    showContextMenu={this.showContextMenu}
                                />
                            )

                        })}

                        {Math.ceil(this.state.totalCount / pageSize) > 1 ?
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                            <Pagination count={Math.ceil(this.state.totalCount / pageSize)} page={this.state.currentPage} onChange={this.onPageChange} />
                        </div>
                        :""}
                        
                        <ContextMenu style={{ zIndex: 1100 }}
                            anchorEl={this.state.anchorEl}
                            contextMenuClose={this.onContextMenuClose}
                            contextMenuItem={this.state.contextMenuItem}
                            contextMenuOptions={this.state.contextMenuOptions}
                        />
                        <ActivitiesDialog
                            open={this.state.dialogOpen === 1} // location
                            editableItem={this.state.editableItem}
                            actionTitle={this.state.actionTitle}
                            dialogAction={this.onDialogAction}
                        />

                        <TeamDialog
                            open={this.state.dialogOpen === 2}
                            editableItem={this.state.editableItem}
                            dialogAction={this.onDialogAction}
                            type={OrgStructType.Team}
                        />

                        <OrgDialog
                            open={this.state.dialogOpen === 0}
                            editableItem={this.state.editableItem}
                            dialogAction={this.onDialogAction}
                            subscriptions={this.state.subscriptions}
                            type={OrgStructType.Organization}
                        />

                        <UnitDialog
                            open={this.state.dialogOpen === 3}
                            editableItem={this.state.editableItem}
                            dialogAction={this.onDialogAction}
                            profiles={this.state.profiles}
                            type={OrgStructType.Unit}
                        />
                        </Container>
                        
                   
                }
            </div>);
    }

    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };
}

const mapStateToProps = (state) => {
     
    return {
        currentUser: state.didoReducer.currentUser
    }
  }

export default connect(mapStateToProps)(withSnackbar(OrgStructPage));