/* eslint-disable default-case */
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
//import { withStyles } from "@mui/material/styles";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ExpandMore, MoreHoriz } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { ContextMenu } from '../components/ContextMenu';
import { ActivitiesDialog } from './Dialog/ActivitiesDialog';
import { withSnackbar } from 'notistack';
import {SNACK_SUCCESS, SNACK_WARNING} from '../constants/common';
import { Heart } from 'react-spinners-css';
import { styled } from '@mui/material';


const ActivePaper = styled(Paper)(({ theme }) => ({
    marginTop: '5px',
    marginBottom: '5px',
    height: '50px',
    verticalAlign: 'middle',
    background: 'white',
    //width:'90%',
    '&:hover': {
        background: '#EEE'
    }
}));

class ActivitiesPage extends React.Component {

    itemTypeDescr = ['DOMEIN', 'TYPE', 'CATEGORIE', 'activiteit']
    IconExpandedState = new Map();

    contextMenuItemAddDomain = { key: 'addDomain', title: 'Add domain', handle: (param) => Api.createDomain(param) }
    contextMenuItemAddType = { key: 'addType', title: 'Type toevoegen', handle: (param) => Api.createType(param) }
    contextMenuItemAddCategory = { key: 'addCategory', title: 'Categorie toevoegen', handle: (param) => Api.createCategory(param) }
    contextMenuItemAddActivity = { key: 'addActivity', title: 'Activiteit toevoegen', handle: (param) => Api.createActivity(param) }

    contextMenuItemEdit = {
        key: 'edit', title: 'Bewerk',
        handle: (param) => {
            switch (param.type) {
                case this.itemTypeDescr[0]: return Api.updateDomain(param);
                case this.itemTypeDescr[1]: return Api.updateType(param);
                case this.itemTypeDescr[2]: return Api.updateCategory(param);
                case this.itemTypeDescr[3]: return Api.updateActivity(param);
            }
        }
    }
    contextMenuItemDelete = {
        key: 'delete', title: 'Verwijderen', handle: (param) => {            
            switch (param.type) {
                case this.itemTypeDescr[0]: return Api.deleteDomain(param.Id);
                case this.itemTypeDescr[1]: return Api.deleteType(param.Id);
                case this.itemTypeDescr[2]: return Api.deleteCategory(param.Id);
                case this.itemTypeDescr[3]: return Api.deleteActivity(param.Id);
            }
        }
    }

    contextMenuTree = [
        [this.contextMenuItemAddType, this.contextMenuItemEdit, this.contextMenuItemDelete],
        [this.contextMenuItemAddCategory, this.contextMenuItemEdit, this.contextMenuItemDelete],
        [this.contextMenuItemAddActivity, this.contextMenuItemEdit, this.contextMenuItemDelete],
        [this.contextMenuItemEdit, this.contextMenuItemDelete]
    ]

    constructor(props) {
        super(props);
        this.state = { domains: [], dialogOpen: false, anchorEl : null, contextMenuOptions:[], editableItem:{} };
    }

    componentDidMount() {
        this.updateDomainsList();
    }

    updateDomainsList = () => {
        this.setState({loading: true})
        Api.getDomains().then(res => {
            this.applyIconExpandedState(res.data)
            //console.log(JSON.stringify(res.data))

            this.setState({ domains: res.data, loading: false });
        }).catch(err => {
            this.setState({loading: false})
            Api.logger.errorWithSnackbar(`Error while updating domain list`, this, err)});
    }

    applyIconExpandedState = (items) => {
        for (var i = 0; i< items.length; i++) {
            var item = items[i];
            if (this.IconExpandedState.has(item.Id)) // item was expanded before
            {
                item.IconExpanded = this.IconExpandedState.get(item.Id);
                item.Childs.forEach((child) => child.Visible = this.IconExpandedState.get(item.Id))
            }
            if (item.Childs != null && item.Childs.length > 0) {
                this.applyIconExpandedState(item.Childs);
            }
        }
    }

    showContextMenu = (event, item, contextMenuOptions) => this.setState({anchorEl: event.currentTarget, contextMenuItem: item, contextMenuOptions:  contextMenuOptions});
    

    contextMenuClose = (event, item, action) => {
        this.setState({ anchorEl: null, currentContextMenuItem: action })
        // on add new
        if (action === this.contextMenuItemAddDomain || action === this.contextMenuItemAddType || action === this.contextMenuItemAddCategory || action === this.contextMenuItemAddActivity)
        {
            this.setState({editableItem : null, actionTitle: action.title, dialogOpen: true})
            if (action !== this.contextMenuItemAddDomain){
                this.setState( {parentItem: item});
            }
        }

        if (action === this.contextMenuItemEdit) {
            this.setState({ editableItem: item,  actionTitle: 'Edit ' + item.type, dialogOpen: true })
        }

        if (action === this.contextMenuItemDelete) {            
            if (item.Childs == null || item.Childs.length === 0) {
                action.handle({Id: item.Id, type: item.type})
                .then( ()=>{ this.props.enqueueSnackbar("Succesvol verwijderd", SNACK_SUCCESS); this.updateDomainsList()})
                .catch(err =>Api.logger.errorWithSnackbar(`Error in contextMenuClose item=${item.Id}`, this, err));
            }
            else {
                this.props.enqueueSnackbar('Verwijder eerst de onderliggende gegevens ' + item.Name, SNACK_WARNING)
            }
        }
    }

    dialogAction = (isCanceled, dialogResult) => {

        this.setState({ dialogOpen: false });

        if (isCanceled) {
            return;
        }

        let param = {
            name: dialogResult.name,
        }

        if (this.state.editableItem) {
            param.id = this.state.editableItem.Id
            param.type = this.state.editableItem.type;

        }
        if (this.state.parentItem) {
            param.ParentId = this.state.parentItem.Id;
        }


        // todo save expand state and apply them to new model OR update only changed values  
        this.state.currentContextMenuItem.handle(param)
        .then(() => {this.props.enqueueSnackbar("Wijzigingen zijn toegepast", SNACK_SUCCESS); this.updateDomainsList()})
        .catch(err =>Api.logger.errorWithSnackbar(`Fout tijdens het maken/bijwerken `, this, err));
    }

    

    toggleExpandIcon = (item) => {        
        if (item.Childs != null) {
            item.Childs.forEach((child) => child.Visible = !child.Visible)
        }
        item.IconExpanded = !item.IconExpanded;

        this.IconExpandedState.set(item.Id, item.IconExpanded)
        
        console.log(JSON.stringify(this.IconExpandedState))
        this.forceUpdate();
    }
    
    renderExpandIcon(item) {
        let icon;
        if (item.type === this.itemTypeDescr[this.itemTypeDescr.length-1]) { // if terminal leaf
            icon = <div>&nbsp;&nbsp;</div>
        }
        else {
            icon = (item.IconExpanded == null || item.IconExpanded === false) ?
                <NavigateNextIcon /> :
                <ExpandMore />
        }   

        return (
            <IconButton disabled={item.Childs == null || item.Childs.length === 0} onClick={() => this.toggleExpandIcon(item)}>{icon}</IconButton>
        )
    }

    setVisible = (item, itemLevel) => (itemLevel !== 0 && (item.Visible == null || item.Visible === false)) ? 'none' : '';

    renderItem(item, itemLevel) {
        item.type = this.itemTypeDescr[itemLevel];
        return (
            <div key={item.Id}
            style={{paddingLeft: itemLevel>0 ? '40px':0, display: this.setVisible(item, itemLevel)}}
            >
                <ActivePaper>
                    <Grid container spacing={0} alignItems="center" justify="center">
                        <Grid item xs={8} style={{  overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis', marginTop:'5px' }}>
                            {this.renderExpandIcon(item)}
                            {item.Name}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' ,  overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis' }}>
                            <Typography color={'textSecondary'} variant={'body2'}>{this.itemTypeDescr[itemLevel]}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={(e) => this.showContextMenu(e, item, this.contextMenuTree[itemLevel])}>
                                <MoreHoriz color={'primary'}  />
                                </IconButton>
                        </Grid>
                    </Grid>
                </ActivePaper>
                {
                    (item.Childs != null) ? item.Childs.map((childItem) => this.renderItem(childItem, itemLevel + 1)) : ""
                }
            </div>
        )
    }


    render() {
        return (
            <div style={{transform: 'translateZ(0px)', flexGrow: 1,}} >
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle="Activiteiten beheren" 
                onAddFabClick={(e) => this.contextMenuClose(e, null, this.contextMenuItemAddDomain)} />
                
                {this.state.loading ? <div align="center" style={{ marginTop: '300px' }}><Heart color="#4AB897" size={100} /></div> :
                <div>
                <Container maxWidth="xl" style={{marginTop: '75px'}}>
                    {this.state.domains.map((domain) => this.renderItem(domain, 0))}
                </Container>
                <ContextMenu  style={{ zIndex: 1100 }}
                    anchorEl={this.state.anchorEl}
                    contextMenuClose={this.contextMenuClose}
                    contextMenuItem={this.state.contextMenuItem}
                    contextMenuOptions={this.state.contextMenuOptions}
                />
                <ActivitiesDialog
                    open={this.state.dialogOpen}                    
                    editableItem={this.state.editableItem}
                    actionTitle={this.state.actionTitle}
                    dialogAction={this.dialogAction}
                />
                </div>
        }
            </div>);
    }
}

//export default withSnackbar(withStyles(styles, { withTheme: true })(ActivitiesPage));
export default withSnackbar(ActivitiesPage);