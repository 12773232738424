import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { SketchPicker } from 'react-color'
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormRow } from './../../components/FormRow';
import { Checkbox, FormControlLabel } from '@mui/material';
import { BaseDialog } from './BaseDialog';

class LevelDialog extends BaseDialog {
    constructor(props) {
        super(props,
            {
                name: null,
                displayColorPicker: false,
                colorHex: null,
                usedForAvailableTime: null,
            });
    }

    nameChange = (evt) => this.setState({ name: evt.currentTarget.value })
    currentName = () => this.currentProp('name', 'Name', null ) 


    handleColorPickerShow = () =>        
        this.setState({ displayColorPicker: !this.state.displayColorPicker, colorHex: this.currentColor() });        
    handleColorPickerClose = () => this.setState({ displayColorPicker: false });
    handleColorChange = (color) => {
        if (color && color.hex) {
            this.setState({ colorHex: color.hex})
        }
    }

    currentColor = () => {
        const clr = this.currentProp('colorHex', 'Color', '#FF3300'); // todo currentColor for nullable values?
        return clr ? clr : '#FF3300';
    }

    currentUsedForAvailableTime = () => this.currentProp('usedForAvailableTime','UsedForAvailableTime', true)

    handleOkClick = async () =>
        this.onDialogClose({ colorHex: this.currentColor(), name: this.currentName(), usedForAvailableTime: this.currentUsedForAvailableTime() }, false) 

    handleCancelClick = () => this.onDialogClose({}, true);

    render() {
        console.log(this.props.currentItem)
        return (
            <Dialog open={this.props.open} PaperProps={{ style: { overflowY: 'visible', overflowX: 'visible' } }}>
                <DialogTitle >
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.currentItem ? "Niveau bewerken" : "Nieuw niveau"}</Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.handleCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ overflowY: 'visible', overflowX: 'visible' }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormRow style={{ textAlign: "left" }}>
                            <TextField
                                name="Name"
                                required
                                label="Naam"
                                defaultValue={this.currentName()}
                                onChange={this.nameChange}
                            />
                        </FormRow>
                        <FormRow style={{ textAlign: "left" }}>
                            <div style={{display: "flex", alignItems: 'center', marginTop: 10, marginBottom: 10, }}>
                                <div onClick={this.handleColorPickerShow}
                                    style={{
                                        marginRight: '10px', 
                                        padding: '5px',
                                        background: '#fff',
                                        borderRadius: '1px',
                                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                                        display: 'inline-block',
                                        cursor: 'pointer'
                                    }}>
                                    <div style={{ width: '54px', height: '21px', borderRadius: '2px', background: this.currentColor() }}
                                    />
                                </div>
                                <div>Level color</div>
                            </div>
                        </FormRow>
                        <FormRow style={{ textAlign: "left" }}>
                            <FormControlLabel
                                control={<Checkbox required checked={this.currentUsedForAvailableTime()} 
                                onChange={(e) => this.setState({ usedForAvailableTime: e.target.checked })} />}
                                label="Display level in available time table"
                            />
                        </FormRow>
                        {this.state.displayColorPicker ?
                            <div style={{ position: 'absolute', zIndex: '2500', }} >
                                <div style={{
                                    position: 'fixed',
                                    top: '0px',
                                    right: '0px',
                                    bottom: '0px',
                                    left: '0px'
                                }} onClick={this.handleColorPickerClose} />
                                <SketchPicker color={this.currentColor()} onChange={this.handleColorChange} />
                            </div>
                            : null}
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleOkClick()} color="primary">
                        OPSLAAN
                    </Button>
                    <Button onClick={() => this.handleCancelClick()} color="primary">
                        TERUG
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default LevelDialog;