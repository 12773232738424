import React from 'react';


class ErrorBoundary extends React.Component {

    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      this.setState({ hasError: true });
      // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
      //logErrorToMyService(error, errorInfo);
      console.log("ERROR BOUNDARY");
      console.log(error);
      console.log(errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // Можно отрендерить запасной UI произвольного вида
        return <h1>Error boundary catched</h1>;
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;