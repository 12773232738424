import { Button, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import DidoDrawer from '../components/Drawer'
import { withSnackbar } from 'notistack';
import DidoHeart from '../components/DidoHeart';
import * as Api from '../services/api/common-api';
import { SNACK_SUCCESS, CHAT_TEMPLATE_KEY, CHAT_TEMPERATURE, CHAT_MAX_TOKENS } from '../constants/common';
import { FormRow } from './../components/FormRow';

class ChatSettingsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { loading: false, template: '', temperature: 0, maxTokens: 300 };
    }

    componentDidMount() {

        Api.getSetting(CHAT_TEMPLATE_KEY).then(async (res) => {
            await this.setState({ template: res.data });
        });

        Api.getSetting(CHAT_TEMPERATURE).then(async (res) => {
            await this.setState({ temperature: res.data });
        });

        Api.getSetting(CHAT_MAX_TOKENS).then(async (res) => {
            await this.setState({ maxTokens: res.data });
        });

        //this.forceUpdate();
    }

    onSignUpTextChange = (event) => {
        this.setState({ signUpTextSetting: event.target.value })
    }



    render() {

        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle="Chat administration" />
                <DidoHeart loading={this.state.loading} />


                <div style={{ marginTop: '10vh', textAlign: 'center' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <Paper sx={{
                            width: '70vw', textAlign: 'center', padding: '2vw'
                        }}><FormRow>
                                <TextField
                                    fullWidth
                                    multiline
                                    label="Prompt template"
                                    value={this.state.template}
                                    onChange={(event) => this.setState({ template: event.target.value })}
                                    rows={30}
                                />
                            </FormRow>
                            <FormRow style={{ marginTop: '3vh' }}>
                                <TextField
                                    fullWidth
                                    label="Temperature"
                                    value={this.state.temperature}
                                    onChange={(event) => this.setState({ temperature: event.target.value })}
                                />
                            </FormRow>
                            <FormRow style={{ marginTop: '3vh' }}>
                                <TextField
                                    fullWidth
                                    label="Max tokens"
                                    value={this.state.maxTokens}
                                    onChange={(event) => this.setState({ maxTokens: event.target.value })}
                                />
                            </FormRow>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: '#4AB897', color: 'white', marginTop: 10 }}
                                onClick={() => {
                                    this.setState({ loading: true })
                                    Promise.all([
                                        Api.updateSetting({ keyColumn: CHAT_TEMPLATE_KEY, valueColumn: this.state.template }),
                                        Api.updateSetting({ keyColumn: CHAT_TEMPERATURE, valueColumn: this.state.temperature }),
                                        Api.updateSetting({ keyColumn: CHAT_MAX_TOKENS, valueColumn: this.state.maxTokens })
                                    ]).then((res) => {
                                        this.setState({ loading: false })

                                        this.props.enqueueSnackbar("Modification has been saved", SNACK_SUCCESS);

                                    })
                                        .catch(err => Api.logger.errorWithSnackbar(`Error on save settings`, this, err));

                                }}>Save</Button>
                        </Paper>
                    </div>
                </div>
            </div>
        );
    }

}


export default withSnackbar(ChatSettingsPage);