import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import * as Utils from '../utils/';
import { faClock } from '@fortawesome/free-regular-svg-icons'
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function AgendaItem(props) {
    let [hours, minutes] = props.unitActivity.StartTime.split(':');

    
    let hoursEnd = hours;
    let minutesEnd = minutes / 1 + props.unitActivity.Duration
    while (minutesEnd >= 60) {
        minutesEnd -= 60;
        hoursEnd++;        
    }

    let gs = minutes;
    let dur = props.unitActivity.Duration;    
    if (gs/1 + dur/1 > 60) {
         dur = 60 - gs;
    }    

    let xs = 12;
    if (window.innerWidth > 1400)
        xs = 2;
    else if (window.innerWidth > 1000)
        xs = 3;
    else if (window.innerWidth > 800)
        xs = 4;
    else if (window.innerWidth > 600)
        xs = 6;

    return (
        <Grid item xs={xs} key={props.unitActivity.Id}>
            <Paper
                onClick={props.onClick}
                elevation={0}
                square style={{ cursor: 'pointer', background: Utils.setOpacity(props.unitActivity.Level.Color, 0.15), flex: 1, padding: 5 }}>

                <Grid container >
                    <Grid item xs={6} style={{ fontWeight: 'bold', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{props.unitActivity.Unit ? props.unitActivity.Unit.Name : props.unitActivity.Team ? props.unitActivity.Team.Name : '???'}</Grid>

                    <Grid item xs={6} align="right">
                        <Typography variant='caption'>
                            <FontAwesomeIcon icon={faClock} /> {hours}:{minutes}-{(hoursEnd + '').padStart(2, '0')}:{(minutesEnd + '').padStart(2, '0')}</Typography></Grid>
                    <Grid item xs={12} style={{ wordWrap: 'break-word', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}> <Typography variant='caption' noWrap style={{ wordWrap: 'break-word', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{props.unitActivity.Activity.Name}</Typography></Grid>
                </Grid>

            </Paper>
            <Paper
                elevation={0}
                square
                style={{ background: Utils.setOpacity(props.unitActivity.Level.Color, 0.4), height: 10 }}
            >
                <Grid container columns={60}>
                    {(gs > 0) ? <Grid item xs={gs}></Grid> : ''}
                    <Grid item xs={dur} style={{ background: Utils.setOpacity(props.unitActivity.Level.Color, 0.9), height: 10, clear: 'both' }}></Grid>
                </Grid>                
            </Paper>
        </Grid>
    )
}