import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
//import { withStyles } from "@mui/material/styles";
import UploadAvatar from '../../components/UploadAvatar';
import { BaseDialog } from './BaseDialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const styles = () => ({
    color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
      },

});


class ThemeDialog extends BaseDialog {
    constructor(props) {
        super(props,
            {
                name: '',
                file: null
            });
    }

    onNameChange = (evt) => this.setState({ name: evt.currentTarget.value });
    currentName = () => this.currentProp('name','Name', '')

    onOkClick = async () => this.onDialogClose({ name: this.currentName() }, false) 
    onCancelClick = () => this.onDialogClose({}, true);

    onSelectFile = (f) => {
        this.setState({ file: f })
    }

    onPreviewLoaded = (p) => {
        this.setState({preview: p})
    }

    render = () => {
        return (
            <Dialog open={this.props.open}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.currentItem ? "Thema bewerken" : "Nieuw thema"}</Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                            name="Name"
                            required
                            label="Naam"
                            defaultValue={this.currentName()}
                            onChange={this.onNameChange} />

                        <UploadAvatar selectedFile={this.state.file} onSelectFile={this.onSelectFile} currentItem={this.props.currentItem}  onPreviewLoaded={this.onPreviewLoaded}/>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.onOkClick()} color="primary">OPSLAAN</Button>
                    <Button onClick={() => this.onCancelClick()} color="primary">TERUG</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

//export default withStyles(styles, { withTheme: true })(ThemeDialog);
export default ThemeDialog;