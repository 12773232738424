import React from 'react';
import CardActions from '@mui/material/CardActions';
import { Card, CardContent, Dialog, Grid, Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import BarChartIcon from '@mui/icons-material/BarChart';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const grid = 8;

export class KanbanTaskCard extends React.Component {

    constructor(props){        
        super(props);
        this.state = {
            showAttachmentDialog: false
        }
    }
    getTaskCardStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: grid * 2,
        margin: '10px',
        border: isDragging ? "2px solid" : "",
        ...draggableStyle
    });

    handleOnAttachmentClick = () => this.setState({showAttachmentDialog: true});

    handleCloseClick = () => this.setState({ showAttachmentDialog: false });

    render() {
        return (
            <>
                <Card elevation={3}
                    key={this.props.task.Id}
                    ref={this.props.provided?.innerRef}
                    {...this.props.provided?.draggableProps}
                    {...this.props.provided?.dragHandleProps}
                    style={this.props.isOnReport ? { padding: grid * 2, margin: '10px', userSelect: "none" } : this.getTaskCardStyle(this.props.snapshot?.isDragging, this.props.provided?.draggableProps.style)}>
                    <CardContent>
                        <div style={{ textAlign: 'justify' }}>
                            <Avatar src={this.props.task.Theme.Avatar} style={{ width: 48, height: 48, float: 'left', marginRight: '10px', marginBottom: '10px' }} />
                            <div style={{ fontWeight: 'bold' }}>{this.props.task.Theme.Name}</div>{this.props.task.Objective}
                        </div>
                    </CardContent>
                    <CardActions disableSpacing style={{ borderTop: '1px #EEE solid', justifyContent: 'flex-end', height: '40px' }}>
                        {
                            this.props.task.LinkToReport ?
                                <IconButton aria-label="Rapport" onClick={() => { window.location.href = this.props.task.LinkToReport }}>
                                    <BarChartIcon />
                                </IconButton>
                                : ""
                        }
                        {
                            this.props.task.Attachment ?
                                <IconButton aria-label="Bijlage" onClick={this.handleOnAttachmentClick}>
                                    <AttachFileIcon />
                                </IconButton>
                                : ""
                        }
                        {
                            this.props.isOnReport ? "" :
                                <IconButton aria-label="History" onClick={() => { this.props.onShowHistoryDialog(this.props.task) }}>
                                    <HistoryIcon />
                                </IconButton>
                        }
                        <IconButton aria-label="Bewerk" onClick={() => { this.props.showTaskDialog(this.props.task, this.props.board) }}>
                            <EditIcon />
                        </IconButton>
                        <IconButton aria-label="Verwijderen" onClick={() => { this.props.onTaskDelete(this.props.task, this.props.board) }}>
                            <DeleteIcon />
                        </IconButton>
                    </CardActions>
                </Card>
                <Dialog
                    open={this.state.showAttachmentDialog}
                    fullWidth
                    maxWidth="lg"
                    onClose={this.handleCloseClick}
                >
                    <DialogTitle>
                        <Grid container>
                            <Grid item xs={10} style={{ alignSelf: 'center' }}>Bijlage</Grid>
                            <Grid item xs={2} style={{ textAlign: 'right' }}>
                                <IconButton onClick={this.handleCloseClick}><CloseIcon /></IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: 'visible', overflowX: 'visible', textAlign: 'center' }}>
                        <img src={this.props.task.Attachment} alt='Attaachment' />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseClick} color="primary">SLUIT</Button>
                    </DialogActions>
                </Dialog>
            </>

        );
    }
}
