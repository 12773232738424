import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { BaseDialog } from './BaseDialog';
import {Grid } from '@mui/material';
import  IconButton  from '@mui/material/IconButton';
import  CloseIcon  from '@mui/icons-material/Close';
import Dropzone from 'react-dropzone'
import UploadIcon from '@mui/icons-material/Upload';

class UnitFormAddDialog extends BaseDialog {
    constructor(props) {
        super(props,
            {                
                files: []
            });

            // const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
            //     // Disable click and keydown behavior
            //     noClick: true,
            //     noKeyboard: true
            //   });
    }

    onOkClick = async () => this.onDialogClose(this.state, false) 
    onCancelClick = () => this.onDialogClose({}, true);

    onDrop = (acceptedFiles) => {                
        acceptedFiles.forEach(file => this.state.files.push(file))
        
        this.forceUpdate()
    }

    render = () => {
        //console.log(this.state.files)
        const files = this.state.files.map(file => (
            <li key={file.path}>
              {file.path} 
            </li>
          ));
         return (
            <Dialog open={this.props.open}  maxWidth='lg'>
                <DialogTitle>
                <Grid container>
                        <Grid item xs={11} style={{ alignSelf: 'center' }}>Upload nieuw formulier</Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                    </DialogTitle>
                 <DialogContent>
                     <div style={{ display: "flex", flexDirection: "column" }}>
                         <Dropzone  onDrop={this.onDrop}>
                             {({ getRootProps, getInputProps, open }) => (
                                 <section  className="container" style={{border: '1px dashed #06F', padding: 10}}>
                                     <div {...getRootProps({className: 'dropzone'})}>
                                         <input {...getInputProps()} />
                                         <p style={{textAlign: 'center', color: '#06F'}}><UploadIcon fontSize='large' /></p>
                                         <p>Sleep enkele bestanden hierheen, of klik om bestanden te selecteren</p>
                                         {/* <button type="button" onClick={open}>
          Open File Dialog
        </button> */}
                                     </div>
                                 </section>
                             )}
                         </Dropzone>
                         <aside>
                             <h4>Files to be uploaded:</h4>
                             <ul style={{color: '#900'}}>{files}</ul>
                         </aside>
                     </div>
                 </DialogContent>
                <DialogActions>
                    <Button onClick={this.onOkClick} color="primary">OPSLAAN</Button>
                    <Button onClick={this.onCancelClick} color="primary">TERUG</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default UnitFormAddDialog;