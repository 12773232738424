import React from 'react';
import * as Api from '../services/api/common-api'
import { Paper } from '@mui/material';
import { Typography } from '@mui/material';
import { KanbanTaskCard } from './../components/KanbanTaskCard';
import { Grid } from '@mui/material';
import { SNACK_SUCCESS } from './../constants/common';
import KanbanTaskDialog from './../containers/Dialog/KanbanTaskDialog';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack'; 

class TodoSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            kanbanDialogOpen: false,
            themes: [],
            todoKanbanBoard: null,
        };
    }

    componentWillMount() {
        this.loadThemes();
        this.loadToDoKanbanBoard();
    }

    componentDidUpdate() {
        if (this.props.lastTeam != null && this.props.currentTeam !== this.props.lastTeam) {            
            this.loadToDoKanbanBoard();
        }
        if (this.props.callOpenDialog && !this.state.kanbanDialogOpen) {            
            this.setState({ kanbanDialogOpen: true, currentItem: null })
            this.props.hideKanbanDialog();
        }
    }

    loadThemes = () => {
        Api.getThemes()
            .then(res => this.setState({ themes: res.data }))
            .catch(err =>Api.logger.errorWithSnackbar(`Fout bij ophalen van thema's`, this, err));
    };

    loadToDoKanbanBoard = () => {
        console.log(this.props.currentTeam)
        console.log(window.location.href)
        Api.getToDoKanbanBoard(this.props.currentTeam, window.location.href)
            .then(res => this.setState({ todoKanbanBoard: res.data }))
            .catch(err => Api.logger.errorWithSnackbar(`Error while getKanbanData`, this, err));
    }

    onTaskEdit = async (task, board) => {
        console.log(task);
        if (task) {
            task.Board = board
        }

        this.loadThemes();
        //this.loadTeams();
        await this.setState({ currentItem: task, kanbanDialogOpen: true })
    }
    
    onTaskDelete = (task, board) => {
        Api.deleteTask(task.Id).then(result =>{
            this.props.enqueueSnackbar("Succesvol verwijderd", SNACK_SUCCESS);
            board.Tasks = board.Tasks.filter(t => t.Id !== task.Id);
            this.forceUpdate();
        })
        .catch(err =>Api.logger.errorWithSnackbar(`Error on delete task`, this, err));
    }

    kanbanDialogAction = (isCanceled, dialogResult) => {
        this.setState({ kanbanDialogOpen: false });
        
        if (isCanceled){
            return;
        }
        
        const formData = new FormData();    
        formData.append("Objective", dialogResult.objective)        
        formData.append("Theme.Id", dialogResult.selectedThemeGuid)
        formData.append("Board.Id", dialogResult.selectedBoardGuid)
        formData.append("LinkToReport", window.location.href)
        formData.append("FormFile", dialogResult.file)
        
        let promise; 
        if (!this.state.currentItem) { // dialog for new item
            promise = Api.createTaskWithUpload(formData);            
        }
        else {
            formData.append("Id", this.state.currentItem.Id);
            formData.append("Attachment", this.state.currentItem.Attachment);
            promise = Api.updateTaskWithUpload(formData);
        }

        promise.then((res) => { // create
            console.log(res.data)
            if (!this.state.currentItem) {
                this.props.enqueueSnackbar("Wijzigingen zijn toegepast", SNACK_SUCCESS);

                this.state.todoKanbanBoard.Tasks.push(res.data);                
            }
            else {
                let task = this.state.currentItem;
                
                task.Objective = dialogResult.objective;
                task.Attachment = res.data.Attachment;
                if (dialogResult.selectedThemeGuid !== task.Theme.Id) {
                   task.Theme = this.state.themes.find(th => th.Id === dialogResult.selectedThemeGuid) 
                }                
            }
            this.forceUpdate();
        })
        .catch(err => Api.logger.errorWithSnackbar(`Error while adding task`, this, err));
    }

    render() {

        return (
            <div style={{ marginTop: this.props.isMobileLayout ? '10px': '75px', marginLeft: '20px', marginRight: '40px' }}>

                <Paper style={{ background: '#FAFAFA', height: '90vh', overflowY: 'auto', overflowX: 'clip' }} elevation={1}>
                    <Grid container style={{ marginLeft: '10px', marginRight: '15px', marginTop: '10px' }}>
                        <Grid item xs={12}><Typography variant='subtitle1' style={{ fontWeight: 'bold', color: '#555', }}>Acties</Typography></Grid>                        
                    </Grid>
                    {
                        this.state.todoKanbanBoard?.Tasks.map((task, index) => (
                            <KanbanTaskCard
                                key={task.Id}
                                task={task}
                                index={index}
                                board={this.state.todoKanbanBoard}
                                showTaskDialog={this.onTaskEdit}
                                onTaskDelete={this.onTaskDelete}
                                onShowHistoryDialog={this.onShowHistoryDialog}
                                isOnReport
                            />
                        ))
                    }
                </Paper>
                <KanbanTaskDialog
                        open={this.state.kanbanDialogOpen}
                        dialogAction={this.kanbanDialogAction}                        
                        themes={this.state.themes}
                        teams={this.props.currentUser.Teams.filter(t => t.Id === this.props.currentTeam)}
                        isTeamFixed
                        isBoardFixed
                        linkToReport={window.location.href}
                        currentItem={this.state.currentItem}
                    />
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    currentUser: state.didoReducer.currentUser,
    currentTeam: state.didoReducer.currentTeam,
});

export default withSnackbar(connect(mapStateToProps)(TodoSidebar));