

import { TOKEN_KEY, USER_KEY } from "../constants/common";


export function parseJwt(token) {
    if (!token){
        return;
    }

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
}

export function isNumber(myString) {
    return myString?.match(/^-{0,1}\d+$/) || myString?.match(/^\d+\.\d+$/);
}


export const isJwtExpired = () => {

  let payload = parseJwt(localStorage.getItem(TOKEN_KEY))

  if (payload != null) {
    const expiration = new Date(payload.exp * 1000);

    const now = new Date();

    const diff = expiration.getTime() - now.getTime();
    //console.log()

    if (diff < 0) {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USER_KEY);
      return true;
    }
  }
  return false;
}