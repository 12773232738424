import React from 'react';
import { EMPTY_GUID } from '../../constants/common';
import * as Api from '../../services/api/common-api'

export class BaseDialog extends React.Component {
  _initialState = null;
  constructor(props, initialState) {
    super(props);

    this._initialState = initialState;
    this._initialState.width = window.innerWidth;

    this.flushState();
  }

  flushState = () => {    
    const clone = JSON.parse(JSON.stringify(this._initialState))
    if (this.state) this.setState(clone)
    else this.state = clone;

  }

  componentWillMount() {       
    window.addEventListener('resize', this.onWindowSizeChange);
}

onWindowSizeChange = () => {
    this.setState({width: window.innerWidth });
};

  currentProp = (propName, apiName, defaultValue) =>{
    if (this.state.hasOwnProperty(propName) && (this.state[propName] != null && this.state[propName] !== '' && this.state[propName] !== EMPTY_GUID )) {
      return this.state[propName]
    }

    if (this.props.currentItem) {
      return this.props.currentItem[apiName];
    }

    return defaultValue;
  }

  onDialogClose = async (fixState, isCanceled) => {
    fixState.isCanceled = isCanceled
    await this.setState(fixState)

    this.props.onDialogClosed(this.state);
    this.flushState();
    this.forceUpdate();
  }
}