import { Select, MenuItem, Checkbox } from '@mui/material';
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import UnitActivityDialog from './Dialog/UnitActivityDialog'
import DeleteDialog from './Dialog/DeleteDialog';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import {EMPTY_GUID} from '../constants/common';
import { ContextMenu } from './../components/ContextMenu';
import { connect } from "react-redux";
import actions from "../actions.js";
import  FormControlLabel  from '@mui/material/FormControlLabel';
import { withSnackbar } from 'notistack';
import { SNACK_SUCCESS } from './../constants/common';
import { BasePage } from './BasePage';
import AgendaItem from '../components/AgendaItem';
import DidoHeart from './../components/DidoHeart';
import queryString from 'query-string'

class UnitActivityPage extends BasePage {
    
    weekDays = ['MAANDAG', 'DINSDAG', 'WOENSDAG', 'DONDERDAG','VRIJDAG','ZATERDAG','ZONDAG']
    constructor(props) {
        super(props);
        this.state = { units:[], levels:[], categories:[], 
            agendaRows: [], unitActivities: [], dialogOpen: false, currentUnitActivity: null, 
            contextMenuItem: null, contextMenuOptions: [this.contextMenuItemEdit, this.contextMenuItemDelete], anchorEl: null,
            dialogOpenDeleteConfirmation: false,
            teamUnits: [], currentUnitGuid:EMPTY_GUID, currentTeamGuid: this.props.currentTeam,
            categoriesFilterItems: [], currentCategoryGuid: EMPTY_GUID, 
            levelsFilterItems: [], currentLevelGuid: EMPTY_GUID,
            currentTab: 0, loading: false,
            showTeamActivities: true, showUnitActivities: true, 
            width: window.innerWidth
         };
    }

    componentDidMount(){
        //this.updateUnitActivitiesList()
        this.updateTeamUnits()
        this.updateCategories()
        this.updateLevels()
        
    }

    componentDidUpdate(prevProps){ // todo try Thunx https://stackoverflow.com/questions/39524855/how-to-trigger-off-callback-after-updating-state-in-redux        
        if (prevProps.currentTeam !== this.props.currentTeam) { 
            this.setState({currentUnitGuid: EMPTY_GUID})
            this.updateUnitActivitiesList()
            this.updateTeamUnits() 
        }
    }

    updateUnitActivitiesList = async () => {

        await this.setState({loading: true})
        const mask = 1 << this.state.currentTab
        const teamGuid = this.state.currentUnitGuid === EMPTY_GUID ? this.props.currentTeam : EMPTY_GUID
        console.log(teamGuid)
        const filter = {
            unitGuid: this.state.currentUnitGuid,
            teamGuid: teamGuid,
            weekDay: mask,
            categoryGuid: this.state.currentCategoryGuid,
            levelGuid: this.state.currentLevelGuid,
            showTeamActivities: this.state.showTeamActivities,
            showUnitActivities: this.state.showUnitActivities
        };
        Api.getAgendaRows(filter)
            .then(res => this.setState({ agendaRows: res.data, loading: false }))
            .catch(err =>Api.logger.errorWithSnackbar(`Error on getAgendaRows`, this, err));
    };

    onWeekDayTabChange = async (e, newVal) => 
    {
        await this.setState({currentTab: newVal})
        this.updateUnitActivitiesList();
    }

    onCategoryChange = async (event) => {
        await this.setState({ currentCategoryGuid: event.target.value });
        this.updateUnitActivitiesList();
    }

    onLevelChange = async (event) => {
        await this.setState({ currentLevelGuid: event.target.value });
        this.updateUnitActivitiesList();
    }

    onTeamUnitChange = async (event) => {
        await this.setState({ currentUnitGuid: event.target.value });
        this.updateUnitActivitiesList();
    }

    onShowTeamActivitiesChange = async (event) => {
        await this.setState({ showTeamActivities : event.target.checked});
        this.updateUnitActivitiesList();
    }

    onShowUnitActivitiesChange = async (event) => {
        await this.setState({ showUnitActivities : event.target.checked});
        this.updateUnitActivitiesList();
    }

    updateTeamUnits = () => {

        Api.getUnitsByTeam(this.props.currentTeam)
            .then(res => {
                res.data.unshift({ Id: EMPTY_GUID, Name: 'Alle kamers' })

                var parsed = queryString.parse(window.location.search)

                var selectedUnit = res.data[0].Id
                if (parsed.u != null) {
                    var unit = res.data.find(u => u.Id === parsed.u.toLowerCase());
                    if (unit) {
                        selectedUnit = unit.Id;
                    }                    
                }

                this.setState({ teamUnits: res.data, currentUnitGuid: selectedUnit })
                this.updateUnitActivitiesList();
            }
            )
            .catch(err => Api.logger.errorWithSnackbar(`Error on getUnitsByTeam`, this, err));
    }

    updateCategories = async () => {
        Api.getCategoriesWithChildsForCurrentDomain()
            .then(async (res) => {
                console.log(res)                
                res.data.unshift({ Id: EMPTY_GUID, Name: 'Alle categories' })
                await this.setState({ categoriesFilterItems: res.data })
            })
            .catch(err => Api.logger.errorWithSnackbar(`Error on getting categories`, this, err));
    }

    updateLevels = async () => {
        Api.getLevelsByDomain(this.props.currentUser.CurrentDomainGuid)
           .then(async (res) => {
                console.log(res)                
                res.data.unshift({ Id: EMPTY_GUID, Name: 'Alle levels' })
                await this.setState({ levelsFilterItems: res.data })
            })
           .catch(err => Api.logger.errorWithSnackbar(`Error on getting levels`, this, err));
    }

    desktopLayout = () => (
        <>
            <Grid item xs={2}>
                <Typography variant='h6' style={{ textAlign: 'left', marginLeft: '2vw' }}>Overzicht activiteiten</Typography>
            </Grid>


            <Grid item xs={10} style={{ textAlign: 'right' }}>
                <FormControlLabel
                    control={<Checkbox checked={this.state.showTeamActivities} onChange={this.onShowTeamActivitiesChange} />}
                    label="Groepsactiviteiten"
                />&nbsp;&nbsp;
                <FormControlLabel
                    control={<Checkbox checked={this.state.showUnitActivities} onChange={this.onShowUnitActivitiesChange} />}
                    label="Clientactiviteiten"
                />&nbsp;&nbsp;

                <Select style={{ width: '10vw', marginRight: '2vw' }}
                    value={this.state.currentUnitGuid}
                    onChange={(e) => this.onTeamUnitChange(e)}
                    disabled={!this.state.showUnitActivities}
                >

                    {this.state.teamUnits.map((unit) => {
                        return (
                            <MenuItem key={unit.Id} value={unit.Id}>{unit.Name}</MenuItem>
                        )
                    })}
                </Select>

                <Select style={{ width: '12vw', marginRight: '2vw' }}
                    value={this.state.currentCategoryGuid}
                    onChange={(e) => this.onCategoryChange(e)}
                >
                    {this.state.categoriesFilterItems.map((cat) => {
                        return (
                            <MenuItem key={cat.Id} value={cat.Id}>{cat.Name}</MenuItem>
                        )
                    })}
                </Select>

                <Select style={{ width: '10vw', marginRight: '2vw' }}
                    value={this.state.currentLevelGuid}
                    onChange={(e) => this.onLevelChange(e)}
                >
                    {this.state.levelsFilterItems.map((level) => {
                        return (
                            <MenuItem key={level.Id} value={level.Id}>{level.Name}</MenuItem>
                        )
                    })}
                </Select>

            </Grid>
        </>
    )

    mobileLayout = () => (
        <>


            <Grid item xs={12}>
                <Typography variant='h6' style={{ textAlign: 'center', marginLeft: '2vw' }}>Overzicht activiteiten</Typography>
            </Grid>


            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <FormControlLabel
                    control={<Checkbox checked={this.state.showTeamActivities} onChange={this.onShowTeamActivitiesChange} />}
                    label="Groepsactiviteiten"
                />&nbsp;&nbsp;
                <FormControlLabel
                    control={<Checkbox checked={this.state.showUnitActivities} onChange={this.onShowUnitActivitiesChange} />}
                    label="Clientactiviteiten"
                />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Select style={{ width: '80vw', marginRight: '2vw' }}
                    value={this.state.currentUnitGuid}
                    onChange={(e) => this.onTeamUnitChange(e)}
                    disabled={!this.state.showUnitActivities}
                >

                    {this.state.teamUnits.map((unit) => {
                        return (
                            <MenuItem key={unit.Id} value={unit.Id}>{unit.Name}</MenuItem>
                        )
                    })}
                </Select>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>

                <Select style={{ width: '80vw', marginRight: '2vw' }}
                    value={this.state.currentCategoryGuid}
                    onChange={(e) => this.onCategoryChange(e)}
                >
                    {this.state.categoriesFilterItems.map((cat) => {
                        return (
                            <MenuItem key={cat.Id} value={cat.Id}>{cat.Name}</MenuItem>
                        )
                    })}
                </Select>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Select style={{ width: '80vw', marginRight: '2vw' }}
                    value={this.state.currentLevelGuid}
                    onChange={(e) => this.onLevelChange(e)}
                >
                    {this.state.levelsFilterItems.map((level) => {
                        return (
                            <MenuItem key={level.Id} value={level.Id}>{level.Name}</MenuItem>
                        )
                    })}
                </Select>
            </Grid>

        </>
    )

    render() {
        //console.log(this.state.teamUnits)
        
        
        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle="Groepsagenda" onAddFabClick={(e) => this.showUnitActivityDialog()} />                
                <DidoHeart loading={this.state.loading} />
                
                <Container maxWidth="xl" key="appBar" style={{ marginTop: '75px' }}>
                    <Paper>
                        <Grid container spacing={2}>
                        { this.state.width > 750 ? 
                        this.desktopLayout():
                        this.mobileLayout()
    }
    
                            <Grid item xs={12}>
                                <Tabs
                                    value={this.state.currentTab}
                                    onChange={(e, newVal) => this.onWeekDayTabChange(e, newVal)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered={this.state.width > 750 ? true : false}
                                    variant={this.state.width > 750 ? 'standard' : 'scrollable'}
                                    scrollButtons="on"
                                >
                                    {
                                        this.weekDays.map((dayName) => {
                                            return (
                                                <Tab label={dayName} key={dayName} />
                                            )
                                        })
                                    }
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
                <Container maxWidth="xl" style={{ marginTop: 20 }} key="unitActivities">
                    <Paper>
                        {this.state.loading ?
                            <div align="center" style={{ height: '80vh' }}>
                                {/* <Heart color="#4AB897" size={100} style={{ marginTop: '25vh' }} /> */}
                                </div>
                            :
                            <Timeline align="left" key="timeline" >
                                {
                                    this.state.agendaRows.map((row) => {
                                        let rowHeight = row.Row.length / 10 * 70;
                                        // console.log(rowHeight)
                                        return (

                                            <TimelineItem key={row.Id} style={{ flex: 1, minHeight: 'auto' }} >
                                                <TimelineOppositeContent style={{ flex: 0.1 }} >
                                                    <Typography color="textSecondary" variant={this.state.width > 700 ? 'body2' : 'caption'}>{row.TimeLineRangeHint}</Typography>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot />
                                                    <TimelineConnector style={{ height: rowHeight }} />
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Grid container spacing={1} >
                                                        {
                                                            row.Row.map((unitActivity) =>
                                                                <AgendaItem unitActivity={unitActivity} onClick={(e) => this.showContextMenu(e, unitActivity)} />
                                                            )
                                                        }
                                                    </Grid>
                                                </TimelineContent>
                                            </TimelineItem>

                                        );
                                    })
                                }
                            </Timeline>
                        }</Paper>

                </Container>
                    <UnitActivityDialog
                        open={this.state.dialogOpen}
                        dialogAction={this.dialogAction}
                        currentUnitActivity={this.state.currentUnitActivity}
                        currentUnitGuid={this.state.currentUnitGuid}
                        units={this.state.teamUnits}
                        levels={this.state.levels}
                        categories={this.state.categories}
                    />
                    <ContextMenu  style={{ zIndex: 1100 }}
                    anchorEl={this.state.anchorEl}
                    contextMenuClose={this.contextMenuClose}
                    contextMenuItem={this.state.contextMenuItem}
                    contextMenuOptions={this.state.contextMenuOptions}
                />
                <DeleteDialog 
                open={this.state.dialogOpenDeleteConfirmation}
                onClose={(b) => this.onDeleteConfirmation(b)}
                text={`Are you sure to delete '${this.state.contextMenuItem?.Activity?.Name}' for ${this.state.contextMenuItem?.Unit ? this.state.contextMenuItem?.Unit.Name:this.state.contextMenuItem?.Team?.Name}?`}
                />
                
            </div>);
    }

    showContextMenu = (event, unitActivity) => {
        this.setState({anchorEl:event.currentTarget, contextMenuItem: unitActivity})
    }

    contextMenuClose = (event, item, action) => {
        console.log(item)
        console.log(action)
        this.setState({ anchorEl: null })

        if (action === this.contextMenuItemEdit)
            this.showUnitActivityDialog(item);
        if (action === this.contextMenuItemDelete) {
            this.setState({ dialogOpenDeleteConfirmation: true })
        }
        
    }

    contextMenuItemEdit = {
        key: 'edit', title: 'Bewerk',
        handle: (param) => Api.createUnitActivity(param)
    }

    contextMenuItemDelete = {
        key: 'delete', title: 'Verwijderen',
        handle: (param) => Api.deleteUnitActivity(param)
    }

    onDeleteConfirmation = (isDelete) => {
        this.setState({ dialogOpenDeleteConfirmation: false })
        if (isDelete) {
            Api.deleteUnitActivity(this.state.contextMenuItem.Id)
            .then(res => {
                this.props.enqueueSnackbar("Succesvol verwijderd", SNACK_SUCCESS);
                this.updateUnitActivitiesList()})
            .catch(err =>Api.logger.errorWithSnackbar(`Error on delete unit activity ${this.state.contextMenuItem?.Id}`, this, err));
        }
    }

    showUnitActivityDialog = async (unitActivity) => {
        

        if (this.state.levels.length === 0) { // not loaded before
            await this.setState({loading: true})

            Promise.all([Api.getUnits(), Api.getLevelsByDomain(this.props.currentUser.CurrentDomainGuid), Api.getCategoriesWithChildsForCurrentDomain()])
                .then(async (res) => {
                    res.forEach(async (result) => {
                        if (result.config.url.toLowerCase().indexOf('level') !== -1) await this.setState({ levels: result.data });
                        else if (result.config.url.toLowerCase().indexOf('category') !== -1) await this.setState({ categories: result.data });
                    });
                    await this.setState({ currentUnitActivity: unitActivity, dialogOpen: true, loading: false })
                })
                .catch(err => Api.logger.errorWithSnackbar(`Error on getting units, levels and categories for dialog`, this, err));
        }
        else{
            this.setState({ currentUnitActivity: unitActivity, dialogOpen: true})
        }
        
    };

    dialogAction = (isCanceled, dialogResult) => {

        this.setState({ dialogOpen: false });
        
        if (isCanceled){
            return;
        }

        let param = {
            Unit: dialogResult.selectedUnitGuid === EMPTY_GUID ? null : {Id: dialogResult.selectedUnitGuid},
            Team: dialogResult.selectedUnitGuid === EMPTY_GUID ? {Id: this.state.currentTeamGuid} : null,
            Activity : {Id: dialogResult.selectedActivityGuid},
            Level : {Id: dialogResult.selectedLevelGuid},
            DayOfWeek : dialogResult.weekdayField,
            StartTime : dialogResult.startTimeField,
            Duration : dialogResult.durationField,
            Description : dialogResult.descriptionField
        }
        
        
        let promise; 

        if (!this.state.currentUnitActivity) { // dialog for new subscription            
            promise = Api.createUnitActivity(param);
        }
        else {
            param.id = this.state.currentUnitActivity.Id;
            promise = Api.updateUnitActivity(param);
        }

        promise
        .then(() => {
            this.props.enqueueSnackbar("Wijzigingen zijn toegepast", SNACK_SUCCESS);
            this.updateUnitActivitiesList()})
        .catch(err =>Api.logger.errorWithSnackbar(`Error editing unit activity`, this, err));
    }
}

export default connect((state)=>({
        currentTeam: state.didoReducer.currentTeam,
        currentUser : state.didoReducer.currentUser
    }), actions)(withSnackbar(UnitActivityPage));
