import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { MenuItem, Select, FormControl, Grid } from '@mui/material';
import UploadAvatar from '../../components/UploadAvatar';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import { SELECT_TEAM, ASSIGNED_TO_TEAM } from '../../constants/roles';
import { EMPTY_GUID, SNACK_ERROR } from '../../constants/common';
import * as Api from '../../services/api/common-api'
import { withSnackbar } from 'notistack';
import { FormRow } from '../../components/FormRow';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class UserDialog extends React.Component {
    
    render() {

        return (
            <Dialog open={this.props.open} fullWidth maxWidth="sm">
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.editableItem ? "Gebruiker bewerken" : "Nieuwe gebruiker"}</Grid>
                        <Grid item xs={2} style={{textAlign: 'right'}}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormRow>
                            <TextField
                                name="Name"
                                required
                                label="Naam"
                                size="small"
                                defaultValue={(this.props.editableItem) ? this.props.editableItem.Name : ""}
                                onChange={this.onNameChange}
                                fullWidth
                            />
                        </FormRow>
                        <FormRow>
                            <TextField
                                name="Email"
                                required
                                label="E-mail"
                                size="small"
                                helperText={this.state.emailVerifyText}
                                defaultValue={(this.props.editableItem) ? this.props.editableItem.Email : ""}
                                error={this.state.emailVerifyText.length > 0}
                                onChange={this.onEmailChange}
                                fullWidth
                            />
                        </FormRow>
                        <FormRow>
                            <UploadAvatar selectedFile={this.state.file} onSelectFile={this.onSelectFile} currentItem={this.props.editableItem} />
                        </FormRow>
                        {
                            this.props.currentUser && this.props.currentUser.IsSystemAdmin ?
                                <FormRow>
                                    <Select
                                        fullWidth
                                        value={this.currentOrganizationGuid()}
                                        onChange={(e) => this.onOrganizationChange(e)}
                                    >
                                        {this.props.organizations.map((org) => {
                                            return (
                                                <MenuItem key={org.Id} value={org.Id}>{org.Name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText>Organisatie</FormHelperText>
                                </FormRow>
                                :
                                ''
                        }
                        {
                            this.isBelongsToTeamSelectVisible() ?
                                <FormRow>
                                    <Select
                                        value={this.currentTeamGuid()}
                                        onChange={(e) => this.onTeamChange(e)}
                                    >
                                        {this.props.teamsForOrg.map((team) => {
                                            return (
                                                <MenuItem key={team.Id} value={team.Id} >{team.Name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText>Belongs to team</FormHelperText>
                                </FormRow>
                                :
                                ''

                        }

{
                            this.isManagedTeamsSelectVisible() ?
                                <FormRow>
                                    <FormControl>
                                    <InputLabel id="managed-teams-label">Managed teams</InputLabel>
                                    <Select
                                    style={{width:'300px'}}
                                    labelId="managed-teams-label"
                                    id="managed-teams"
                                    multiple
                                    MenuProps={MenuProps}
                                    value={this.currentManagedTeamsIds()}
                                    onChange={(e) => this.onManagedTeamsChange(e)}
                                        renderValue={(selected) => 
                                            (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    { 
                                                    
                                                    this.props.teamsForOrg
                                             .filter(t => selected.includes(t.Id))
                                             .map(t => (
                                                <Chip key={t.Id} label={t.Name} />
                                             ))
                                                    }
                                                    </Box>
                                                    )
                                        }
                                    input={<OutlinedInput label="ManagedTeams" />}

                                    >
                                        {this.props.teamsForOrg.map((team) => {
                                            return (
                                                <MenuItem key={team.Id} value={team.Id} >
                                                    <Checkbox checked={this.currentManagedTeamsIds().indexOf(team.Id) > -1} />
                                                    <ListItemText primary={team.Name} />
                                                    </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    </FormControl>
                                    
                                </FormRow>
                                :
                                ''

                        }

                    </div>
                    <FormGroup>
                        {
                            this.props.profiles.map((profile) => {
                                return (
                                    <FormControlLabel key={profile.Id}
                                        control={<Checkbox onClick={this.onProfileCheckboxClick}
                                            name={profile.Id}
                                            checked={this.currentProfiles().some(p => p.Id === profile.Id)}
                                        />}
                                        label={profile.Name}
                                    />);
                            })
                        }
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onOkClick} color="primary">OPSLAAN</Button>
                    <Button onClick={this.onCancelClick} color="primary">TERUG</Button>
                </DialogActions>
            </Dialog>
        );
    }


    constructor(props) {
        super(props);
        this.flushState();
    }

    flushState = async () => {
        const initialState = {
            name: '',
            email: '',
            emailVerifyText: '',
            file: null,
            selectedOrgGuid: '',
            selectedTeamGuid: '',
            profiles: null,
            selectedManagedTeamsIds: []
        };
        if (this.state) await this.setState(initialState)
        else this.state = initialState;
    }

    isBelongsToTeamSelectVisible = () => {
        if (!this.props.currentUser.IsOrgAdmin) return false;

        if (!this.props.profiles || !this.props.profiles.length) return false;

        var profilesWithBelongToTeamRoleId = this.props.profiles.filter(p => p.Roles.some(r => r.Name === ASSIGNED_TO_TEAM)).map(p => p.Id)

        const isProfileSelected = profilesWithBelongToTeamRoleId.some(pId => this.currentProfiles().map(cp => cp.Id).includes(pId))

        return isProfileSelected;
    }

    isManagedTeamsSelectVisible = () => {
        
        if (!this.props.currentUser.IsOrgAdmin) return false;
        
        if (!this.props.profiles || !this.props.profiles.length) return false;

        var managerProfiles = this.props.profiles.filter(p => p.Roles.some(r => r.Name === SELECT_TEAM)).map(p => p.Id);
        return managerProfiles.some(mpId => this.currentProfiles().map(cp => cp.Id).includes(mpId));
    }

    // Name
    currentName = () => this.state.name !== '' ? this.state.name :
        this.props.editableItem ? this.props.editableItem.Name :
            '';
    onNameChange = (evt) => this.setState({ name: evt.currentTarget.value })

    // Email
    currentEmail = () => this.state.email !== '' ? this.state.email :
        this.props.editableItem ? this.props.editableItem.Email :
            '';
    onEmailChange = (evt) => {
        const isMailCorrect = /\S+@\S+\.\S+/.test(String(evt.currentTarget.value).toLowerCase());

        this.setState({ email: evt.currentTarget.value, emailVerifyText: isMailCorrect ? '' : 'Invalid email address' })

        if (isMailCorrect) {
            Api.isEmailNotExists(evt.currentTarget.value).then(
                res => this.setState({ emailVerifyText: res.data === true ? 'This email already exists' : '' }))
        }
    }

    // Organization
    currentOrganizationGuid = () => this.state.selectedOrgGuid !== '' ? this.state.selectedOrgGuid :
        this.props.editableItem && this.props.editableItem.Organization ? this.props.editableItem.Organization.Id :
            this.props.organizations.length > 0 ? this.props.organizations[0].Id :
                '';

    onOrganizationChange = async (event) => await this.setState({ selectedOrgGuid: event.target.value });

    // Team
    currentTeamGuid = () => this.state.selectedTeamGuid !== '' ? this.state.selectedTeamGuid :
        this.props.editableItem && this.props.editableItem.BelongsToTeam ? this.props.editableItem.BelongsToTeam.Id :
            this.props.teamsForOrg.length > 0 ? this.props.teamsForOrg[0].Id :
                '';

    currentManagedTeamsIds = () => this.state.selectedManagedTeamsIds.length > 0 ? this.state.selectedManagedTeamsIds :
        this.props.editableItem && this.props.editableItem.ManagedTeams?.length > 0 ? this.props.editableItem.ManagedTeams.map(t => t.Id) :
            [];

    onTeamChange = async (event) => await this.setState({ selectedTeamGuid: event.target.value });

    onManagedTeamsChange =  async (event) => {
        console.log(event.target.value)
        await this.setState({selectedManagedTeamsIds:event.target.value});
    }

    // Avatar
    onSelectFile = (f) => this.setState({ file: f })

    // Profiles
    currentProfiles = () => {
        return this.state.profiles != null ? this.state.profiles :
            (this.props.editableItem) ? this.props.editableItem.Profiles : /*.map(p => p.Id)*/
                []
    };

    onProfileCheckboxClick = async (evt) => {
        let profiles = this.currentProfiles();

        if (evt.target.checked) {
            profiles.push(this.props.profiles.find(p => p.Id === evt.target.name));
        }
        else {
            profiles = profiles.filter(p => p.Id !== evt.target.name)
        }
        await this.setState({ profiles })
        this.forceUpdate();
    }



    onOkClick = async () => {
        if (this.state.emailVerifyText !== '') {
            this.props.enqueueSnackbar('There are errors on form, please fix them before saving', SNACK_ERROR)
            return;
        }

        await this.setState({
            name: this.currentName(),
            email: this.currentEmail(),
            selectedOrgGuid: this.currentOrganizationGuid(),
            profiles: this.currentProfiles().map(p => p.Id),
            selectedTeamGuid: this.isBelongsToTeamSelectVisible() ? this.currentTeamGuid() : EMPTY_GUID,
            selectedManagedTeamsIds: this.currentManagedTeamsIds()
            //avatar?
            //profiles?
        })


        this.props.onUserDialogClose(this.state);
        this.flushState();
    }

    onCancelClick = () => { this.props.onUserDialogClose(); this.flushState(); }




}

const mapStateToProps = (state) => {

    return {
        currentUser: state.didoReducer.currentUser
    }
}

export default connect(mapStateToProps)(withSnackbar(UserDialog));