import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { MenuItem, Select } from '@mui/material';
import UploadAvatar from '../../components/UploadAvatar';
import { FormRow } from './../../components/FormRow';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export class TeamDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            file: null,
            preview: null
        };
    }

    handleNameChange = (evt) => this.setState({ name: evt.currentTarget.value });
    currentName = () => this.state.name ?
        this.state.name :
        this.props.editableItem ?
            this.props.editableItem.Name :
            ''

    handleOkClick = async () => {
        if (!this.state.name){
            await this.setState({name: this.currentName()})
        }
        await this.setState({type: this.props.type});
        this.props.dialogAction(false, this.state);
        this.setState({ name: null, file:null, preview:null })
    }

    onCancelClick = () => {
        this.props.dialogAction(true);
        this.setState({  name: null, file:null, preview:null })
    }

    onSelectFile = (f) => {
        this.setState({file: f})
    }

    onPreviewLoaded = (p) => {
        this.setState({preview: p})
    }

    render = () => {
        return (
            <Dialog open={this.props.open}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.editableItem ? "Groep bewerken" : "Nieuwe groep"}</Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormRow>
                        <TextField
                            name="Name"
                            required
                            label="Naam"
                            defaultValue={this.currentName()}
                            onChange={this.handleNameChange} />
                           </FormRow>
                           <FormRow>
                            <UploadAvatar selectedFile={this.state.file} onSelectFile={this.onSelectFile} currentItem={this.props.editableItem} onPreviewLoaded={this.onPreviewLoaded} />
                            </FormRow>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleOkClick()} color="primary">OPSLAAN</Button>
                    <Button onClick={() => this.onCancelClick()} color="primary">TERUG</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
