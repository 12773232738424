import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import * as Api from '../services/api/common-api'
import { withSnackbar } from 'notistack';
import { Autocomplete, Checkbox, FormControlLabel,Typography } from '@mui/material';
import { BasePage } from './BasePage';
import { GreenButton } from './../components/GreenButton';
import DidoHeart from '../components/DidoHeart';
import { FormRow } from './../components/FormRow';
import history from '../utils/history';
import { FREEMIUM_SIGN_UP_TEXT } from './../constants/common';

class SignUpPage extends BasePage {
    constructor(props) {
        super(props);
        this.state = { 
            name: '', nameVerifyText: '', 
            email: '', emailVerifyText: '', 
            orgName: '', selectedOrgId: undefined, orgs: [], 
            isAgreement: false, loading: false,
            signUpSettingText : '' 
        };
    }

    loadOrganizations = () => this.getItems(Api.getOrganizationsForSubscriptionPage, this.state.orgName, res => this.setState({
        orgs: res.data.map(o => ({ label: o.Name, id: o.Id }))
    }), `Error retriving organizations list`);



    componentDidMount = () => {
        Api.getSetting(FREEMIUM_SIGN_UP_TEXT).then((res) => this.setState({signUpSettingText : res.data}));
        this.loadOrganizations()
    };

    signUp = (e) => {
        
        if (this.state.nameVerifyText.length > 0 || this.state.emailVerifyText.length > 0 || !this.state.selectedOrgId || !this.state.isAgreement) { // validate
            console.log("validate fail")
            return
        }
        e.preventDefault();
        console.log("signUp animation start")
        this.setState({ loading: true })
        
        const data = { Name: this.state.name, Email: this.state.email, OrganizationId: this.state.selectedOrgId };
        Api.signUpUser(data).then(response => {
            console.log(response);
            if (response.status === 200) {
                window.location = '/goedgedaan';                
            }
            else {
                history.push("/user_not_found");
            }
        })
        .catch(err => Api.logger.errorWithSnackbar(`Error on sign up new user: ${err.response.data}`, this, err))
        .finally(() => this.setState({ loading: false }));
    }

    handleEmailChange = (evt) => {
        const isMailCorrect = /\S+@\S+\.\S+/.test(String(evt.currentTarget.value).toLowerCase());

        this.setState({ email: evt.currentTarget.value, emailVerifyText: isMailCorrect ? '' : 'Invalid email address' })
    }

    handleNameChange = (evt) => 
        this.setState({ name: evt.target.value, nameVerifyText: evt.target.value.length < 3 ? 'Name should be at least 3 characters' : '' })
    

    render() {
        //console.log(this.state.orgs);
        return (
            <>
                <DidoHeart loading={this.state.loading} />
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}>
                    <Paper style={{ textAlign: "center", verticalAlign: "center", width: "400px", height: '720px', marginTop: '15vh', paddingLeft: '2vw', paddingRight: '2vw' }}>
                        <form>
                            <FormRow>
                                <img src="/img/color-logo.png" alt="Logo" width="205px" height="126px" style={{ textAlign: "center", marginTop: "5vh", marginBottom: "5vh" }} />
                            </FormRow>
                            <FormRow>
                            <pre><Typography variant="subtitle2" align='left'>{this.state.signUpSettingText}</Typography></pre>
                            
                            </FormRow>
                            <FormRow style={{ textAlign: "center" }}>
                                <TextField
                                    name="name"
                                    required
                                    label="Name"
                                    margin="dense"
                                    onChange={this.handleNameChange}
                                    fullWidth
                                    helperText={this.state.nameVerifyText}
                                    error={this.state.nameVerifyText.length > 0}
                                />
                            </FormRow>
                            <FormRow style={{ textAlign: "center" }}>
                                <TextField
                                    fullWidth
                                    name="email"
                                    required
                                    label="E-mail"
                                    margin="dense"
                                    onChange={this.handleEmailChange}
                                    helperText={this.state.emailVerifyText}
                                    error={this.state.emailVerifyText.length > 0}
                                // sx={{paddingLeft: '1vw', paddingRight: '1vw'}}
                                />
                            </FormRow>
                            <FormRow style={{ textAlign: "center" }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={this.state.orgs}
                                    renderInput={(params) => <TextField required {...params} label="Organization" margin="dense" />}
                                    onChange={(event, newValue) => this.setState({ selectedOrgId: newValue.id })}
                                />
                            </FormRow>
                            <FormRow style={{ textAlign: "center" }}>
                                <FormControlLabel
                                    control={<Checkbox required checked={this.state.isAgreement} onChange={(e) => this.setState({ isAgreement: e.target.checked })} />}
                                    label="I'm work for selected organization"
                                />
                            </FormRow>
                            <FormRow style={{ textAlign: "center" }}>
                                <GreenButton variant="contained" type="submit" onClick={this.signUp}>SIGN UP</GreenButton>
                            </FormRow>


                        </form>
                    </Paper>
                </Grid>
            </>
        );
    }
}


export default withSnackbar(SignUpPage);