import { Card, CardContent } from '@mui/material';
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import CostDialog from './Dialog/CostDialog'
import Container from '@mui/material/Container';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Grid from '@mui/material/Grid';
import DomainDropDown from '../components/DomainDropDown';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack'; 
import { SNACK_SUCCESS } from './../constants/common';
import { Heart } from 'react-spinners-css';
import AppBar from '@mui/material/AppBar';
import Toolbar  from '@mui/material/Toolbar';
import { getBootstrapWidthXs } from '../utils';

class CostPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { costs: [], dialogOpen: false, currentCost: null, currentDomainGuid: '', levelsForCurrentDomain: [], width: window.innerWidth  };
    }

    componentWillMount() {
        window.addEventListener('resize', this.onWindowSizeChange);
    }

    componentDidMount() {
        //console.log(this.props.currentUser)
        this.updateCostsList(this.props.currentUser.CurrentDomainGuid)
    }

    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };

    updateCostsList = (domainGuid) => {
        this.setState({loading: true})
        Api.getCostsByDomain(domainGuid)
            .then(res => this.setState({ costs: res.data, currentDomainGuid: domainGuid, loading: false }))
            .catch(err =>{
                this.setState({loading: false})
                Api.logger.errorWithSnackbar(`Fout bij ophalen van kostenlijst`, this, err)});

        Api.getLevelsByDomain(domainGuid)
            .then(res => this.setState({ levelsForCurrentDomain: res.data,loading: false }))
            .catch(err => {
                this.setState({loading: false})
                Api.logger.errorWithSnackbar(`Fout bij het ophalen van de lijst met niveaus`, this, err)});
    };

    deleteCost = (id) => Api.deleteCost(id)
    .then(() => {this.props.enqueueSnackbar("Succesvol verwijderd", SNACK_SUCCESS); this.updateCostsList(this.state.currentDomainGuid)})
    .catch(err =>Api.logger.errorWithSnackbar(`Fout tijdens verwijderen`, this, err));

    adjust = (color, amount) => {
        return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
    }

    render() {
        console.log(this.state.width)
        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle="Kostprijs beheren"  onAddFabClick={() => this.showCostDialog()}/>

                    {this.props.currentUser?.IsSystemAdmin ?
                    <AppBar position="fixed" style={{ top: '56px', background: 'white' }}>
                        <Toolbar style={{ background: 'white' }} position="fixed">
                            <DomainDropDown onDomainSelected={(domainGuid) => this.onDomainUpdated(domainGuid)} />
                        </Toolbar>
                    </AppBar>
                    :
                    ''
                }

                {this.state.loading ? <div align="center" style={{ marginTop: '300px' }}><Heart color="#4AB897" size={100} /></div> :
                    <Container maxWidth="xl" style={{marginTop: this.props.currentUser?.IsSystemAdmin ? '130px' : '75px'}}>
                        <Grid container spacing={3}>
                            {
                                this.state.costs.map((cost) => {
                                    console.log(this.state.width)
                                    let width = getBootstrapWidthXs(this.state.width)
                                    return (
                                        <Grid item key={cost.Id} xs={width}>
                                            <Card elevation={3}>
                                                <CardContent>
                                                    <Grid container spacing={0} alignItems="center" justify="center">
                                                        <Grid item xs={2}>
                                                            <div style={{ backgroundColor: this.adjust(cost.Level.Color, -50), borderRadius: 3, width: 30, height: 30, alignContent: 'center' }}>
                                                                <FavoriteIcon style={{ fill: this.adjust(cost.Level.Color, 20), marginTop: 3, marginLeft: 3 }} />
                                                            </div>
                                                        </Grid>
                                                        {/* <Grid item xs={1}></Grid> */}
                                                        <Grid item xs={10}>{cost.Level.Name}<br />€ {cost.Price}</Grid>
                                                    </Grid>
                                                </CardContent>
                                                <CardActions disableSpacing style={{ borderTop: '1px #EEE solid', justifyContent: 'flex-end' }}>
                                                    <IconButton aria-label="Bewerk" onClick={() => this.showCostDialog(cost)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="Verwijderen" onClick={() => this.deleteCost(cost.Id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                        <CostDialog
                            open={this.state.dialogOpen}
                            dialogAction={this.dialogAction}
                            currentCost={this.state.currentCost}
                            currentDomainGuid={this.state.currentDomainGuid}
                            levels={this.state.levelsForCurrentDomain}
                        />
                    </Container>
                }

            </div>);
    }

    showCostDialog = async (cost) => await this.setState({ currentCost: cost, dialogOpen: true });

    dialogAction = (isCanceled, dialogResult) => {

        this.setState({ dialogOpen: false });
        
        if (isCanceled){
            return;
        }

        let param = {
            Level: {Id: dialogResult.selectedLevelGuid},
            Price : dialogResult.price/1,
            Organization: {Id: this.props.currentUser.CurrentOrgGuid}
        }
        
        let promise; 

        if (!this.state.currentCost) { // dialog for new subscription            
            promise = Api.createCost(param);
        }
        else {
            param.id = this.state.currentCost.Id;
            promise = Api.updateCost(param);
        }

        promise.then(() => {this.props.enqueueSnackbar("Wijzigingen zijn toegepast", SNACK_SUCCESS); this.updateCostsList(this.state.currentDomainGuid)})
            .catch(err => Api.logger.errorWithSnackbar(`Fout bij het wijzigen van de kosten`, this, err));
    }

    
    

    
    
}

const mapStateToProps = (state) => {
     
    return {
        currentUser: state.didoReducer.currentUser
    }
  }

export default withSnackbar(connect(mapStateToProps)(CostPage));