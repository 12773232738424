import React from 'react';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TodayOutlined from "@mui/icons-material/esm/TodayOutlined";
import * as Api from "../services/api/common-api"

import { TOKEN_KEY, USER_KEY } from './../constants/common';
import * as Roles from '../constants/roles';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import {Avatar, Select} from "@mui/material"
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import actions from "../actions.js";
import  Fab  from '@mui/material/Fab';
import  AddIcon  from '@mui/icons-material/Add';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import BarChartIcon from '@mui/icons-material/BarChart';
import {withSnackbar} from 'notistack';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import packageInfo from '../../package.json';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import ReportFilterDialog from './ReportFilterDialog';

const drawerWidth = 340;


const AppBar = styled(MuiAppBar, 
    {
        shouldForwardProp: (prop) => prop !== 'open',
    })
    (({ theme, open }) => ({
        backgroundColor: '#C15F7E',
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        backgroundColor: '#C15F7E',
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const DrawerH = styled('div')(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  }));



class DidoDrawer extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            user: {},
            teams: [],
            locations: [],
            currentLocationId: null,
            teamsOnLocation: [],
            reportsExpanded: false,
            width: window.innerWidth,
            showReportFilterDialog: false
        }
    }

    handleDrawerOpen = () => {
        this.setState({open: true});
    }

    handleDrawerClose = () => {
        this.setState({open: false});
    }

    handleChangeTeam = (event) => {
        this.props.setCurrentTeam( event.target.value)
        this.forceUpdate();
    }

    componentWillMount() {       
        window.addEventListener('resize', this.onWindowSizeChange);
        this.getCurrentUser();
    }

    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };

    onLocationChange = async (event) => this.switchLocation(event.target.value)

    switchLocation = async (newLocationId, newTeamId) => {
        //console.log(this.props.currentUser.Locations)
        if (this.props.currentUser.Locations == null || this.props.currentUser.Locations.length === 0) {
            return;
        }
        var location = this.props.currentUser.Locations.find(l => l.Id === newLocationId);
        //console.log(location)
        if (location != null) {
            await this.setState({ currentLocationId: location.Id, teamsOnLocation: location.Childs })

            //console.log(newTeamId)

            if (newTeamId == null) {
                if (location.Childs.length > 0) {
                    console.log(location.Childs[0].Id)
                    this.props.setCurrentTeam(location.Childs[0].Id)
                }
            }
            else {
                this.props.setCurrentTeam(newTeamId);
            }
        }
        this.forceUpdate();
    }

    getCurrentUser = () => {

        if (this.props.currentTeam == null && (this.props.currentUser && this.props.currentUser.Teams?.length > 0)) {
            this.props.setCurrentTeam(this.props.currentUser.Teams[0].Id)
        }

        if (this.props.currentUser != null) {
            this.setState({
                user: this.props.currentUser,
                teams: this.props.currentUser.Teams,
                locations: this.props.currentUser.Locations
            })

            if (this.props.currentUser.BelongsToTeam) {
                this.props.setCurrentTeam(this.props.currentUser.BelongsToTeam.Id)
            }

            if (this.state.currentLocationId == null && this.props.currentUser.Locations != null && this.props.currentUser.Locations.length > 0) {
                if (this.props.currentTeam != null) {
                    var location = this.props.currentUser.Locations.find(l => l.Childs.some(t => t.Id === this.props.currentTeam));
                    if (location != null) {
                        this.switchLocation(location.Id, this.props.currentTeam)
                    }
                    else console.log(`Location ${location} is not found for team ${this.props.currentTeam}`);
                }
            }
        }
        else if (!this.props.isInfoPage)
        {
            Api.getCurrentUser()
            .then(user => {
                this.setState({
                    user: user.data,
                    teams: user.data.Teams,
                    locations: user.data.Locations,
                    currentLocationId: user.data.Locations.length > 0 ? user.data.Locations[0].Id : '',
                    
                })

                this.props.setCurrentUser(user.data)

                if (user.data.BelongsToTeam) {
                    this.props.setCurrentTeam(this.props.currentUser.BelongsToTeam.Id)
                }
                else {
                    this.switchLocation(this.state.currentLocationId)
                }



                this.props.setCurrentOrg(user.data.CurrentOrgGuid)

                if (this.props.onCurrentUserChanges) { // get info for outside
                    this.props.onCurrentUserChanges(user.data);
                }
            })
            .catch(err => Api.logger.errorWithSnackbar(`Error in getCurrentUser`, this, err));
        }
    }

    isVisibleForRole = (targetRole) => {
        return (this.state.user.Roles && this.state.user.Roles.indexOf(targetRole) !== -1);
    }

    stopImpersonalization = () => {
        
        Api.stopImpersonalization()
            .then(response => {
                if (response.status === 200) {
                    localStorage.setItem(TOKEN_KEY, response.data);
                    localStorage.removeItem(USER_KEY);
                    
                    if (this.props.currentUser?.IsImpersonalization ){ // if impersonalization, then sysAdmin or orgAdmin
                        window.location = '/orgStruct'
                    }
                    else {window.location = '/';}
                }
                else window.location = '/error_on_stop_impersonalization';
            })
            .catch(err => Api.logger.error(`Error in stopImpersonalization`, this, err));
    }

    logout = () => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(USER_KEY)
        window.location = '/login';
    }

    expandReports = () => this.setState({reportsExpanded: !this.state.reportsExpanded})

    onDownloadFreemiumReportClick = async () => {
        await this.setState({ showReportFilterDialog: true })        
    }

    getCurrentTeamTitle = () => {
        let currentTeamTitle = '';
        if (this.props.currentUser && !this.props.currentUser.IsOrgAdmin && !this.props.currentUser.IsSystemAdmin) {            
            let currentLocation = this.state.locations.find(l => l.Id === this.state.currentLocationId)
            let currentTeam = this.state.teamsOnLocation.find(t => t.Id === this.props.currentTeam)


            if (this.props.currentUser.BelongsToTeam != null) {
                currentLocation = this.props.currentUser.Locations.find(l => l.Childs.find(t => t.Id === this.props.currentUser.BelongsToTeam.Id))
                currentTeam = this.props.currentUser.BelongsToTeam
            }

            currentTeamTitle = currentLocation != null && currentTeam != null ?
                this.state.width > 500 ? `${currentLocation.Name} / ${currentTeam.Name}`
                    : `${currentTeam.Name}` : ''
        }

        return currentTeamTitle;
    }
    

    render = () => {
        
        return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    open={this.state.open}
                >
                    <Toolbar>
                        {this.props.currentUser != null &&
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawerOpen}
                                edge="start"
                                sx={{ mr: 2, ...(this.state.open && { display: 'none' }) }}
                            >
                                <MenuIcon />
                            </IconButton>
                        }
                        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
                            {this.props.pageTitle}
                        </Typography>
                        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
                            {this.getCurrentTeamTitle()}
                        </Typography>
                        {
                            this.props.onAddKanbanTask ?
                                <IconButton
                                    color="inherit"                                    
                                    onClick={this.props.onAddKanbanTask}
                                    sx={{ mr: 2 }}
                                ><OnlinePredictionIcon /></IconButton>
                                : ""
                        }
                        {
                            this.props.showDownloadReportButton ?
                                <IconButton
                                    color="inherit"
                                    style={{ display: this.props.onStartDownloadReport ? '' : 'none' }}
                                    onClick={this.props.onStartDownloadReport}
                                    sx={{ mr: 2 }}
                                >
                                    {!(this.props.isFileDownloading) ? <DownloadIcon /> :
                                        <CircularProgress style={{ color: 'white' }} size={20} />
                                    }
                                    <Typography variant="body2" sx={{ ml: 1, color: 'white' }} >PDF</Typography>
                                </IconButton>
                                : ""
                        }                       
                    </Toolbar>
                </AppBar>

                <Drawer
                    onClose={this.handleDrawerClose}
                    anchor="left"
                    variant="temporary"
                    open={this.state.open}
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                          width: drawerWidth,
                          boxSizing: 'border-box',
                        },
                      }}
                >
                    <DrawerH>
                        <a href="/"><img src="/img/dido_drawer_logo.png" alt="DidoMeet" width="178" height="30" />  </a>
                        <Typography variant='caption'>{packageInfo.version}</Typography>
                        <DrawerHeader>
                            <div align="marginRight">
                                <IconButton onClick={this.handleDrawerClose}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                        </DrawerHeader>
                    </DrawerH>
                    <Divider />
                    <ListItem button key={"User"} >
                        <ListItemText primary={(this.state.user.UserName) ? " " + this.state.user.UserName : 'Loading...'} />
                        <IconButton
                            color="inherit"
                            aria-label="stop impersonalization"
                            onClick={this.stopImpersonalization}
                            style={{ display: (this.state.user.IsImpersonalization === true) ? 'inherit' : 'none'}}
                        >
                            <SupervisorAccountIcon />
                        </IconButton>
                    </ListItem>
                    <Divider />
                    {this.isVisibleForRole(Roles.SELECT_TEAM) ?
                        <span>
                            <ListItem>
                                <Select
                                    style={{ display: 'flex' }}
                                    fullWidth
                                    value={this.state.currentLocationId}
                                    onChange={(e) => this.onLocationChange(e)}
                                >
                                    {this.state.locations.map((location) => {
                                        return (
                                            <MenuItem key={location.Id} value={location.Id} style={{ display: 'flex' }}>
                                                <div style={{ display: 'flex', marginLeft: 20 }}>
                                                    <ListItemText primary={location.Name} />
                                                </div>
                                            </MenuItem>
                                        )
                                    })}
                                </Select>

                            </ListItem>


                            <ListItem>
                                <Select
                                    style={{ display: 'flex' }}
                                    fullWidth
                                    value={this.props.currentTeam ?? ''}
                                    onChange={(e) => this.handleChangeTeam(e)}
                                >
                                    {this.state.teamsOnLocation.map((team) => {
                                        return (
                                            <MenuItem key={team.Id} value={team.Id} style={{ display: 'flex' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <ListItemIcon >
                                                        <Avatar src={team.Avatar} style={{ width: 24, height: 24 }} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={team.Name} />
                                                </div>
                                            </MenuItem>
                                        )
                                    })}
                                </Select>

                            </ListItem>
                        </span> : ''}
                    <Divider />
                    <List>
                        {
                            (!this.props.currentUser || this.props.currentUser.IsSystemAdmin || this.props.currentUser.IsOrgAdmin || !this.props.currentUser.IsChatBotEnabled) ?
                                '' :
                                <ListItem button key={"Assistant"} component="a" href="/assistant">
                                    <ListItemIcon><TodayOutlined /></ListItemIcon>
                                    <ListItemText primary={"Team assistent"} />
                                </ListItem>
                        }
                        {this.isVisibleForRole(Roles.REPORT_DASHBOARD) ?
                            <ListItem button key={"Dashboard"} component="a" href="/report/dashboard">
                                <ListItemIcon><TodayOutlined /></ListItemIcon>
                                <ListItemText primary={"Inzichten bewoners"} />
                            </ListItem> : ''}
                        {this.isVisibleForRole(Roles.REPORT_MANAGMENT_DASHBOARD) ?
                            <ListItem button component="a" href="/report/management_dashboard" >
                                <ListItemIcon><TodayOutlined /></ListItemIcon>
                                <ListItemText primary="Inzicht organisatie" />
                            </ListItem> : ''}
                        {this.isVisibleForRole(Roles.REPORT_DAY_STRUCTURE) ? // todo one-report access
                            <ListItem button component="a" href="/report/management_daystructure" >
                                <ListItemIcon><TodayOutlined /></ListItemIcon>
                                <ListItemText primary={Roles.REPORT_DAY_STRUCTURE} />
                            </ListItem> : ''}
                        {this.isVisibleForRole(Roles.MANAGE_USERS) ?
                        <ListItem button key={"Users"} component="a" href="/users">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Gebruikers"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_PROFILES) ?
                        <ListItem button key={"Profiles"} component="a" href="/profiles">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Profielen"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_SUBSCRIPTIONS) ?
                        <ListItem button key={"Subscriptions"} component="a" href="/subscriptions">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Subscriptions"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_ACTIVITIES) ?
                        <ListItem button key={"Activities"} component="a" href="/activities">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Activities"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_ORG_STRUCTURE) ?
                        <ListItem button key={"OrgStruct"} component="a" href="/orgStruct">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Bewoners"} />
                        </ListItem>: ''} 

                        {this.isVisibleForRole(Roles.MANAGE_ZORG_PROFILES) ?
                        <ListItem button key={"ZorgProfile"} component="a" href="/zorgProfile">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"ZorgProfiel"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_LEVELS) ?
                        <ListItem button key={"Level"} component="a" href="/level">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Niveau"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_LEVELS) ?
                        <ListItem button key={"Freemium"} component="a" href="/freemium">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Freemium"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_INFO_PAGES) ?
                        <ListItem button key={"InfoPages"} component="a" href="/info_pages">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"InfoPages"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_LEVELS) ?
                        <ListItem button key={"ChatSettings"} component="a" href="/chat_settings">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Chat settings"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_COSTS) ?
                        <ListItem button key={"Cost"} component="a" href="/cost">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Cost"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_AGENDA) ?
                        <ListItem button key={"Agenda"} component="a" href="/agenda">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Agenda"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_UNITFORMS) ?
                        <ListItem button key={"forms"} component="a" href="/forms">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Formulieren"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_KANBAN_THEMES) ?
                        <ListItem button key={"Theme"} component="a" href="/theme">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Verbeter thema's"} />
                        </ListItem>: ''}

                        {this.isVisibleForRole(Roles.MANAGE_KANBAN) ?
                        <ListItem button key={"Kanban"} component="a" href="/kanban">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Leren en verbeteren"} />
                        </ListItem>: ''}

                        
                        {this.isVisibleForRole(Roles.REPORT_GROUP) ||
                            this.isVisibleForRole(Roles.REPORT_UNIT) ||
                            this.isVisibleForRole(Roles.REPORT_CATEGORY) ||
                            this.isVisibleForRole(Roles.REPORT_DAY) ||
                            this.isVisibleForRole(Roles.REPORT_DAY_TABLE) ||
                            this.isVisibleForRole(Roles.REPORT_LEVEL) ||
                            this.isVisibleForRole(Roles.REPORT_UNIT_WEEK) ||
                            this.isVisibleForRole(Roles.REPORT_CATEGORIES) ||
                            this.isVisibleForRole(Roles.REPORT_KANBAN_TEAM) ||
                            this.isVisibleForRole(Roles.REPORT_KANBAN_ORG) ||
                            this.isVisibleForRole(Roles.REPORT_ZZP45) ||
                            this.isVisibleForRole(Roles.REPORT_MANAGMENT_DASHBOARD)
                        ?
                            <Box>
                                <ListItem button key={"Report"} onClick={this.expandReports}>
                                    <ListItemIcon><TodayOutlined /></ListItemIcon>
                                    <ListItemText primary={"Rapporten"} />
                                    {this.state.reportsExpanded ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>

                                <Collapse in={this.state.reportsExpanded} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding style={{ paddingLeft: '1vw' }} >
                                        {this.isVisibleForRole(Roles.REPORT_GROUP) ?
                                        <ListItem button component="a" href="/report/group" >
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Analyse zorgprofiel groep" style={{ paddingLeft: '-2vw' }} />
                                        </ListItem>: ''}

                                        {this.isVisibleForRole(Roles.REPORT_UNIT) ?
                                        <ListItem button component="a" href="/report/unit" >
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Analyse zorgprofiel cliënt" />
                                        </ListItem>:''}

                                        {this.isVisibleForRole(Roles.REPORT_CATEGORY) ?
                                        <ListItem button component="a" href="/report/category" >
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Analyse per categorie" />
                                        </ListItem>:''}

                                        {this.isVisibleForRole(Roles.REPORT_DAY) ?
                                        <ListItem button component="a" href="/report/day" >
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Dagstructuur groep" />
                                        </ListItem>:''}

                                        {this.isVisibleForRole(Roles.REPORT_DAY_TABLE) ?
                                        <ListItem button component="a" href="/report/day_table" >
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Dagstructuur groep uitgedrukt in minuten per tijdvak" />
                                        </ListItem>:''}

                                        {this.isVisibleForRole(Roles.REPORT_LEVEL) ?
                                        <ListItem button component="a" href="/report/level" >
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Teamsamenstelling per dag" />
                                        </ListItem>:''}

                                        {this.isVisibleForRole(Roles.REPORT_UNIT_WEEK) ?
                                        <ListItem button component="a" href="/report/unit_week" >
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Individuele cliëntagenda" />
                                        </ListItem>:''}

                                        {this.isVisibleForRole(Roles.REPORT_CATEGORIES) ?
                                        <ListItem button component="a" href="/report/categories" >
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Activiteiten per niveau" />
                                        </ListItem>:''}

                                        {this.isVisibleForRole(Roles.REPORT_KANBAN_TEAM) ?
                                        <ListItem button component="a" href="/report/kanbanTeam" >
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Teamtaken" />
                                        </ListItem>:''}

                                        {this.isVisibleForRole(Roles.REPORT_KANBAN_ORG) ?
                                        <ListItem button component="a" href="/report/kanbanOrg" >
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Organisatietaken" />
                                        </ListItem>:''}

                                        {this.isVisibleForRole(Roles.REPORT_ZZP45) ?
                                        <ListItem button component="a" href="/report/zzp45" >
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Indicatie spiegel" />
                                        </ListItem>:''}
                                    </List>
                                </Collapse>
                            </Box>
                            : ''}

                        {this.isVisibleForRole(Roles.REPORT_UNIT_WEEK_FREEMIUM) ?
                            <ListItem button onClick={this.onDownloadFreemiumReportClick}>
                                <ListItemIcon>
                                    <DownloadIcon />
                                </ListItemIcon>
                                <ListItemText primary="Individuele cliëntagenda (Pdf)" />
                            </ListItem> : ''}

                        {this.isVisibleForRole(Roles.SELECT_TEAM) ?
                        <ListItem button  key={"available_time"} component="a" href="/available_time">
                        <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary="Beschikbare inzet" />
                        </ListItem> : ''}

                        <ListItem button key={"Logout"} component="a" onClick={this.logout}>
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Uitloggen"} />
                        </ListItem>

                        

                    </List>
                </Drawer>
                {(this.props.onAddFabClick == null) ? '' :
                    <Fab style={{ backgroundColor: '#4AB897', zIndex: 1200, color: 'white', right: '8vw', top: '3vh', position: 'fixed' }} aria-label="add">
                        <AddIcon onClick={(e) => this.props.onAddFabClick(e)} />
                    </Fab>
                }
                {(this.props.controlsFragment == null) ? '' : this.props.controlsFragment}
                {this.state.showReportFilterDialog ?
                    <ReportFilterDialog
                        open={this.state.showReportFilterDialog}
                        dialogAction={() => this.setState({ showReportFilterDialog: false })}
                    />
                    : ""}
            </Box>
            
        );
    }
}

const mapStateToProps = (state) => ({
        currentTeam: state.didoReducer.currentTeam,
        currentOrg: state.didoReducer.currentOrg,
        currentUser: state.didoReducer.currentUser
});

  const mapDispatchToProps = (dispatch) =>({
    setCurrentTeam: (id) => dispatch(actions.setCurrentTeam(id)),
    setCurrentOrg: (id) => dispatch(actions.setCurrentOrg(id)),
    setCurrentUser: (user) => dispatch(actions.setCurrentUser(user))
  });

  export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(DidoDrawer));