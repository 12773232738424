import { styled, Avatar } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Heart } from 'react-spinners-css';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import  IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  Typography  from '@mui/material/Typography';
import { MoreHoriz } from '@mui/icons-material';
import {OrgStructType, ItemTypeDescr, ItemLevelIcons} from './../constants/OrgStructConst'
import React from "react";
import  NavigateNextIcon  from '@mui/icons-material/NavigateNext';
import  ExpandMore  from '@mui/icons-material/ExpandMore';
import * as Api from '../services/api/common-api'
import {ActivePaper} from './ActivePaper'


class OrgItem extends React.Component {

    setVisible = (item, itemLevel) => (itemLevel !== 0 && (item.Visible == null || item.Visible === false)) ? 'none' : '';

    renderExpandIcon(item) {
        let icon;
        if (item.type === OrgStructType.Unit) { // if terminal leaf
            icon = <div>&nbsp;&nbsp;</div>
        }
        else {
            icon = (item.IconExpanded == null || item.IconExpanded === false) ?
                <NavigateNextIcon /> :
                <ExpandMore />
        }   

        return (
            <IconButton>{icon}</IconButton>
        )
    }

    toggleExpandIcon = (item) => {
        //console.log("toggle " + JSON.stringify(item)) 
        if (!item.IconExpanded) 
        {
            if (item.type ===  OrgStructType.Organization) {
                Api.getLocationsWithoutChilds(item.Id)
                .then(res => {
                    item.Childs = res.data;
                    item.Childs.forEach((child) => {
                        child.Visible = true;
                        child.type = OrgStructType.Location;
                        child.parent = item;
                    })
                    item.IconExpanded = true; 
                    this.forceUpdate()                    
                })
            }
            if (item.type === OrgStructType.Location) {
                Api.getTeamsByLocation(item.Id)
                .then(res => {
                    item.Childs = res.data;
                    item.Childs.forEach((child) => {
                        child.Visible = true; 
                        child.type = OrgStructType.Team;
                        child.parent = item;})
                    item.IconExpanded = true; 
                    this.forceUpdate()
                })
            } 
            if (item.type === OrgStructType.Team) {
                Api.getByTeamWithZpAsync(item.Id)
                .then(res => {
                    item.Childs = res.data;
                    item.Childs.forEach((child) => {
                        child.Visible = true; 
                        child.type = OrgStructType.Unit;
                        child.parent = item;})
                    item.IconExpanded = true; 
                    this.forceUpdate()
                })
            }                  
        }
        else // already loaded from server, just update
        {
            item.Childs.forEach((child) => child.Visible = !child.Visible)
            item.IconExpanded = !item.IconExpanded;
            //console.log(item.IconExpanded)
           
            this.forceUpdate();
        }
    }

    render() {
        var item = this.props.item;
        var itemLevel = this.props.itemLevel;
        


        item.type = ItemTypeDescr[itemLevel];

        let hint = ItemTypeDescr[itemLevel];
        if (item.type === OrgStructType.Organization) { // organization
            if (item.Subscription != null) {
                if (this.props.width > 1100)
                    hint = `${item.Subscription.Name}, Maximaal aantal groepen: ${item.Subscription.MaxTeams}`
                else
                    hint = `${item.Subscription.Name}`
            }
        }
        else if (item.type === OrgStructType.Unit) { // unit
            //console.log(item)
            if (item.ZorgProfile != null) {
                hint = item.ZorgProfile.Name;
            }

        }
        //console.log(item.Avatar)
        let avatar = item.type === OrgStructType.Team ? <Avatar src={item.Avatar} style={{ width: 24, height: 24 }} /> : <span /> //avatars for teams. todo use letter if no img
        const isUnitEditDeleteButtonDisabled = item.type === OrgStructType.Unit && this.props.currentUser.IsSystemAdmin;
        return (
            <div key={item.Id}
                style={{ paddingLeft: itemLevel > 0 ? '30px' : 0, display: this.setVisible(item, itemLevel) }}
            >
                <ActivePaper key={item.Id}>
                    <Grid container spacing={0} alignItems="center" justify="center" key={item.Id}>
                        <Grid item xs={6} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginTop: '5px', cursor: 'pointer' }}
                            onClick={() => this.toggleExpandIcon(item)}
                        >
                            {this.renderExpandIcon(item)}
                            <IconButton disabled>
                                <FontAwesomeIcon icon={ItemLevelIcons[itemLevel]} />
                            </IconButton>

                            {item.Name}
                            <IconButton disabled>{avatar}</IconButton>
                        </Grid>
                        <Grid item xs={4} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            <Typography color={'textSecondary'} variant={'body2'}>{hint}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton disabled={isUnitEditDeleteButtonDisabled} onClick={(e) => this.props.showContextMenu(e, item, this.props.contextMenuTree[itemLevel])}>
                                <MoreHoriz color={isUnitEditDeleteButtonDisabled ? 'disabled' : 'primary'} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </ActivePaper>
                {
                    (item.Childs != null) ? item.Childs.map((childItem) => {
                        return(
                        <OrgItem 
                        key={childItem.Id}
                        item={childItem}
                        itemLevel={itemLevel+1}
                        currentUser={this.props.currentUser}
                        contextMenuTree={this.props.contextMenuTree}
                        width={this.props.width}
                        showContextMenu={this.props.showContextMenu}
                        />    
                        )}) : ""
                }
            </div>
        )
    }
}

export default (OrgItem);