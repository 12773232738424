import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import GridList from '@mui/material/ImageList';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import * as Utils from '../utils/';
import { Heart } from 'react-spinners-css';
import { connect } from "react-redux";
import actions from "../actions.js";
import history from '../utils/history';
import { withSnackbar } from 'notistack';
import { BasePage } from './BasePage';

class HomePage extends BasePage { 

    constructor(props) {        
        super(props);

        this.setState({
            lastTeam: null
        });
    }

    componentDidMount(){
        //console.log("CurTeam" + JSON.stringify(this.props.currentTeam))        
        if (this.props.currentTeam != null){
            console.log("fetchUnitActivities1")
            this.props.dispatch(actions.fetchUnitActivities(this.props.currentTeam))
            
        }
    }

    componentDidUpdate() {
        // console.log("CurTeam" + JSON.stringify(this.props.currentTeam))
        // console.log("LastTeam" + JSON.stringify(this.props.lastTeam))

        if (this.props.currentTeam !== this.state.lastTeam && this.state.lastTeam === null && !this.props.isFetching) { //after impersonalization
            
            if (this.props.currentTeam)
                this.props.dispatch(actions.fetchUnitActivities(this.props.currentTeam))
            this.setState({ lastTeam: this.props.currentTeam })
            return;
        }

        if (this.props.currentTeam !== this.state.lastTeam) {
            if (this.state.lastTeam !== null) { // todo team has been changed (keep prev team in redux state?)
                
                if (this.props.currentTeam)
                    this.props.dispatch(actions.fetchUnitActivities(this.props.currentTeam))
            }
            this.setState({lastTeam: this.props.currentTeam})
        }
    }


    render() {
        if (this.props.currentUser?.IsSystemAdmin || this.props.currentUser?.IsOrgAdmin){
            window.location.href = "/orgStruct"
            //history.replace("/orgStruct")
        }

        //console.log(`RENDER HomePage ${JSON.stringify(this.props)}`)
       
        let colors = ["#4eb798", "#9b4e68", "#fdd07e","#1788ac", "#00876d", "#ac5674", "#a9d2a0",  "#41bbc9", "#c05e7d","#757675", "#744c69","#9d9c9c",  ]
        let colorIndex = 0;
        let lastStartTime = null
        return (
            <div>
                <DidoDrawer  style={{ zIndex: 2000 }} pageTitle="Startpagina" noFab/>
                
                <Container maxWidth="lg" style={{ marginTop:'10vh' }}>
                    <Paper>
                    {this.props.isFetching ? <div align="center" style={{ height: '80vh'}}><Heart color="#4AB897" size={100} style={{ marginTop:'25vh'}} /></div> :
                    // <GridList cols={6} spacing={15} cellHeight='auto'>
                                <Timeline align="left" >
                                    {                                        
                                        this.props.agendaRows && this.props.agendaRows.map((row) => {
                                            if (lastStartTime == null) {
                                                lastStartTime = row.Row[0].StartTime
                                            }
                                            else if (lastStartTime !== row.Row[0].StartTime) {
                                                lastStartTime = row.Row[0].StartTime
                                                colorIndex++
                                                if (colorIndex >= colors.length)
                                                    colorIndex = 0
                                            }

                                            let rowHeight = row.Row.length / 10 * 70;

                                            return (

                                                <TimelineItem key={row.Id} style={{ flex: 1, minHeight: 'auto'  }} >
                                                    <TimelineOppositeContent style={{ flex: 0.1 }} >
                                                        <Typography color="textSecondary" variant={this.state.width > 700 ? 'body2':'caption'}>{row.TimeLineRangeHint}</Typography>
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator>
                                                        <TimelineDot style={{ backgroundColor: colors[colorIndex] }} />
                                                        <TimelineConnector style={{ backgroundColor: colors[colorIndex], height: rowHeight }} />
                                                    </TimelineSeparator>
                                                    <TimelineContent>
                                                        <Grid container spacing={1} >
                                                            {
                                                                row.Row.map((unitActivity) => {


                                                                    let [hours, minutes] = unitActivity.StartTime.split(':');

                                                                    let gridStart = minutes / 60 * 12 - 1;
                                                                    gridStart = gridStart < 0 ? 0 : gridStart;
                                                                    gridStart = Math.round(gridStart);
                                                                    let duration = unitActivity.Duration / 60 * 12
                                                                    duration = Math.round(duration);

                                                                    let hoursEnd = hours;
                                                                    let minutesEnd = minutes / 1 + unitActivity.Duration
                                                                    if (minutesEnd > 60) {
                                                                        minutesEnd -= 60;
                                                                        hoursEnd++;
                                                                        duration = 12 - gridStart;
                                                                    }

                                                                    //console.log(this.state.width)
                                                                    let xs = 12;
                                                                    if (this.state.width > 1400)
                                                                    xs = 3;
                                                                    else if (this.state.width > 1000)
                                                                    xs = 4;
                                                                    else if (this.state.width > 750)
                                                                    xs = 6;
                                                                    // else if (this.state.width > 600)
                                                                    // xs=6;

                                                                    return (
                                                                        <Grid item xs={xs} key={unitActivity.Id}>
                                                                            <Paper
                                                                                elevation={0}
                                                                                //variant='outlined'
                                                                                square style={{ background: Utils.setOpacity(unitActivity.Level.Color, 0.15), flex: 1, padding: 5 }}>

                                                                                <Grid container >
                                                                                    <Grid item xs={6} style={{ fontWeight: 'bold' }}>{unitActivity.Level.Name}</Grid>
                                                                                    <Grid item xs={2}></Grid>
                                                                                    <Grid item xs={4} align="right">
                                                                                        <Typography variant='caption' >{unitActivity.Unit ? unitActivity.Unit.Name : unitActivity.Team ? unitActivity.Team.Name : '???'}</Typography></Grid>
                                                                                    <Grid item xs={12} ><Typography variant='caption' component="h2" noWrap style={{ wordWrap: 'break-word' }}>{unitActivity.Activity.Name} ({unitActivity.Activity.CategoryName})</Typography></Grid>
                                                                                    <Grid item xs={12}><Typography variant='caption' >{unitActivity.Description}</Typography></Grid>
                                                                                </Grid>

                                                                            </Paper>
                                                                            <Paper
                                                                                elevation={0}
                                                                                square
                                                                                style={{ background: Utils.setOpacity(unitActivity.Level.Color, 0.4), height: 10 }}
                                                                            >
                                                                                <Grid container >
                                                                                    {(gridStart > 0) ? <Grid item xs={gridStart}></Grid> : ''}
                                                                                    <Grid item xs={duration} style={{ background: Utils.setOpacity(unitActivity.Level.Color, 0.9), height: 10, clear: 'both' }}></Grid>
                                                                                </Grid>
                                                                            </Paper>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }

                                                        </Grid>
                                                    </TimelineContent>
                                                </TimelineItem>

                                            );
                                        })
                                    }
                                </Timeline>
                            // </GridList>
    }
                    </Paper>
                    </Container>
                
            </div>

        );
    }
}

const mapStateToProps = (state) => {
     
    return {
        currentTeam: state.didoReducer.currentTeam,
        currentOrg: state.didoReducer.currentOrg,
        agendaRows: state.didoReducer.agendaRows,
        isFetching: state.didoReducer.isFetching,
        currentUser: state.didoReducer.currentUser
    }
  }

export default withSnackbar(connect(mapStateToProps)(HomePage));

