import React from 'react';

class CheckInboxPage extends React.Component {
  render() {
    return <div style={{textAlign: 'center', marginTop:'30vh',}}>
      <img src="/img/mailbox.png" width="180" height="180" alt="mailbox" />
      <h2 style={{textAlign: 'center',  color: 'white'}}>
        Controleer je <a href="mailto:">inbox</a> a.u.b. <br/>
        <a href="https://gmail.com">GMail</a><br/>
        <a href="#">Mail.nl</a></h2>
      </div>;
  }
}

export default CheckInboxPage;