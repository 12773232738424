import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
//import { withStyles } from "@mui/material/styles";
import { InputAdornment, MenuItem, Select } from '@mui/material';
import * as Api from '../../services/api/common-api'
import { FormRow } from './../../components/FormRow';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


class CostDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            selectedLevelGuid : null,
            price : null
        };
    }

    onChangeLevel  = async (event) => {        
        await this.setState({selectedLevelGuid : event.target.value});
    }    
    currentLevelGuid = () => (this.state.selectedLevelGuid) ?
        this.state.selectedLevelGuid :
        (this.props.currentCost) ?
            this.props.currentCost.Level.Id :
            this.props.levels.length > 0 ?
                this.props.levels[0].Id :
                ""

    onPriceChange = (e) => this.setState({ price: e.currentTarget.value })
    currentPrice = () => (this.state.price) ? this.state.price : (this.props.currentCost)? this.props.currentCost.Price : 0

    

    onOkClick = async () => {
        if (!this.state.selectedLevelGuid) {
            await this.setState({ selectedLevelGuid: this.currentLevelGuid() })
        }
        if (!this.state.price){
            await this.setState({price: this.currentPrice()})
        }
        this.props.dialogAction(false, this.state);
        this.setState({ selectedLevelGuid: null, price: null })
    }
    
    onCancelClick = () => {
        this.props.dialogAction(true);
        this.setState({ selectedLevelGuid: null, price: null })
    }

    render() {
        let currentLevelGuid = this.currentLevelGuid()
        let price = this.currentPrice()
        //console.log("render" + price)
        return (
            <Dialog open={this.props.open}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.currentCost ? "Kostprijs bewerken" : "Nieuwe kostprijs"}</Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormRow>
                        <Select 
                        fullWidth
                        value={currentLevelGuid}
                        onChange={(e) => this.onChangeLevel(e)}
                        > 
                            {this.props.levels.map((level) => {
                                return (
                                    <MenuItem key={level.Id} value={level.Id}>{level.Name}</MenuItem>
                                )
                            })}
                        </Select>
                        </FormRow>
                        <FormRow>
                        <TextField
                            name="Cost"
                            required
                            label="Kostprijs"
                            type="number"
                            inputProps = {{step:0.01}}
                            value={price}
                            onChange={this.onPriceChange}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€ </InputAdornment>,
                              }}
                        />
</FormRow>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.onOkClick()} color="primary">
                        OPSLAAN
          </Button>
                    <Button onClick={() => this.onCancelClick()} color="primary">
                        TERUG
          </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

//export default withStyles(styles, { withTheme: true })(CostDialog);
export default CostDialog;