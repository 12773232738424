import { MESSAGE_TYPE_ASSISTANT } from '../../constants/common';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { Button } from '@mui/material';
import Markdown from 'react-markdown'
import Tiles from './Tiles';
import "../../css/speech.css"

function LoadingBar(props){
    if (props.IsLastAssistantMessage) { // && key === msgBlocks.length - 1
        return <div key={crypto.randomUUID()}>
            {/* {props.val}&nbsp; */}
            <span className={props.IsResponseLoading ? 'blink' : 'displayNone'}>{String.fromCharCode(9632)}</span>
            {props.IsResponseLoading ?
                <div style={{ marginTop: '3vh' }}><Button
                    variant="contained"
                    startIcon={<CancelScheduleSendIcon />}
                    onClick={props.onCancelMessageClick}
                    style={{ backgroundColor: "#C15F7E" }}
                >
                    Cancel run
                </Button></div> :
                <></>}
        </div>
    }

    return <></>
}

export default function ChatMessage(props) {
    console.log(props);
    const bgColor = props.MessageType === MESSAGE_TYPE_ASSISTANT ? "#EEE" : "#6ed7b8";
        
    return (
        <>
            {/* <div style={{ backgroundColor: bgColor, borderRadius: "10px", padding: '1px', paddingLeft: '10px' }}>                 */}
                
                <div key={crypto.randomUUID()} style={{backgroundColor:bgColor}} 
                className={props.MessageType === MESSAGE_TYPE_ASSISTANT ? 'speech left' : 'speech right'} ><LoadingBar key={crypto.randomUUID()} val={props.Message} {...props} /><Markdown>{props.Message}</Markdown></div>                
            {/* </div> */}
            <Tiles {...props}/>
        </>
    )
}

{/* {!props.Citations ? <></> : <>
                <Accordion key={crypto.randomUUID()} sx={{ marginTop: '2px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Show references
                    </AccordionSummary>
                    <AccordionDetails>
                        {props.Citations.map(ct => <>
                            <Typography variant='subtitle2'><strong>{ct.FileName}</strong></Typography>
                            <Typography variant='body2'>{ct.Text}</Typography>
                        </>
                        )}
                    </AccordionDetails>
                </Accordion>
            </>} */}