import * as Api from './services/api/common-api'
//import logger from './services/api/log-api'

var setCurrentTeam = function (team) {
    //Api.startLoadData(team);    
    return {
        type: "SET_TEAM",
        team
    }
};

 var setCurrentOrg = function (org) {
    
    return {
        type: "SET_ORG",
        org
    }
}

var setCurrentUser = function (user) {
  //console.log("setCurrentUser")
  return {
    type: "SET_USER",
    user
  }
}

export const requestUnitActivities = (team) => {
    //console.log(`ACTION requestUnitActivities ${team}`)
  
    return {
      type: 'REQUEST_AGENDA_ROWS',
      team
    }
  }

  export const receivedUnitActivities = (team, data) => {
    //console.log(`ACTION receivedUnitActivities ${team}, ${JSON.stringify(data)}`)

    return {
      type: 'RECEIVE_AGENDA_ROWS',
      team,
      agendaRows: data
    }
  }

var fetchUnitActivities = (team) => {
    
    //console.log(`ACTION fetchPosts wrapper subreddit=${team}`)
    if (team == null) {
      console.log("team is null in fetchUnitActivities")
    }

    return (dispatch, getState) => {
        dispatch(requestUnitActivities(team))
        //console.log(`fetching................`)

        return Api.getAgendaRowsForStartPage(team)
        .then(res => {
            //console.log(`ACTION fetch response ${JSON.stringify(res.data)} `)

            return dispatch(receivedUnitActivities(team, res.data))
        })
        .catch(err => Api.logger.error(`Error in fetchUnitActivities(${team})`, err))
    }
}

export default { setCurrentTeam, setCurrentOrg,setCurrentUser, fetchUnitActivities };