var didoReducer = function (state = {}/*= /*Map(){}*/, action) {
    //console.log(`didoReducer.js: action=${JSON.stringify(action)}, state=${JSON.stringify(state)}`)
    switch (action.type) {
        case "SET_TEAM":
            return {
                currentTeam: action.team,
                currentUser: state.currentUser,
                currentOrg: state.currentOrg
            };
        case "SET_ORG":
            return {
                currentTeam: state.currentTeam,
                currentUser: state.currentUser,
                currentOrg: action.org
            }
            case "SET_USER":
                return {
                    ...state,
                    currentUser: action.user
                    }
        case "REQUEST_AGENDA_ROWS":
            return {
                ...state,
                isFetching: true,
                agendaRows: []
            }
        case "RECEIVE_AGENDA_ROWS":
            return {
                ...state,
                isFetching: false,
                agendaRows: action.agendaRows
            } 
            default:
                return state;

    }

}

export default didoReducer;