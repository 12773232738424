import { Grid, Paper, styled, Typography } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const ActiveTile = styled(Paper)(({ theme }) => ({    
    height: '5vh',
    verticalAlign: 'middle',
    background: '#f8ccec',
    width:'100%',
    overflow: 'clip', 
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    alignContent: 'center',    
    border: '1px solid #d8accc',
    '&:hover': {
        background: '#d8accc'
    }
}));

export default function Tiles(props) {

    const tiles = props.Tiles;    

    return (
        <Grid container spacing={1} justify="space-between" alignItems="stretch" sx={{ marginTop: '5px', marginLeft: '20px', marginRight: '20px'}} >
            {tiles?.map(tile =>
            <Grid item xs={4} sx={{display :"flex"}}>
                <ActiveTile onClick={() => props.onTileClick(tile)} elevation={1}>
                    <Grid container>
                        <Grid item xs={3} sx={{textAlign: 'center', verticalAlign: 'middle', alignContent: 'center'}}>
                            {tile.Avatar ?
                                <img src={tile.Avatar} width={40} height={40} alt="" style={{marginTop: '0.5vh'}}/> : // sx={{ width: 32, height: 32 }} 
                                <AutoFixHighIcon sx={{width: 24, height: 24}} />
                            }
                        </Grid>
                        <Grid item xs={9} alignContent={'center'} >
                            <Typography>{tile.Name}</Typography></Grid>
                    </Grid>
                </ActiveTile>
            </Grid>
        )}
        </Grid>

    )
}
