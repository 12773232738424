import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
//import { withStyles } from "@mui/material/styles";
import { MenuItem, Typography } from '@mui/material';
import  Grid from '@mui/material/Grid';
import  Checkbox  from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import { EMPTY_GUID } from '../../constants/common';
import { BaseDialog } from './BaseDialog';
import moment from 'moment';
import{ ThemeProvider, createTheme } from "@mui/material/styles";
import { styled } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const WeekDay = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }));


const materialTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#4AB897'
            }
        },
    }})

class UnitActivityDialog extends BaseDialog {
    constructor(props) {
        super(props,  {            
            selectedUnitGuid : null,
            selectedLevelGuid: null,
            selectedCategoryGuid: null,
            selectedActivityGuid: null,
            descriptionField: null,
            weekdayField: null,
            startTimeField: null,
            durationField: null
        });
    }

    // Unit
    handleChangeUnit = async (event) => { await this.setState({ selectedUnitGuid: event.target.value }) };
    currentUnitGuid = () => {
        if (this.state.selectedUnitGuid)
        return this.state.selectedUnitGuid;

        if (this.props.currentUnitActivity) { // edit mode
            if (this.props.currentUnitActivity.Unit)
                return this.props.currentUnitActivity.Unit.Id
            else if (this.props.currentUnitActivity.Team)
                return EMPTY_GUID;
        }
        else if (this.props.units?.length > 0 ){
            if (this.props.currentUnitGuid)
            return this.props.currentUnitGuid

            return this.props.units[0].Id;
        }

        return "";
    }


    // Level
    handleChangeLevel = async (event) => await this.setState({ selectedLevelGuid: event.target.value });
    currentLevelGuid = () => (this.state.selectedLevelGuid) ?
        this.state.selectedLevelGuid :
        (this.props.currentUnitActivity) ?
            this.props.currentUnitActivity.Level?.Id :
            this.props.levels?.length > 0 ?
                this.props.levels[0].Id :
                ""

    
    // WeekDay checkboxs
    handleWeekDay = (event) => {
        const weekdayMask = this.currentWeekDay();
        this.setState({
            weekdayField:
                event.target.value === 'Ma' ?
                    event.target.checked ?  weekdayMask | 1 : weekdayMask & ~1 :
                    event.target.value === 'Di' ?
                        event.target.checked ? weekdayMask | 2 : weekdayMask & ~2 :
                        event.target.value === 'Wo' ?
                            event.target.checked ? weekdayMask | 4 : weekdayMask & ~4 :
                            event.target.value === 'Do' ?
                                event.target.checked ? weekdayMask | 8 : weekdayMask & ~8 :
                                event.target.value === 'Vr' ?
                                    event.target.checked ? weekdayMask | 16 : weekdayMask & ~16 :
                                    event.target.value === 'Za' ?
                                        event.target.checked ? weekdayMask | 32 : weekdayMask & ~32 :
                                        event.target.value === 'Zo' ?
                                            event.target.checked ? weekdayMask | 64 : weekdayMask & ~64 :
                                            0
        });
    }
    currentWeekDay = () => this.state.weekdayField ? this.state.weekdayField : this.props.currentUnitActivity ? this.props.currentUnitActivity.DayOfWeek : 127

    // Start & Duration
    handleChangeStartTime = (evt) => {
        var newValue = "00:00"
        if (evt) {
            newValue = moment(evt).format("HH:mm");
        }
        this.setState({startTimeField : newValue})
    }; 
    currentStartTime = () => this.state.startTimeField ? this.state.startTimeField : this.props.currentUnitActivity ? this.props.currentUnitActivity.StartTime : "06:00"

    handleChangeDuration =  (evt) =>this.setState({durationField : evt.target.value === ''? '' : Number(evt.target.value)})
    handleChangeDurationSlider = (evt, val) => this.setState({durationField : val})
    currentDuration = () => this.state.durationField ? this.state.durationField : this.props.currentUnitActivity ? this.props.currentUnitActivity.Duration : 15


    // Category
    handleChangeCategory = async (event) => {        
        await this.setState({
        selectedCategoryGuid: event.target.value
    })};

    currentCategoryGuid = () => (this.state.selectedCategoryGuid) ?
        this.state.selectedCategoryGuid :
        (this.props.currentUnitActivity) ?
            this.props.categories?.find(c => c.Childs?.find(a => a.Id.toLowerCase() === this.props.currentUnitActivity.Activity?.Id.toLowerCase())).Id :
            this.props.categories?.length > 0 ?
                this.props.categories[0]?.Id :
                ""

    
    // Activity
    getActivitiesByCategory = () => {
        const categoryGuid = this.currentCategoryGuid();
        if (categoryGuid === "")
            return [];

        return this.props.categories?.find(c => c.Id === categoryGuid)?.Childs
    }   
    
    handleChangeActivity = async (event) => await this.setState({ selectedActivityGuid: event.target.value });
    currentActivityGuid = () => (this.state.selectedActivityGuid) ?
        this.state.selectedActivityGuid :
        (this.props.currentUnitActivity) ? this.props.currentUnitActivity.Activity?.Id :
            (()=>{
                var activities = this.getActivitiesByCategory()
                return activities.length > 0 ?
                activities[0].Id :
                ""})()

    // Description
    handleChangeDescription = (event) => this.setState({ descriptionField: event.target.value })
    currentDescription = () => (this.state.descriptionField) ? this.state.descriptionField : (this.props.currentUnitActivity) ? this.props.currentUnitActivity.Description : ""


    handleOkClick = async () => {
        await this.setState({ 
            selectedUnitGuid: this.state.selectedUnitGuid || this.currentUnitGuid(),
            selectedLevelGuid: this.state.selectedLevelGuid || this.currentLevelGuid(),
            weekdayField: this.state.weekdayField  || this.currentWeekDay(),
            startTimeField: this.state.startTimeField || this.currentStartTime(),
            durationField: this.state.durationField || this.currentDuration(),
            selectedActivityGuid: this.state.selectedActivityGuid || this.currentActivityGuid(),
            descriptionField: this.state.descriptionField || this.currentDescription(),
         })
        
        this.props.dialogAction(false, this.state);
        this.flushState();
    }
    
    onCancelClick = () => {
        this.props.dialogAction(true);
        this.flushState();
    }

    render() {
        //this.getCategoryByActivityId()
        return (
            <Dialog open={this.props.open} maxWidth="xs" scroll='body' {...(this.state.width > 600 ? {fullWidth: true} : {fullScreen: true})}>
                <DialogTitle>                
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.currentUnitActivity ? "Activiteit bewerken" : "Nieuwe activiteit"}</Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>                
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4} style={{marginTop: '1px'}}>
                        <Grid item xs={5} >
                            <TextField
                                label="Kamer"
                                value={this.currentUnitGuid()}
                                select
                                fullWidth
                                onChange={(e) => this.handleChangeUnit(e)}
                            >
                                {this.props.units.map((unit) => {
                                    return (
                                        <MenuItem key={unit.Id} value={unit.Id}>{unit.Name}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                label="Niveau"
                                value={this.currentLevelGuid()}
                                select
                                fullWidth
                                onChange={(e) => this.handleChangeLevel(e)}
                            >
                                {this.props.levels.map((level) => {
                                    return (
                                        <MenuItem key={level.Id} value={level.Id}><img style={{background: level.Color, width:20, height:15, marginRight: 10, marginLeft: 5, border: 'none'}} />{level.Name}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <WeekDay>
                                    <Typography>Ma</Typography>
                                    <Checkbox value="Ma" onChange={this.handleWeekDay} checked={(1 & this.currentWeekDay()) !== 0} color="primary" />
                                </WeekDay>
                                <WeekDay>
                                    <Typography>Di</Typography>
                                    <Checkbox value="Di" onChange={this.handleWeekDay} checked={(2 & this.currentWeekDay()) !== 0} color="primary" />
                                </WeekDay>
                                <WeekDay>
                                    <Typography>Wo</Typography>
                                    <Checkbox value="Wo" onChange={this.handleWeekDay} checked={(4 & this.currentWeekDay()) !== 0} color="primary" />
                                </WeekDay>
                                <WeekDay>
                                    <Typography>Do</Typography>
                                    <Checkbox value="Do" onChange={this.handleWeekDay} checked={(8 & this.currentWeekDay()) !== 0} color="primary" />
                                </WeekDay>
                                <WeekDay>
                                    <Typography>Vr</Typography>
                                    <Checkbox value="Vr" onChange={this.handleWeekDay} checked={(16 & this.currentWeekDay()) !== 0} color="primary" />
                                </WeekDay>
                                <WeekDay>
                                    <Typography>Za</Typography>
                                    <Checkbox value="Za" onChange={this.handleWeekDay} checked={(32 & this.currentWeekDay()) !== 0} color="primary" />
                                </WeekDay>
                                <WeekDay>
                                    <Typography>Zo</Typography>
                                    <Checkbox value="Zo" onChange={this.handleWeekDay} checked={(64 & this.currentWeekDay()) !== 0} color="primary" />
                                </WeekDay>

                            </div>
                        </Grid>

                        {/* ---------- row ---------- */}
                        <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        label="Starttijd"
        value={moment(this.currentStartTime(), "HH:mm").toDate()}
        onChange={this.handleChangeStartTime}
        ampm={false}
        format="HH:mm"
        mask="__:__"
                                                        cancelText="Terug"
                                                        clearText="Wissen"
                                                        clearable
                                                        okText="Opslaan" 
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
                      
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5}>
                            <TextField type="number" value={this.currentDuration()} onChange={this.handleChangeDuration} label="Duur (minuten)" inputProps={{ min: 1, max: 180 }} fullWidth></TextField>
                        </Grid>

                        {/* ---------- row ---------- */}

                        <Grid item xs={12}>
                            <Typography id="duur-minuten" variant='caption' style={{ textAlign: 'right', display: 'block' }}>Duur (minuten)</Typography>
                            <Slider style={{ marginTop: 0 }}
                                key="slider"
                                aria-labelledby="duur-minuten"
                                value={this.currentDuration()}
                                onChange={(e, v) => this.handleChangeDurationSlider(e, v)}
                                valueLabelDisplay="on"
                                step={1}
                                marks={[{ value: 30, label: '30m' }, { value: 60, label: '1h' }, { value: 90, label: '1h 30m' }, { value: 120, label: '2h' }, { value: 150, label: '2h30m' }, { value: 180, label: '3h' }]}
                                min={1}
                                max={180}
                            />
                        </Grid>
                        {/* ---------- row ---------- */}
                        <Grid item xs={12}>
                        <TextField
                                label="Categorie"
                                value={this.currentCategoryGuid()}
                                select
                                fullWidth
                                onChange={(e) => this.handleChangeCategory(e)}
                            >
                                {this.props.categories.map((category) => {
                                    return (
                                        <MenuItem key={category.Id} value={category.Id}>{category.Name}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                        {/* ---------- row ---------- */}
                        <Grid item xs={12}>
                        <TextField
                                label="Activiteit"
                                value={this.currentActivityGuid()}
                                select
                                fullWidth
                                onChange={(e) => this.handleChangeActivity(e)}
                            >
                                {this.getActivitiesByCategory().map((activity) => {
                                    return (
                                        <MenuItem key={activity.Id} value={activity.Id}>{activity.Name}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>
                        {/* ---------- row ---------- */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="Description"
                                required
                                label="Toelichting"
                                multiline
                                defaultValue={this.currentDescription()}
                                onChange={this.handleChangeDescription}
                            />
                        </Grid>
                    </Grid>

                 </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleOkClick()} color="primary">
                        OPSLAAN
          </Button>
                    <Button onClick={() => this.onCancelClick()} color="primary">
                        TERUG
          </Button>
                </DialogActions> 
             </Dialog>
        );
    }
}

//export default withStyles(styles, { withTheme: true })(UnitActivityDialog);
export default UnitActivityDialog;