import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { MenuItem, Select, Checkbox, ListItemText } from '@mui/material';
import * as Api from '../services/api/common-api'
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import { FormRow } from './FormRow';
import DidoHeart from './DidoHeart';


class ReportFilterDialog extends React.Component {

    weekDayNames = ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"];

    constructor(props) {
        super(props);
        this.state = {
            //weekdays : this.weekDayNames,
            weekdays: [0, 1, 2, 3, 4, 5, 6],
            unitName: '',
            units: [],
            loading: false,
        };
    }

    onChangeWeekday = async (event) => {
        const value = event.target.value;
        console.log(value);
        await this.setState({ weekdays: typeof value === 'string' ? value.split(',') : value })
    }

    // onChangeUnit = async (event) => {
    // }

    componentDidMount() {
        //console.log(this.state.weekdays)
        this.updateUnits()
    }

    updateUnits = async () => {
        await this.setState({ loading: true })
        Api.getUnitsByTeam(this.props.currentTeam)
            .then(res => {
                this.setState({ units: res.data });
                //console.log(res.data)
                if (res.data.length > 0) {
                    //console.log(res.data[0].Name)
                    this.setState({ unitName: res.data[0].Name })
                }
            })
            .catch(err => Api.logger.errorWithSnackbar(`Error on getUnitsByTeam`, this, err))
            .finally(() => this.setState({ loading: false }))
    }

    onOkClick = async () => {
        console.log(this.state.weekdays)
        console.log(this.state.unitName)
        if (this.state.weekdays.length > 0 && this.state.unitName.length > 0) {
            //  try {

            await this.setState({ loading: true })
            Api.downloadPdfReport(this.state.weekdays, this.state.unitName, this.props.currentTeam)
                .then(async (res) => {
                    this._downloadFileInBrowser(res.data)
                })
                .finally(() => {
                    this.setState({ loading: false, weekdays: [0, 1, 2, 3, 4, 5, 6], unitName: '', })
                    this.props.dialogAction();
                });


        }
    }

    onCancelClick = () => {
        this.props.dialogAction();
        this.setState({ weekdays: [0, 1, 2, 3, 4, 5, 6], unitName: '', })
    }

    _downloadFileInBrowser = (binary) => {
        var binaryData = [];
        binaryData.push(binary);

        const url = window.URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', 'individual_clientagenda.pdf');

        document.body.appendChild(link);
        link.click();
    }

    render() {
        //console.log(this.props.open);
        return (
            <>
                <DidoHeart loading={this.state.loading} />
                <Dialog open={this.props.open} maxWidth='sm' fullWidth>
                    <DialogTitle>
                        <Grid container>
                            <Grid item xs={10} style={{ alignSelf: 'center' }}>Select Unit</Grid>
                            <Grid item xs={2} style={{ textAlign: 'right' }}>
                                <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <FormRow>
                                <Select
                                    multiple
                                    fullWidth
                                    value={this.state.weekdays}
                                    onChange={this.onChangeWeekday}
                                    renderValue={(selected) => selected.length === 7 ? "Alle" : selected.map(idx => this.weekDayNames[idx]).join(', ')}
                                >
                                    {this.weekDayNames.map((dayName, index) => (
                                        // <MenuItem key={index} value={dayName}>
                                        //     <Checkbox checked={this.state.weekdays.includes(dayName)} />
                                        //     <ListItemText primary={dayName} />
                                        // </MenuItem>
                                        <MenuItem key={index} value={index}>
                                            <Checkbox checked={this.state.weekdays.includes(index)} />
                                            <ListItemText primary={dayName} />
                                        </MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormRow>
                            <FormRow>
                                <Select fullWidth
                                    value={this.state.unitName}
                                    onChange={(e) => this.setState({ unitName: e.target.value })}
                                >

                                    {this.state.units.map((unit) =>
                                        <MenuItem key={unit.Id} value={unit.Name}>{unit.Name}</MenuItem>
                                    )
                                    }
                                </Select>
                            </FormRow>

                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onOkClick()} color="primary">
                            BOUW RAPPORT OP
                        </Button>
                        <Button onClick={() => this.onCancelClick()} color="primary">
                            TERUG
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}


export default connect((state) => ({ currentTeam: state.didoReducer.currentTeam }))(ReportFilterDialog);