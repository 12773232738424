import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { FormRow } from '../../components/FormRow';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

class SubscriptionDialog extends React.Component {
    constructor(props) {
        super(props);
        this.flushState();
    }

    flushState = () => {
        const initialState = { 
            name: '',
            maxTeams: 0,
            maxUsers: 0,
            monthlyPrice: 0,
            yearlyPrice: 0        
        };
        if (this.state) this.setState(initialState)
        else this.state = initialState;
    }

    onNameChange = (evt) => this.setState({ name: evt.currentTarget.value })
    currentName = () => this.state.name ? this.state.name : this.props.currentSubscription ? this.props.currentSubscription.Name : ""

    onMaxTeamsChange = (evt) => this.setState({ maxTeams: evt.currentTarget.value })
    currentMaxTeams = () => this.state.maxTeams ? this.state.maxTeams : this.props.currentSubscription ? this.props.currentSubscription.MaxTeams : 0

    onMaxUsersChange = (evt) => this.setState({ maxUsers: evt.currentTarget.value })
    currentMaxUsers = () => this.state.maxUsers ? this.state.maxUsers : this.props.currentSubscription ? this.props.currentSubscription.MaxUsers : 0

    onMonthlyPriceChange = (evt) => this.setState({ monthlyPrice: evt.currentTarget.value })
    currentMonthlyPrice = () => this.state.monthlyPrice ? this.state.monthlyPrice : this.props.currentSubscription ? this.props.currentSubscription.MonthlyPrice : 0.0

    onYearlyPriceChange = (evt) => this.setState({ yearlyPrice: evt.currentTarget.value })      
    currentYearlyPrice = () => this.state.yearlyPrice ? this.state.yearlyPrice : this.props.currentSubscription ? this.props.currentSubscription.YearlyPrice : 0.0

    onOkClick = async () => {
        
        await this.setState({
                name: this.currentName(),
                maxTeams: this.currentMaxTeams(),
                maxUsers: this.currentMaxUsers(),
                monthlyPrice: this.currentMonthlyPrice(),
                yearlyPrice: this.currentYearlyPrice()
            })

        this.props.dialogAction(false, this.state);
        this.flushState();
    }

    onCancelClick = async () => {
        this.props.dialogAction(true)
        this.flushState();
    }

    render() {
        return (
            <Dialog aria-labelledby="simple-dialog-title" open={this.props.open}>
                <DialogTitle id="simple-dialog-title">
                <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.currentSubscription ? "Edit subscription" : "New subscription"}</Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormRow>
                        <TextField
                            name="Name"
                            required
                            label="Naam"
                            defaultValue={this.currentName()}
                            onChange={this.onNameChange}
                        /></FormRow>
                        <FormRow>
                        <TextField
                            name="MaxTeams"
                            required
                            label="Max teams"
                            defaultValue={this.currentMaxTeams()}
                            onChange={this.onMaxTeamsChange}
                        /></FormRow>
                        <FormRow>
                        <TextField
                            name="MaxUsers"
                            required
                            label="Max users"
                            defaultValue={this.currentMaxUsers()}
                            onChange={this.onMaxUsersChange}
                        />
                        </FormRow>
                        <FormRow>
                        <TextField
                            name="MonthlyPrice"
                            required
                            label="Monthly price"
                            defaultValue={this.currentMonthlyPrice()}
                            onChange={this.onMonthlyPriceChange}
                        />
                        </FormRow>
                        <FormRow>
                        <TextField
                            name="YearlyPrice"
                            required
                            label="Yearly price"
                            defaultValue={this.currentYearlyPrice()}
                            onChange={this.onYearlyPriceChange}
                        />
                        </FormRow>
                    </div>
                   
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.onOkClick()} color="primary">
                        OPSLAAN
          </Button>
                    <Button onClick={() => this.onCancelClick()} color="primary">
                        TERUG
          </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default SubscriptionDialog;