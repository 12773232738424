export const IMPERSONALIZATION = "Impersonalization";
export const MANAGE_USERS = "ManageUsers";
export const MANAGE_PROFILES = "ManageProfiles";
export const MANAGE_SUBSCRIPTIONS = "ManageSubscriptions";
export const MANAGE_ACTIVITIES = "ManageActivities";
export const MANAGE_ORG_STRUCTURE = "ManageOrgStructure"
export const MANAGE_ZORG_PROFILES = "ManageZorgProfiles"
export const MANAGE_LEVELS= "ManageLevels"
export const MANAGE_COSTS = "ManageCosts"
export const MANAGE_AGENDA = "ManageAgenda"
export const MANAGE_UNITFORMS = "ManageUnitForms"
export const MANAGE_KANBAN = "ManageKanban"
export const MANAGE_KANBAN_THEMES = "ManageKanbanThemes";
export const MANAGE_INFO_PAGES = "ManageInfoPages";
export const SELECT_TEAM = "SelectTeam"
export const ORG_ADMIN = "OrgAdmin";
export const ASSIGNED_TO_TEAM = "AssignedToTeam";
export const REPORT_GROUP = "Analyse zorgprofiel groep";
export const REPORT_UNIT = "Analyse zorgprofiel cliënt";
export const REPORT_CATEGORY = "Analyse per categorie";
export const REPORT_DAY = "Dagstructuur groep";
export const REPORT_DAY_TABLE = "Dagstructuur groep uitgedrukt in minuten per tijdvak";
export const REPORT_LEVEL = "Teamsamenstelling per dag";
export const REPORT_UNIT_WEEK = "Individuele cliëntagenda";
export const REPORT_UNIT_WEEK_FREEMIUM = "Individuele cliëntagenda (Freemium)";
export const REPORT_CATEGORIES = "Activiteiten per niveau";
export const REPORT_KANBAN_TEAM = "Teamtaken";
export const REPORT_KANBAN_ORG = "Organisatietaken";
export const REPORT_ZZP45 = "Indicatie spiegel";
export const REPORT_MANAGMENT_DASHBOARD = "Organisatie dashboard";
export const REPORT_DASHBOARD = "Dashboard";
export const REPORT_DAY_STRUCTURE = "Inzicht dagstructuur";
