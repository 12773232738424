import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import * as Api from '../../services/api/common-api'
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

class ProfileDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            //roles: []
        };
    }

    nameChange = (evt) => this.setState({name:evt.currentTarget.value})    
    roleCheckboxClick = (evt) => {        
        let roles = this.props.dialogProfileRoles;
        if (evt.target.checked) 
            roles.push(evt.target.name);        
        else {
            const index = roles.indexOf(evt.target.name);
            if (index !== -1) 
                roles.splice(index, 1);            
        }

        this.forceUpdate();    
    }

    OnOkClick = async () => {
        if (this.props.dialogProfileRoles.length ===0)
        Api.logger.warning(`Error in getAllDomains`,this.state)
       
        this.props.dialogAction(false, this.state);
        this.setState({ name: null })
    }

    onCancelClick = () => this.props.dialogAction(true)
    
    render() {
        return (
            <Dialog aria-labelledby="simple-dialog-title" open={this.props.open}>
                <DialogTitle id="simple-dialog-title">
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.dialogProfile ? "Edit profile" : "New profile"}</Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                            name="Name"
                            required
                            label="Naam"
                            defaultValue={(this.props.dialogProfile) ? this.props.dialogProfile.Name : ""}
                        onChange={this.nameChange}
                        />                        
                    </div>
                    <FormGroup>
                        {
                            this.props.allRoles.map((role) => {
                                return (
                                    <FormControlLabel key={role.name}
                                        control={<Checkbox onClick={this.roleCheckboxClick}
                                            name={role.name}
                                            checked={this.props.dialogProfileRoles.indexOf(role.name) !== -1}
                                        />}
                                        label={role.name}
                                    />);
                            })
                        }
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.OnOkClick()} color="primary">
                        OPSLAAN
          </Button>
                    <Button onClick={this.onCancelClick} color="primary">
                        TERUG
          </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ProfileDialog;