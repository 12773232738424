import React from 'react';
import DidoDrawer from '../components/Drawer'
import Container from '@mui/material/Container';
import { withSnackbar } from 'notistack';
import { BasePage } from './BasePage';
import DidoHeart from '../components/DidoHeart';
import { connect } from 'react-redux';
import * as Api from '../services/api/common-api'
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import { SNACK_SUCCESS } from './../constants/common';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

const totalRowId = 'total_row'

const sorter = (a, b, row1, row2) => {    
    const order = row1.api.state.sorting.sortModel[0].sort;    

    if (order === 'asc') {
        if (row1.id === totalRowId) return 1;
    }
    if (order === 'desc') {
        if (row1.id === totalRowId) return -1;
    }
    
    if (typeof(a) === 'string')
        return a.localeCompare(b)
    
    return a - b;
}

const columns = [
    { field: 'level', headerName: 'Niveau / Dag', flex: 0.3, sortComparator: sorter },
    { field: 'mo', headerName: 'Maandag', flex: 0.1, editable: true, type: 'number', align: 'center', headerAlign: 'center', sortComparator: sorter },
    { field: 'tu', headerName: 'Dinsdag', flex: 0.1, editable: true, type: 'number', align: 'center', headerAlign: 'center', sortComparator: sorter },
    { field: 'we', headerName: 'Woensdag', flex: 0.1, editable: true, type: 'number', align: 'center', headerAlign: 'center', sortComparator: sorter },
    { field: 'th', headerName: 'Donderdag', flex: 0.1, editable: true, type: 'number', align: 'center', headerAlign: 'center', sortComparator: sorter },
    { field: 'fr', headerName: 'Vrijdag', flex: 0.1, editable: true, type: 'number', align: 'center', headerAlign: 'center', sortComparator: sorter },
    { field: 'sa', headerName: 'Zaterdag', flex: 0.1, editable: true, type: 'number', align: 'center', headerAlign: 'center', sortComparator: sorter },
    { field: 'su', headerName: 'Zondag', flex: 0.1, editable: true, type: 'number', align: 'center', headerAlign: 'center', sortComparator: sorter },
    { field: 'total', headerName: 'Totale inzet', flex: 0.2, editable: false, type: 'number', align: 'center', headerAlign: 'center', 
    renderCell: (params) => (
        <strong>{params.value}</strong>
    ), sortComparator: sorter }
];


class AvailableTimePage extends BasePage {

    constructor(props) {
        super(props);
        this.state = { timeTable: [], tableChanged: false };
    }

    componentDidUpdate(prevProps){      
        if (prevProps.currentTeam !== this.props.currentTeam) { 
            this.loadTimeTable();
        }
    }

    totalColumn = (row) => row.mo + row.tu + row.we + row.th + row.fr + row.sa + row.su

    getRowClassName = (params) => {
        return params.row.id === totalRowId ? "bold" : ""
    }

    calculateTotalRow = () => {
        var totalRow = {id: totalRowId, level: "Totale inzet", mo: 0, tu: 0, we: 0, th: 0, fr: 0, sa: 0, su: 0, total: 0};

        this.state.timeTable.forEach(row => {
            if (row.id !== totalRowId) {
                totalRow.mo += row.mo
                totalRow.tu += row.tu
                totalRow.we += row.we
                totalRow.th += row.th
                totalRow.fr += row.fr
                totalRow.sa += row.sa
                totalRow.su += row.su
                totalRow.total += row.total
            }
        });

        var isTotalRowFound = false;
        var rows = this.state.timeTable.map((row) => {
            if (row.id === totalRow.id) {
                isTotalRowFound = true;
                return totalRow;
            }
            return row;
        });

        if (!isTotalRowFound) {
            rows.push(totalRow);
        }

        this.setState({timeTable: rows})
    }

    loadTimeTable = () => {
        this.setState({ loading: true });
        Promise.all([Api.getLevelsForAvailableTimeByDomain(this.props.currentUser.CurrentDomainGuid), Api.getAvailableTimeForTeam(this.props.currentTeam)])
            .then(async (res) => {
                res.forEach(async (result) => {
                    if (result.config.url.toLowerCase().indexOf('level') !== -1) await this.setState({ levels: result.data });
                    else if (result.config.url.toLowerCase().indexOf('time') !== -1) {
                        console.log(result.data.Schedule)
                        if (Array.isArray(result.data.Schedule) && result.data.Schedule.length > 0) {
                            const mapped = result.data.Schedule.map((row) => ({
                                id: row.Id,
                                level: row.Level.Name,
                                levelId: row.Level.Id,
                                mo: row.Mo,
                                tu: row.Tu,
                                we: row.We,
                                th: row.Th,
                                fr: row.Fr,
                                sa: row.Sa,
                                su: row.Su,
                                total: row.Mo + row.Tu + row.We + row.Th + row.Fr + row.Sa + row.Su
                            }))
                            await this.setState({ timeTable: mapped, currentItem: { Id: result.data.Id } });
                            this.calculateTotalRow();
                        }
                        else {
                            const zeroTimeTable = this.state.levels.map(l => ({ id: crypto.randomUUID(), level: l.Name, levelId: l.Id, mo: 0, tu: 0, we: 0, th: 0, fr: 0, sa: 0, su: 0, total: 0 }));
                            await this.setState({ timeTable: zeroTimeTable });
                            this.calculateTotalRow();
                        }
                    }
                });
                await this.setState({ loading: false })
            })
            .catch(err => Api.logger.errorWithSnackbar(`Error on getting available time`, this, err))
            .finally(() => this.setState({ loading: false }))
    }

    componentDidMount() {
        this.loadTimeTable();
    }


    processRowUpdate = async (newRow) => {
        const updatedRow = { ...newRow, isNew: false, total: this.totalColumn(newRow) };
        var rows = this.state.timeTable.map((row) => (row.id === newRow.id ? updatedRow : row));

        await this.setState({timeTable: rows, tableChanged: true});
        this.calculateTotalRow();
        
        return updatedRow;
      };

    onCleanClick = () => {
        const zero = this.state.timeTable.map((row) => {
            row.mo = row.tu = row.we = row.th = row.fr = row.sa = row.su = row.total = 0
            return row;
        });
        this.setState({ timeTable: zero, dialogOpen: false, tableChanged: true });
    }

    onSaveClick = () => {

        var schedule = this.state.timeTable.map((row) =>
        ({
            Id: row.id,
            Level: { Id: row.levelId },
            Mo: row.mo,
            Tu: row.tu,
            We: row.we,
            Th: row.th,
            Fr: row.fr,
            Sa: row.sa,
            Su: row.su
        }));
        schedule.pop();

        var params = {
            Team : {
                Id: this.props.currentTeam
            },
            Schedule : schedule
        };

        let promise; 

        if (!this.state.currentItem) { // dialog for new subscription            
            promise = Api.createAvailableTimeTable(params);
        }
        else {
            params.id = this.state.currentItem.Id;
            promise = Api.updateAvailableTimeTable(params);
        }

        promise
        .then(() => {
            this.props.enqueueSnackbar("Alle wijzigingen zijn opgeslagen", SNACK_SUCCESS);
            this.setState({tableChanged: false});
        })
        .catch(err => Api.logger.errorWithSnackbar(`Error on updating Available time table `, this, err));
    }

    render() {
        return (
            <div>
                <DidoDrawer pageTitle="Beschikbare inzet"
                    controlsFragment={
                        <>
                            <Fab
                                disabled={!this.state.tableChanged}
                                style={{ backgroundColor: this.state.tableChanged ? '#4AB897' : '#CCC', zIndex: 1200, color: 'white', right: '10vw', top: '3vh', position: 'fixed' }}>
                                <SaveIcon onClick={this.onSaveClick} />
                            </Fab>
                            <Fab
                                style={{ backgroundColor: '#910F2E', zIndex: 1200, color: 'white', right: '14vw', top: '3vh', position: 'fixed' }}>
                                <CleaningServicesIcon onClick={() => this.setState({dialogOpen: true})} />
                            </Fab>
                        </>
                    }
                />
                <DidoHeart loading={this.state.loading} />
                <Container maxWidth="md" style={{ marginTop: '75px', marginBottom: '75px' }}>
                    <Paper>
                        <DataGrid
                            rows={this.state.timeTable}
                            columns={columns}
                            processRowUpdate={this.processRowUpdate}
                            showColumnVerticalBorder
                            showCellVerticalBorder
                            disableRowSelectionOnClick
                            hideFooter
                            
                            getRowClassName={this.getRowClassName}
                            sx={{
                                '.MuiDataGrid-columnHeaderTitle': { 
                                   fontWeight: 'bold !important',
                                   overflow: 'visible !important'
                                },
                                '.bold': {
                                    fontWeight: 'bold !important',
                                }
                              }}
                        />
                    </Paper>
                </Container>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.setState({ dialogOpen: false })}
                >
                    <DialogTitle>Tabel opschonen?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Wil je alle inzetregels wissen?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onCleanClick}>Ja</Button>
                        <Button onClick={() => this.setState({ dialogOpen: false })} autoFocus>
                        Nee
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ currentTeam: state.didoReducer.currentTeam, currentUser: state.didoReducer.currentUser })

export default connect(mapStateToProps)(withSnackbar(AvailableTimePage));