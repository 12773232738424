import React from 'react';

class SessionExpiredPage extends React.Component {
  render() {

    return (
    <div style={{textAlign: 'center', marginTop: '30vh'}}>
<h2>Uw sessie is verlopen. Gelieve opnieuw in te <a href="/login">loggen</a></h2>
    </div> 
    );
  }
}

export default SessionExpiredPage;