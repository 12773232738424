/* eslint-disable default-case */
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import DidoHeart from '../components/DidoHeart';
import { Grid } from '@mui/material';
import TodoSidebar from '../components/TodoSidebar';

class ReportPage extends React.Component {

    //reportId = '5f8cc0ea-7d91-4d17-bafe-2e1ffc09fb2a';
    groupId = '8c994421-9e00-419b-b1f3-3f045403d0f0';
    debugCurrentTeam = this.props.currentTeam; //'CECB8416-5FC7-4E0D-7167-08D9B9612354'; //
    weekDayNames = ["1. Maandag", "2. Dinsdag", "3. Woensdag", "4. Donderdag", "5. Vrijdag", "6. Zaterdag", "7. Zondag"];


    constructor(props) {

        super(props);
        this.state = {
            width: window.innerWidth,
            embedToken: null,
            lastTeam: null,
            pdfSelectedDay: [],
            pdfSelectedRoom: '',
            isFileDownloading: false,
            kanbanDialogOpen: false
        };
        this.report = null
    }

    isReportWithTabs = () => ["/level/day", "/management_dashboard", "management_daystructure"].some(r => window.location.href.includes(r))
    isDownloadableAsPdf = () => ["unit_week"].some(r => window.location.href.includes(r))
    isShowKanbanBoard = () => !["/dashboard", "/management_dashboard", "management_daystructure"].some(r => window.location.href.includes(r))
    needUpdateContextMenu = () => ["management_daystructure"].some(r => window.location.href.includes(r))
    isMobileLayout = () => this.state.width < 700


    rebuildPowerBiReport(isNew) {
        this.setState({ isFileDownloading: false });
        Api.getPowerBiEmbedToken(this.groupId, this.props.reportId).then(res => {
            if (!window['powerbi-client']) {
                console.log("can't find powerbi")
                window.location.reload()
            }
            var models = window['powerbi-client'].models;
            const filterTeam = {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: "dictionary Team",
                    column: "Id"
                },
                operator: "In",
                values: [this.props.currentTeam]
            };

            const filterOrg = {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: "dictionary Organization",
                    column: "Id"
                },
                operator: "In",
                values: [this.props.currentOrg]
            };

            var embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${this.props.reportId}&groupId=${this.groupId}`;
            this.setState({ embedToken: res.data });


            var config = {
                type: 'report',
                tokenType: models.TokenType.Embed,
                accessToken: res.data,
                embedUrl: embedUrl,
                id: this.props.reportId,
                permissions: models.Permissions.All,
                //pageView: "fitToWidth",
                pageView: "fitToPage",
                viewMode: models.ViewMode.View,
                filters: [
                    ["/kanbanOrg", "/zzp45", "/dynamicFormOrg", "/management_dashboard", "/management_daystructure"]
                        .some(r => window.location.href.includes(r)) ?
                        filterOrg : filterTeam
                ],
                settings: {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: this.isMobileLayout() ? false : this.isReportWithTabs(),
                    background: models.BackgroundType.Transparent,
                    layoutType: this.isMobileLayout() ? models.LayoutType.MobilePortrait : models.LayoutType.Master,
                    customLayout: {
                        displayOption: models.DisplayOption.FitToPage

                    },
                    localeSettings: {
                        language: "nl",
                        formatLocale: "nl"
                    }
                }
            };

            var reportContainer = document.getElementById('embedContainer');
            let report = null;
            if (isNew) {
                report = window.powerbi.embedNew(reportContainer, config);
            }
            else {
                report = window.powerbi.embed(reportContainer, config);
            }

            report.on('dataSelected', this.onPowerBiComboSelected);
            if (this.needUpdateContextMenu()) {
                report.on('loaded', this.onPowerBiReportLoaded);
            }
            this.setState({ report: report, loading: false })
        })
    }

    componentWillMount() {
        var element = document.createElement("script");
        element.src = "/js/jquery-3.4.1.min.js";
        document.body.appendChild(element);

        element = document.createElement("script");
        element.src = "/js/powerbi.js";
        document.body.appendChild(element);

        this.setState({ loading: true })

        window.addEventListener('resize', this.onWindowSizeChange);
        this.rebuildPowerBiReport(false);
    }

    componentDidUpdate() {
        if (this.state.lastTeam != null && this.props.currentTeam !== this.state.lastTeam) {
            this.rebuildPowerBiReport(true)
            this.setState({ lastTeam: this.props.currentTeam })
        }
        else if (this.state.lastTeam == null)
            this.setState({ lastTeam: this.props.currentTeam })
    }

    onWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    onStartDownloadReport = async () => {
        try {
            await this.setState({ isFileDownloading: true })

            Api.downloadPdfReport(this.state.pdfSelectedDay, this.state.pdfSelectedRoom, this.props.currentTeam)
                .then(async (res) => {
                    this._downloadFileInBrowser(res.data)
                    await this.setState({ isFileDownloading: false })
                });
        }
        catch {
            await this.setState({ isFileDownloading: false })
        }
    }

    mapWeekdays = (selectedWeekdayNames, operator) => {

        var numericWeekdays = operator.toLowerCase() === "in" ? [] : [0, 1, 2, 3, 4, 5, 6];
        for (var i = 0; i < this.weekDayNames.length; i++) {
            const currentDay = this.weekDayNames[i].toLowerCase();

            const includeCondition = operator.toLowerCase() === "in" && selectedWeekdayNames.filter(sel => currentDay === sel.toLowerCase()).length > 0;
            const excludeCondition = operator.toLowerCase() === "notin" && selectedWeekdayNames.filter(sel => currentDay === sel.toLowerCase()).length > 0;

            if (includeCondition) {
                numericWeekdays.push(i)
            }
            else if (excludeCondition) {
                const index = numericWeekdays.indexOf(i);
                if (index > -1) {
                    numericWeekdays.splice(index, 1);
                }
            }
        }

        return numericWeekdays;
    }

    onPowerBiComboSelected = async () => {
        const pages = await this.state.report.getPages();

        let pageWithSlicer = pages.filter(page => page.isActive)[0];
        const visuals = await pageWithSlicer.getVisuals();


        let slicerWeekday = visuals.filter(visual => visual.type === "slicer" && visual.name === "d14ae9de80d4b207050e")[0];

        let slicerRoom = visuals.filter(visual => visual.type === "slicer" && visual.name === "c7294094577620ab8084")[0];

        const stateWeekday = await slicerWeekday.getSlicerState();
        //console.log(stateWeekday)
        let weekdays = [];
        if (stateWeekday.filters.length > 0) {
            weekdays = this.mapWeekdays(stateWeekday.filters[0].values, stateWeekday.filters[0].operator)
            this.setState({ pdfSelectedDay: weekdays });
        }
        else {
            this.setState({ pdfSelectedDay: [0, 1, 2, 3, 4, 5, 6] });
        }

        const stateRoom = await slicerRoom.getSlicerState();
        if (stateRoom.filters.length > 0) {
            this.setState({ pdfSelectedRoom: stateRoom.filters[0].values[0] });
        }
    }

    onPowerBiReportLoaded = async () => {
        var models = window['powerbi-client'].models;
        const newSettings = {
            commands: [
                {
                    includeExclude: {
                        displayOption: models.CommandDisplayOption.Hidden,
                    },
                    seeData: {
                        displayOption: models.CommandDisplayOption.Hidden,
                    }
                }
            ]
        };

        await this.state.report.updateSettings(newSettings);
    }

    _downloadFileInBrowser = (binary) => {
        var binaryData = [];
        binaryData.push(binary);

        const url = window.URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', 'individual_clientagenda.pdf');

        document.body.appendChild(link);
        link.click();
    }

    onShowKanbanDialog = () => this.setState({ kanbanDialogOpen: true });

    render() {
        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }}
                    pageTitle={this.props.title}
                    noFab
                    onTeamChange={() => this.rebuildPowerBiReport()}
                    onStartDownloadReport={this.isDownloadableAsPdf() ? this.onStartDownloadReport : null}
                    showDownloadReportButton={this.state.pdfSelectedRoom !== ''}
                    isFileDownloading={this.state.isFileDownloading}
                    onAddKanbanTask={this.isShowKanbanBoard() ? this.onShowKanbanDialog : undefined}
                />
                {
                    this.state.embedToken == null ?
                        <DidoHeart loading={this.state.loading} />
                        :
                        <Grid container>
                            <Grid item xs={this.isShowKanbanBoard() ? this.isMobileLayout() ? 11.5 : 9 : 12}>
                                <div style={{ marginTop: '75px' }}>
                                    <link rel="stylesheet" type="text/css" href='/forReports.css' />
                                    <div id="embedContainer" style={{ height: this.isMobileLayout() ? '80vh' : '90vh', width: '100%', padding: '0px', clear: 'both', borderWidth: 0 }} ></div>
                                </div>
                            </Grid>
                            {this.isShowKanbanBoard() ?
                                <Grid item xs={this.isMobileLayout() ? 12 : 3}>
                                    <TodoSidebar
                                        lastTeam={this.state.lastTeam}
                                        callOpenDialog={this.state.kanbanDialogOpen}
                                        hideKanbanDialog={() => this.setState({ kanbanDialogOpen: false })}
                                        isMobileLayout = {this.isMobileLayout()}
                                    />
                                </Grid>
                                : ""}
                        </Grid>

                }
            </div>);
    }


}

const mapStateToProps = (state) => ({
    currentUser: state.didoReducer.currentUser,
    currentTeam: state.didoReducer.currentTeam,
    currentOrg: state.didoReducer.currentOrg,
});

export default withSnackbar(connect(mapStateToProps)(ReportPage));