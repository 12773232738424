import { Grid, Card, CardContent } from '@mui/material';
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import SubscriptionDialog from './Dialog/SubscriptionDialog'
import Container from '@mui/material/Container';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { withSnackbar } from 'notistack';
import { SNACK_SUCCESS } from './../constants/common';
import { Heart } from 'react-spinners-css';
import { getBootstrapWidthXs } from '../utils';


class SubscriptionsPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { subscriptions: [], dialogOpen: false, currentSubscription:{}, width: window.innerWidth};
    }

    componentWillMount() {       
        window.addEventListener('resize', this.onWindowSizeChange);
    }
    componentDidMount() {
        this.updateSubscriptionsList();
    }

    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };

    updateSubscriptionsList = () => {
        this.setState({loading: true})
        Api.getSubscriptions().then(res => this.setState({ subscriptions: res.data, loading:false }))
        .catch(err => {
            this.setState({loading: false})
            Api.logger.errorWithSnackbar(`Error on get subscriptions`, this, err)
    });}

    deleteSubscription = (id) => Api.deleteSubscription(id)
    .then(() => {
        this.props.enqueueSnackbar("Succesvol verwijderd", SNACK_SUCCESS);
        this.updateSubscriptionsList()}).catch(err =>Api.logger.errorWithSnackbar(`Error on deleting ${id}`,this, err));

    render() {
        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle="Manage subscriptions"  onAddFabClick={() => this.showSubscriptionDialog()} />
                {/* <div align="right">
                    <Fab style={{ backgroundColor: '#4AB897', zIndex: 1200, color: 'white', right: '8vw', position: 'fixed' }} aria-label="add">
                        <AddIcon onClick={() => this.showSubscriptionDialog()} />
                    </Fab>
                </div> */}
                {this.state.loading ? <div align="center" style={{ marginTop: '300px' }}><Heart color="#4AB897" size={100} /></div> :
                <Container maxWidth="xl" style={{marginTop: '75px'}}>
                    <Grid container spacing={3}>
                        {                            
                            this.state.subscriptions.map((subscription) => {
                                var width = getBootstrapWidthXs(this.state.width)

                                return (                                   
                                    <Grid item key={subscription.Id} xs={width}>
                                        <Card elevation={3}>
                                            <CardHeader                                               
                                                title={subscription.Name}
                                                />
                                            <CardContent>
                                                <Typography variant="caption" component="span">Max : {subscription.MaxTeams} teams - {subscription.MaxUsers} users</Typography><br/>
                                                <Typography variant="caption" component="span">Monthly € {subscription.MonthlyPrice} / Yearly € {subscription.YearlyPrice}</Typography>
                                            </CardContent>
                                            <CardActions disableSpacing>
                                                <IconButton aria-label="Bewerk" onClick={()=>this.showSubscriptionDialog(subscription)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton aria-label="Delete" onClick={()=>this.deleteSubscription(subscription.Id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </CardActions>
                                        </Card>
                                    </Grid>                                  
                                );
                            })
                        }
                    </Grid>
                    <SubscriptionDialog 
                    open={this.state.dialogOpen} 
                    dialogAction={this.dialogAction}
                    currentSubscription={this.state.currentSubscription}
                    //ref="dialog"
                     /> 
                </Container>
    }

            </div>);
    }

    showSubscriptionDialog = (subscription) => this.setState({ currentSubscription: subscription, dialogOpen: true });

    dialogAction = (isCanceled, dialogResult) => {

        this.setState({ dialogOpen: false });
        
        if (isCanceled){
            return;
        }
        // if (isCanceled) {
        //     this.setState({ dialogOpen: false });
        //     return;
        // }
        

        let param = {
            name: dialogResult.name,
            maxTeams: dialogResult.maxTeams,
            maxUsers: dialogResult.maxUsers,
            monthlyPrice : dialogResult.monthlyPrice,
            yearlyPrice : dialogResult.yearlyPrice
        }
        
        let promise; 

        if (!this.state.currentSubscription) { // dialog for new subscription            
            promise = Api.createSubscription(param);
        }
        else {
            param.id = this.state.currentSubscription.Id;
            promise = Api.updateSubscription(param);
        }

        promise.then(() => {
            this.props.enqueueSnackbar("Wijzigingen zijn toegepast", SNACK_SUCCESS);
            this.updateSubscriptionsList()})
        .catch(err =>Api.logger.errorWithSnackbar(`Error on edit subscription`,this, err));       
    }

    
    

    
    
}

export default withSnackbar(SubscriptionsPage);