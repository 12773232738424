import './App.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import LoginPage from "./../LoginPage"
import SignUpPage from "./../SignUpPage"
import LoginCallBack from "../LoginCallback"
import PrivateRoute from '../../components/PrivateRoute';
import HomePage from './../HomePage';
import NotFoundPage from '../NotFoundPage';
import CheckInboxPage from '../CheckInboxPage';
import ProfilesPage from '../ProfilesPage';
import { withRouter } from 'react-router-dom';
import SubscriptionsPage from './../SubscriptionsPage';
import ActivitiesPage from './../ActivitiesPage';
import UsersPage from './../UserPage';
import OrgStructPage from './../OrgStruct/OrgStructPage';
import ZorgProfilePage from './../ZorgProfilePage';
import LevelPage from './../LevelPage';
import CostPage from './../CostPage';
import UnitActivityPage from './../UnitActivityPage';
import ThemePage from './../ThemePage';
import UnitFormPage from './../UnitFormPage';
import KanbanPage from './../KanbanPage';
import ReportPage from './../ReportPage';
import * as Api from '../../services/api/common-api'

import { isJwtExpired } from "../../helpers/jwt";
import SessionExpiredPage from './../SessionExpiredPage';
import FreemiumPage from '../FreemiumPage';
import InfoPage from '../InfoPage';
import InfoPageManagement from '../InfoPageManagement';
import { NEW_INFO_PAGE_URL } from '../../constants/common';
import AvailableTimePage from './../AvailableTimePage';
import  Fab from '@mui/material/Fab';
import ChatIcon from '@mui/icons-material/Chat';
import { connect } from "react-redux";
import ChatWidget from '../../components/Chat/ChatWidget';
import { Paper } from '@mui/material';
import ChatSettingsPage from '../ChatSettingsPage';
import DialogTreePage from '../DialogTreePage';
import AssistantPage from '../AssistantPage';
setInterval(function(){ 
  let exp = isJwtExpired()
  if (exp) {
    window.location = '/session_expired';
  }
}, 1000*60*5);

class AppInternal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      infoPageRoutes:[],
      infoPagesLoaded: false,
      showChatWnd: false
    };
  }

  loadRoutes = () => {
    this.setState({infoPageRoutes:[], infoPagesLoaded: false})
    Api.getInfoPageRoutes()
    .then(res => {      
      this.setState({infoPageRoutes:[...res.data, NEW_INFO_PAGE_URL], infoPagesLoaded: true})
    } )
    .catch(err => {
      this.setState({ loading: false })
      Api.logger.errorWithSnackbar(`Error get info pages list`, this, err)
    });
  }

  chatBtnClicked = () => 
    this.setState({showChatWnd: !this.state.showChatWnd}); 

  componentDidMount = () => { 
    this.loadRoutes()
  }

  render() {
    if (!this.state.infoPagesLoaded) {
      return (<></>)
    }

    const isBackgroundPage = this.props.location.pathname === '/login' || this.props.location.pathname === '/check_inbox' || this.props.location.pathname === '/session_expired' || this.props.location.pathname === '/signup';

    return (
      <div>
        <Helmet
          titleTemplate="%s - Dido Meet"
          defaultTitle="Dido Meet"
          bodyAttributes={isBackgroundPage ?
            { style: 'background-color : #C15F7E' } :
            { style: 'background-color : #EEE' }}
        >
          <meta name="description" content="Dido Meet application" />
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />
        </Helmet>
        <Switch>
          <Route path='/login' component={LoginPage} />
          <Route path='/signup' component={SignUpPage} />
          <Route path='/callback' component={LoginCallBack} />
          <Route path='/check_inbox' component={CheckInboxPage} />
          <Route path='/session_expired' component={SessionExpiredPage} />
          {                      
            this.state.infoPageRoutes.map((infoPageRoute) => (
              <Route 
              exact
              key={infoPageRoute} 
              path={infoPageRoute}
              render={props => (<InfoPage {...props} 
                allRoutes={this.state.infoPageRoutes}
                loadRoutes={this.loadRoutes}
                 />)}              
              />
            ))
          }
          <PrivateRoute path={NEW_INFO_PAGE_URL} component={InfoPage} />
          <PrivateRoute path="/users" component={UsersPage} />
          <PrivateRoute path="/profiles" component={ProfilesPage} />
          <PrivateRoute path="/subscriptions" component={SubscriptionsPage} />
          <PrivateRoute path="/activities" component={ActivitiesPage} />
          <PrivateRoute path="/orgStruct" component={OrgStructPage} />
          <PrivateRoute path="/zorgProfile" component={ZorgProfilePage} />
          <PrivateRoute path="/level" component={LevelPage} />
          <PrivateRoute path="/freemium" component={FreemiumPage} />
          <PrivateRoute path="/chat_settings" component={ChatSettingsPage} />
          <PrivateRoute path="/info_pages" component={InfoPageManagement} loadRoutes={this.loadRoutes} />
          <PrivateRoute path="/cost" component={CostPage} />
          <PrivateRoute path="/agenda" component={UnitActivityPage} />
          <PrivateRoute path="/forms" component={UnitFormPage} />
          <PrivateRoute path="/theme" component={ThemePage} />
          <PrivateRoute path="/kanban" component={KanbanPage} />
          <PrivateRoute path="/report/group" component={ReportPage} reportId="ed37f078-feab-4069-a40d-e092e030a6ca" title="Analyse zorgprofiel groep" />
          <PrivateRoute path="/report/unit" component={ReportPage} reportId="0dadf0b9-7795-4387-b0b7-3fdcff367002" title="Analyse zorgprofiel cliënt" />
          <PrivateRoute path="/report/category" component={ReportPage} reportId="9992e6cb-717e-4504-b133-2e0a722793d3" title="Analyse per categorie" />
          <PrivateRoute path="/report/day" component={ReportPage} reportId="0d77f29d-3e46-4a4f-a0c2-4a4b5e097998" title="Dagstructuur groep" />
          <PrivateRoute path="/report/day_table" component={ReportPage} reportId="7ca8689a-4791-41bf-ab67-d39e7b3cbccf" title="Dagstructuur groep uitgedrukt in minuten per tijdvak" />
          <PrivateRoute path="/report/level" component={ReportPage} reportId="ea7ed097-562e-450e-9ec4-dbdc641ebd65" title="Teamsamenstelling per dag" />
          <PrivateRoute path="/report/unit_week" component={ReportPage} reportId="9abf27e0-736f-4fcc-b695-f64cda38217e" title="Individuele cliëntagenda" />
          <PrivateRoute path="/report/categories" component={ReportPage} reportId="e57c050f-c010-48df-929e-95f54e265357" title="Activiteiten per niveau" />
          <PrivateRoute path="/report/dashboard" component={ReportPage} reportId="3d5efcf4-cadd-4c44-9a64-bc7ba916cd06" title="Inzichten bewoners" />
          <PrivateRoute path="/report/management_dashboard" component={ReportPage} reportId="8666f53b-fb36-4985-b924-2e6dad706cd8" title="Inzicht organisatie" />
          <PrivateRoute path="/report/management_daystructure" component={ReportPage} reportId="6ab53687-6222-4008-8d84-1b1fd32318bc" title="Inzicht dagstructuur" />
          <PrivateRoute path="/report/kanbanTeam" component={ReportPage} reportId="fc56843b-e59c-4cef-b4dd-c815e6924c85" title="Teamtaken" />
          <PrivateRoute path="/report/kanbanOrg" component={ReportPage} reportId="24a5ebae-3af5-472a-b06f-d645503899b2" title="Organisatietaken" />
          <PrivateRoute path="/report/zzp45" component={ReportPage} reportId="3b4c0db5-7613-4fd0-b6ee-61ee1f78bde5" title="Indicatie spiegel" />
          <PrivateRoute path="/available_time" component={AvailableTimePage} />
          <PrivateRoute path="/dialogTree" component={DialogTreePage} />
          <PrivateRoute path="/assistant" component={AssistantPage} />
          <PrivateRoute exact path='/' component={HomePage} /> 
          
          <Route path="" component={NotFoundPage} />
        </Switch> 
        {/* {          
          (!this.props.currentUser || this.props.currentUser.IsSystemAdmin || this.props.currentUser.IsOrgAdmin || isBackgroundPage || !this.props.currentUser.IsChatBotEnabled) ? '' :
            <>
              <Fab onClick={this.chatBtnClicked} variant='extended' style={{ backgroundColor: '#4eb798', border: '2px solid', borderColor: '#555', color: 'white', right: '5vw', top: 'auto', bottom: '5vh', left: 'auto', position: 'fixed', zIndex: 1100 }}>
                <ChatIcon />
                &nbsp;Dido&nbsp;
              </Fab>
              <div style={{ display: this.state.showChatWnd === true ? 'inherit' : 'none',  zIndex: 1100 }}>
                <ChatWidget
                  onCloseClick={this.chatBtnClicked}
                />
              </div>
            </>
        } */}
      </div>
      
    );
  }
}

const mapStateToProps = (state) => {
     
  return {
      currentUser: state.didoReducer.currentUser
  }
}


const App = connect(mapStateToProps)(withRouter(AppInternal));
export {App};