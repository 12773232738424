import React from 'react';
import * as Api from '../services/api/common-api'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import actions from "../actions.js";
import {withSnackbar} from 'notistack';

class LocationDropDown extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {  locations: [], currentLocationGuid: '' };
    }

    componentDidMount() {
        //this.getAllLocations();
    }

    getAllLocations = () => {
        if (this.state.locations.length === 0 && this.props.currentOrg != null) {

            Api.getLocationsWithoutChilds(this.props.currentOrg)
            .then(res => { // todo take from redux props
                if (res.data.length > 0) {
                    this.setState({ locations: res.data, currentLocationGuid: res.data.length > 0 ? res.data[0].Id : '' })
                    this.props.onLocationSelected(res.data[0].Id);
                }
            }).catch(err =>Api.logger.errorWithSnackbar(`Error in getAllLocations`, this, err));
        }
    }

    onChangeLocation = async (event) => {        
        await this.setState({currentLocationGuid : event.target.value});
        this.props.onLocationSelected(event.target.value)
    }


    render(){
        this.getAllLocations();

        let minWidth = '30vw';
        if (window.innerWidth < 900)
        minWidth = '70vw'
        
        return (
            <Select autoWidth style={{ marginLeft: 30, minWidth: minWidth}}
                        value={this.state.currentLocationGuid}
                        onChange={(e) => this.onChangeLocation(e)}>
                        {this.state.locations.map((d) => <MenuItem key={d.Id} value={d.Id}>{d.Name}</MenuItem>)}
                    </Select>
        )
    }
}


//export default LocationDropDown;

export default connect((state)=>{ 

    return {
        currentOrg: state.didoReducer.currentOrg
    }}, actions)(withSnackbar(LocationDropDown));