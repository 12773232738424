
import { faSitemap } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons'

export const OrgStructType = {
    Organization: 'Organisatie',
    Location: 'Locatie',
    Team: 'Groep',
    Unit: 'Kamer'
};

export const ItemTypeDescr = [OrgStructType.Organization, OrgStructType.Location, OrgStructType.Team, OrgStructType.Unit];

export const ItemLevelIcons = [faSitemap, faMapMarkedAlt, faUsers, faHeartbeat]
