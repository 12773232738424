import { Button, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import DidoDrawer from '../components/Drawer'
import { withSnackbar } from 'notistack';
import DidoHeart from './../components/DidoHeart';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as Api from '../services/api/common-api';
import { SNACK_SUCCESS, FREEMIUM_SIGN_UP_TEXT } from './../constants/common';

class FreemiumPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { selectedFile: null, loading: false, signUpTextSetting: '' };
    }

    componentDidMount() {
        Api.getSetting(FREEMIUM_SIGN_UP_TEXT).then(async (res) => {
            await this.setState({ signUpTextSetting: res.data });
            this.forceUpdate();
        });
    }

    onSignUpTextChange = (event) => {
        this.setState({ signUpTextSetting: event.target.value })
    }

    onFileChange = event => this.setState({ selectedFile: event.target.files[0] });

    onFileUpload = () => {
        this.setState({ loading: true })
        const formData = new FormData();

        formData.append(
            "myFile",
            this.state.selectedFile,
            this.state.selectedFile.name
        );

        Api.importFreemiumOrganizations(formData).then(() => {
            this.props.enqueueSnackbar("Organization list has been uploaded", SNACK_SUCCESS);
            this.setState({ loading: false })
        })
            .catch(err => Api.logger.errorWithSnackbar(`Error while loading organization list`, this, err));
    };

    render() {

        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle="Freemium administration" />
                <DidoHeart loading={this.state.loading} />
                <div style={{ marginTop: '30vh', textAlign: 'center' }}>
                    <div>
                        <Typography variant='body2' style={{ width: '40%', textAlign: 'justify', margin: '0 auto' }}>
                            Select comma-separated CSV file (without header) with organizations and press "Upload" button. If uploaded organization with the same name already exists, it will be skipped. You can edit and delete organizations on the "Bewoners" page.
                        </Typography>
                    </div>

                    <br />
                    <input
                        accept="text/csv"
                        id="contained-button-file"
                        type="file"
                        onChange={this.onFileChange}
                    />
                    <br />
                    <Button
                        disabled={this.state.selectedFile == null}
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        style={{ backgroundColor: this.state.selectedFile == null ? '#555' : '#4AB897', color: 'white', marginTop: 10 }}
                        onClick={this.onFileUpload}
                    >
                        Import organizations list
                    </Button>
                </div>


                <div style={{ marginTop: '15vh', textAlign: 'center' }}>
                    <div style={{ display: 'inline-flex' }}>
                        <Paper sx={{
                            width: '40vw', textAlign: 'center', padding: '2vw'
                        }}>
                            <TextField
                                fullWidth
                                multiline
                                label="SignUp Text"
                                value={this.state.signUpTextSetting}
                                //defaultValue={this.signUpTextSetting
                                onChange={(event) => this.setState({ signUpTextSetting: event.target.value })}
                                rows={4}
                            />
                            <br />
                            <Button
                                variant="contained"
                                style={{ backgroundColor: '#4AB897', color: 'white', marginTop: 10 }}
                                onClick={() => {
                                    Api.updateSetting({
                                        keyColumn: FREEMIUM_SIGN_UP_TEXT,
                                        valueColumn: this.state.signUpTextSetting
                                    })
                                        .then((res) => {
                                            if (res.data) {
                                                this.props.enqueueSnackbar("Modification has been saved", SNACK_SUCCESS);
                                            }
                                        })
                                        .catch(err => Api.logger.errorWithSnackbar(`Error on save ${FREEMIUM_SIGN_UP_TEXT}`, this, err));

                                }}>Save</Button>
                        </Paper>
                    </div>
                </div>
            </div>
        );
    }

}


export default withSnackbar(FreemiumPage);