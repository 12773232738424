import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
//import { withStyles } from "@mui/material/styles";
import UploadAvatar from '../../components/UploadAvatar';
import { BaseDialog } from './BaseDialog';
import ImageGallery from 'react-image-gallery';
import "../../css/image-gallery.css"
import {Grid } from '@mui/material';
import  IconButton  from '@mui/material/IconButton';
import  CloseIcon  from '@mui/icons-material/Close';

const styles = () => ({
    color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
      },

});


class UnitFormDialog extends BaseDialog {
    constructor(props) {
        super(props,
            {
                name: '',
                file: null
            });
    }

    onCancelClick = () => this.onDialogClose({}, true);

    onImageClick(e){
        window.open(e.target.currentSrc, "_blank")
        
    }

    render = () => {
        if (!this.props.currentItem){
            return <div></div>;
        }

        console.log(this.props.currentItem.OcrServiceFileId)

        const pagesCount = this.props.currentItem.Unit == null ? 2 : 3;
        let images = [];
        for (var i = 1; i<=pagesCount; i++)
        {
            images.push(
                {
                    original: `https://didoschrijft.azurewebsites.net/Images/${this.props.currentItem.OcrServiceFileId}_${i}.png`,
                    thumbnail: `https://didoschrijft.azurewebsites.net/Images/${this.props.currentItem.OcrServiceFileId}_${i}.png`,
                     originalWidth: '30%',
                     originalHeight: '20%',
                    thumbnailWidth : '10%',
                    thumbnailHeight : '10%'
                })
        }

            

        return (
            <Dialog open={this.props.open} {...this.props}>
                <DialogTitle>
                <Grid container>
                        <Grid item xs={11} style={{ alignSelf: 'center' }}>Formulieren {this.props.currentItem.FileName}</Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                    </DialogTitle>
                <DialogContent>
                    {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
                        
                    <ImageGallery items={images} 
                    thumbnailPosition="left"
                    showPlayButton={false}
                    onClick={(e) => this.onImageClick(e)}
                    />
                    {/* </div> */}
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={() => this.onOkClick()} color="primary">OPSLAAN</Button> */}
                    <Button onClick={() => this.onCancelClick()} color="primary">TERUG</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

//export default withStyles(styles, { withTheme: true })(ThemeDialog);
export default UnitFormDialog;