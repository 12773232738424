import { Grid, Card, CardContent } from '@mui/material';
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import ZorgProfileDialog from './Dialog/ZorgProfileDialog'
import Container from '@mui/material/Container';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faEuroSign } from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";
import { EMPTY_GUID } from '../constants/common';
import { withSnackbar } from 'notistack';
import { Heart } from 'react-spinners-css';
import { getBootstrapWidthXs } from '../utils';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import DomainDropDown from './../components/DomainDropDown';

class ZorgProfilesPage extends React.Component {

    render() {
        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle="Zorgprofielen beheren" onAddFabClick={() => this.showZorgProfileDialog()}/>

                {this.props.currentUser?.IsSystemAdmin ?
                    <AppBar position="fixed" style={{ top: '56px', background: 'white' }}>
                        <Toolbar style={{ background: 'white' }} position="fixed">
                            <DomainDropDown onDomainSelected={(domainGuid) => this.onDomainUpdated(domainGuid)} />
                        </Toolbar>
                    </AppBar>
                    :
                    ''
                }

                {this.state.loading ? <div align="center" style={{ marginTop: '300px' }}><Heart color="#4AB897" size={100} /></div> :
                   
                        <Container maxWidth="xl" style={{marginTop: this.props.currentUser?.IsSystemAdmin ? '140px' : '75px'}}>
                            <Grid container spacing={2}>
                                {
                                    this.state.profiles.map((profile) => {
                                        let width = getBootstrapWidthXs(this.state.width)
                                        return (
                                            <Grid item xs={width} key={profile.Id} >
                                                <Card elevation={1}>
                                                    <CardHeader title={profile.Name} subheader={
                                                        <div>
                                                            {profile.Location !== null ? profile.Location.Name : profile.Organization !== null ? profile.Organization.Name : "Systeem"} <br /><FontAwesomeIcon icon={faClock} />&nbsp;{profile.SummaryTimeLimit.substr(0, 5)} / <FontAwesomeIcon icon={faEuroSign} />&nbsp; {profile.SummaryMoneyLimit}
                                                        </div>
                                                    }
                                                    />
                                                    <CardContent>
                                                        <Typography variant="body2" component="span"> {profile.Description}
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions disableSpacing>
                                                        <IconButton aria-label="Bewerk" onClick={() => this.showZorgProfileDialog(profile)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton aria-label="Delete" onClick={() => this.deleteZorgProfile(profile.Id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                            <ZorgProfileDialog
                                open={this.state.dialogOpen}
                                dialogAction={this.dialogAction}
                                currentZorgProfile={this.state.currentZorgProfile}
                                zorgProfileItems={this.state.zorgProfileItems}
                                systemZps={this.state.systemZps}
                                locations={this.state.locations}
                            />
                        </Container>
                   // </div>
                }
            </div>);
    }
    
    constructor(props) {
        super(props);
        this.state = { profiles: [], dialogOpen: false, currentProfile: {}, domains: [], currentDomainGuid: '', zorgProfileItems: [], systemZps:[], locations:[], width: window.innerWidth };
    }

    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };
    

    componentWillMount() {       
        window.addEventListener('resize', this.onWindowSizeChange);
    }
    
    async componentDidMount() {
        if (this.props.currentUser.IsSystemAdmin) {
            this.getAllDomains();
        }
        else {
            await this.setState({ currentDomainGuid: this.props.currentUser.CurrentDomainGuid });
            this.updateZorgProfilesList();
        }
    }

    getAllDomains = () => Api.getDomainsWithoutChilds()
    .then(res => {
        this.setState({ domains: res.data, currentDomainGuid: res.data[0].Id })
        this.updateZorgProfilesList();
    })
    .catch(err =>Api.logger.errorWithSnackbar(`Error while get domains`, this, err));

    onDomainUpdated = async (newDomainGuid) => {        
        await this.setState({currentDomainGuid : newDomainGuid});
        this.updateZorgProfilesList();
    }   

    updateZorgProfilesList = () => 
    {        
        this.setState({loading: true, currentZorgProfile: null}) // clean current to avoid caching
        var promise = (this.props.currentUser.IsSystemAdmin) ? Api.getZorgProfilesByDomain(this.state.currentDomainGuid) : Api.getZorgProfilesByOrg(this.props.currentUser.CurrentOrgGuid);
        
        promise.then(res => this.setState({ profiles: res.data, loading: false}))
        .catch(err => {
            this.setState({loading: false})
            Api.logger.errorWithSnackbar(`Error get Zorg profiles`, this,  err)});
    }

    deleteZorgProfile = (id) => Api.deleteZorgProfile(id).then(() => this.updateZorgProfilesList())
    .catch(err =>Api.logger.errorWithSnackbar(`Error deleting zp ${id}`, this,  err));

    showZorgProfileDialog = async (ZorgProfile) => {

        if (!this.props.currentUser.IsSystemAdmin) {
            Api.getSystemZp()
                .then(res => this.setState({ systemZps: res.data }))
                .catch(err => Api.logger.errorWithSnackbar(`Error while get system ZP`, this,  err));

            Api.getLocationsWithoutChilds(this.props.currentUser.CurrentOrgGuid)
                .then(res => {
                    res.data.unshift({ Id: EMPTY_GUID, Name: '-----' })
                    this.setState({ locations: res.data })
                })
                .catch(err => Api.logger.errorWithSnackbar(`Error while get locations`, this,  err));
        }

        if (ZorgProfile) {
            await this.setState({ currentZorgProfile: ZorgProfile, zorgProfileItems: ZorgProfile.Items, dialogOpen: true })
        }
        else
            Api.getTypesByDomain(this.state.currentDomainGuid)
                .then(async (res) => {
                    let items = [];
                    res.data.map((type) => items.push({ DidoType: type, TimeLimit: '00:00', MoneyLimit: '0.0' })); //todo to select-linq

                    await this.setState({ currentZorgProfile: null, zorgProfileItems: items, dialogOpen: true });
                })
                .catch(err => Api.logger.errorWithSnackbar(`Error while get types`, this,  err));


        //await this.setState({   })
    }

    dialogAction = (isCanceled, dialogResult) => {
        // console.log(JSON.stringify(dialogResult))
        // console.log(JSON.stringify(this.state))

        this.setState({ dialogOpen: false});
        
        if (isCanceled){
            return;
        }
        
        var orgGuid = (dialogResult.selectedLocationId == null || dialogResult.selectedLocationId == EMPTY_GUID) ? this.props.currentUser.CurrentOrgGuid : EMPTY_GUID;

        let param = {
            name: dialogResult.name,
            description: dialogResult.description,
            Organization: {Id: orgGuid},
            Location: {Id: dialogResult.selectedLocationId ?? EMPTY_GUID},
            SystemZorgProfile: {Id: dialogResult.selectedSystemZpId ?? EMPTY_GUID},
            items: dialogResult.zorgProfileItems,
            domain: this.props.currentUser.IsSystemAdmin ? this.state.domains.find(d => d.Id ===  this.state.currentDomainGuid) : {Id: this.props.currentUser.CurrentDomainGuid}
        }
        
        let promise; 

        if (!this.state.currentZorgProfile) { // dialog for new ZorgProfile            
            promise = Api.createZorgProfile(param);
        }
        else {
            param.id = this.state.currentZorgProfile.Id;
            promise = Api.updateZorgProfile(param);
        }

        promise.then(() => {  this.updateZorgProfilesList(); })
        .catch(err =>Api.logger.errorWithSnackbar(`Error while edit zp`, this,  err));      
    }

    

    
    
}

const mapStateToProps = (state) => {
     
    return {
        currentUser: state.didoReducer.currentUser
    }
  }

export default connect(mapStateToProps)(withSnackbar(ZorgProfilesPage));