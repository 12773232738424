import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid  from '@mui/material/Grid';
import { InputAdornment, MenuItem } from '@mui/material';
import { connect } from "react-redux";
import {Select} from "@mui/material"
import InputLabel from '@mui/material/InputLabel';
import { EMPTY_GUID, SNACK_ERROR } from '../../constants/common';
//import { KeyboardTimePicker  } from '@material-ui/pickers';
import * as Api from '../../services/api/common-api'
import { withSnackbar } from 'notistack';
import moment from 'moment';
import{ ThemeProvider, createTheme } from "@mui/material/styles";
import { styled } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const FormRow = styled('div')(({ theme }) => ({
     marginBottom: theme.spacing(1),
     marginTop: theme.spacing(1),    
  })); 

const materialTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#4AB897'
            }
        },
    }})

class ZorgProfileDialog extends React.Component {
    constructor(props) {
        super(props);

        this.flushState();
    }

    componentWillMount() {
        window.addEventListener('resize', this.onWindowSizeChange);
    }

    flushState = () => {
        const initialState = {
            name: '',
            description: '',
            selectedLocationId: null,
            selectedSystemZpId: null,
            zorgProfileItems : this.props.zorgProfileItems,
            zpAlreadyExists: false,
            width: window.innerWidth
        };
        if (this.state) this.setState(initialState)
        else this.state = initialState;

        this.onWindowSizeChange = this.onWindowSizeChange.bind(this);
    }

    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };

    // Name
    currentName = () => (this.props.currentZorgProfile) ? this.props.currentZorgProfile.Name : ""
    onNameChange = (evt) => this.setState({ name: evt.currentTarget.value })

    // Description
    currentDescription = () => (this.props.currentZorgProfile) ? this.props.currentZorgProfile.Description : ""
    onDescriptionChange = (evt) => this.setState({ description: evt.currentTarget.value })

    // SystemZp
    currentSystemZp = () => (this.state.selectedSystemZpId) ?
        this.state.selectedSystemZpId :
        (this.props.currentZorgProfile?.SystemZorgProfile) ?
            this.props.currentZorgProfile?.SystemZorgProfile.Id :
            this.props.systemZps.length > 0 ?
                this.props.systemZps[0].Id :
                null
                onSystemZpChange  = async (event) => {        
                    await this.setState({selectedSystemZpId : event.target.value});
                }
    
    // Location / Organization
    currentLocation = () => (this.state.selectedLocationId) ?
        this.state.selectedLocationId :
        (this.props.currentZorgProfile?.Location) ?
            this.props.currentZorgProfile?.Location.Id :
            this.props.locations.length > 0 ?
                this.props.locations[0].Id :
                null

    onLocationChange = async (event) => {
        await this.setState({ selectedLocationId: event.target.value});
    }

    saveDialog = async () => {
        if (!this.state.selectedSystemZpId){
            await this.setState({selectedSystemZpId : this.currentSystemZp()})
        }

        if (!this.state.selectedLocationId){
            await this.setState({selectedLocationId : this.currentLocation()})
        }

        await this.setState({zorgProfileItems: this.props.zorgProfileItems})

        this.props.dialogAction(false, this.state);
        this.flushState();
    }

    onOkClick = async () => {

        if (!this.props.currentUser.IsSystemAdmin) {
            var orgGuid = (this.currentLocation() === EMPTY_GUID) ? this.props.currentUser.CurrentOrgGuid : EMPTY_GUID;
            Api.isOrgZpExists(this.currentSystemZp(), this.currentLocation(), orgGuid)
                .then(async res => {
                    if (res.data === false) {
                        this.saveDialog()
                    }
                    else {
                        this.props.enqueueSnackbar('Zorgprofiel voor deze locatie bestaat al', SNACK_ERROR)
                    }
                })
        }
        else this.saveDialog();
    }

    onCancelClick = async () => {
        this.props.dialogAction(true)
        this.flushState();
    }

    zorgItemTimeChange = (evt, type) => {
       console.log(moment(evt).format("HH:mm"))
        if (evt == null)
        {
            type.TimeLimit = "00:00"            
        }
        if (evt){
            type.TimeLimit = moment(evt).format("HH:mm")
        
        }
        this.forceUpdate();
    }

    zorgItemMoneyChange = (evt, type) => {
        type.MoneyLimit = evt.target.value;
    }
    
    render() {
        //console.log(this.state.width)
        
        return (
            <Dialog open={this.props.open} {...(this.state.width > 600 ? {fullWidth: true} : {fullScreen: true})} >
                <DialogTitle>
                <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.currentZorgProfile ? "Zorgprofiel bewerken" : "ZP definitie toevoegen"}</Grid>
                        <Grid item xs={2} style={{textAlign: 'right'}}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div >
                        {this.props.currentUser.IsSystemAdmin ?
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <FormRow>
                                <TextField
                                    name="Name"
                                    required
                                    label="Code"
                                    defaultValue={this.currentName()}
                                    onChange={this.onNameChange}
                                    //size='small'
                                    margin='dense'
                                    fullWidth
                                />
                                </FormRow>
                                <FormRow>
                                <TextField
                                    name="Description"
                                    required
                                    label="Omschrijving"
                                    multiline
                                    margin='dense'
                                    defaultValue={this.currentDescription()}
                                    onChange={this.onDescriptionChange}
                                    fullWidth
                                />
                                </FormRow>
                            </div>
                            :
                            <div>
                                <div style={{ marginBottom: 20 }}>
                                    <InputLabel shrink>ZP definitie</InputLabel>
                                    <Select
                                        style={{ display: 'flex' }}
                                        fullWidth
                                        value={this.currentSystemZp()}
                                        onChange={(e) => this.onSystemZpChange(e)}
                                    >
                                        {
                                            this.props.systemZps !== null && this.props.systemZps.map(systemZp => {
                                                return (
                                                    <MenuItem key={systemZp.Id} value={systemZp.Id} > {systemZp.Name} </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                                <div >
                                    <InputLabel shrink>Plaats</InputLabel>
                                    <Select
                                        style={{ display: 'flex' }}
                                        fullWidth
                                        value={this.currentLocation()}
                                        onChange={(e) => this.onLocationChange(e)}
                                    >
                                        {
                                            this.props.locations !== null && this.props.locations.map(location => {
                                                return (
                                                    <MenuItem key={location.Id} value={location.Id} > {location.Name} </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                        }
                        <br /><br />

                        {
                            this.props.zorgProfileItems.map((zpItem) => {
                                
                                var durationDateObject = moment(zpItem.TimeLimit, 'HH:mm').toDate()
                                console.log(durationDateObject )
                                //zpItem.TimeLimit = '';
                                return (
                                    <div key={zpItem.DidoType.Id}>
                                        <Grid container spacing={0} alignItems="center" style={{ marginBottom: 20 }} >
                                            <Grid item xs={4}>{zpItem.DidoType.Name}</Grid>

                                            <Grid item xs={5}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        
        value={durationDateObject}
        onChange={(e) => this.zorgItemTimeChange(e, zpItem)}
        ampm={false}
        format="HH:mm"
        mask="__:__"
                                                        cancelText="Terug"
                                                        clearText="Wissen"
                                                        clearable
                                                        okText="Opslaan" 
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

                                               
                                            </Grid>
                                            <Grid item xs={1}>
                                                </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    defaultValue={zpItem.MoneyLimit}
                                                    onChange={(e) => this.zorgItemMoneyChange(e, zpItem)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                <b>€</b>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>

                                        </Grid>
                                    </div>
                                );
                            })
                        }

                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onOkClick} color="primary">
                        OPSLAAN
                    </Button>
                    <Button onClick={this.onCancelClick} color="primary">
                        TERUG
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
     
    return {
        currentUser: state.didoReducer.currentUser
    }
  }

export default connect(mapStateToProps)(withSnackbar(ZorgProfileDialog));