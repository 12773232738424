import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { withStyles } from "@mui/material/styles";
import { Avatar, Typography, Paper, Grid } from '@mui/material';
import  Timeline  from '@mui/lab/Timeline';
import  TimelineItem  from '@mui/lab/TimelineItem';
import  TimelineOppositeContent  from '@mui/lab/TimelineOppositeContent';
import  TimelineSeparator  from '@mui/lab/TimelineSeparator';
import  TimelineDot  from '@mui/lab/TimelineDot';
import  TimelineConnector  from '@mui/lab/TimelineConnector';
import  TimelineContent  from '@mui/lab/TimelineContent';
import Moment from 'react-moment';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import  IconButton   from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

Moment.globalLocal = false;

const styles = (theme) => ({
    color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 500
    },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2500',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
});


class KanbanTaskHistoryDialog extends React.Component {
    constructor(props) {
        super(props);

        this.flushState()
    }

    flushState = () => {
        const initialState = {
        };
        if (this.state) this.setState(initialState)
        else this.state = initialState;
    }

    render = () => {
      
        return (
            <Dialog open={this.props.open} fullWidth>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11} style={{ alignSelf: 'center' }}>Tijdspanne geschiedenis</Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={this.props.onClose}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                    <Timeline align="left" >
                      {
                          this.props.history.map(h => {
                              
                            //console.log("start " + h.EnteredAt)
                            //let now = (new Date()).toISOString().split('.')[0];
                            // let now = (new Date())
                            // console.log("end " + now)
                              const leftAt = h.LeftAt.includes('0001') ? Date.now() : new Date(h.LeftAt)
                              
                             
                              return(
                                  <TimelineItem key={h.Id} style={{ flex: 1 }} >
                                      <TimelineOppositeContent style={{ flex: 0.2 }} >
                                          <Typography color="textSecondary">{h.Board.Name}</Typography>
                                      </TimelineOppositeContent>
                                      <TimelineSeparator>
                                          <TimelineDot />
                                          <TimelineConnector />
                                      </TimelineSeparator>
                                      <TimelineContent>
                                          <Paper style={{padding: 15}} variant='outlined'>
                                          
                                          <Typography variant='body2'><AlarmOnIcon style={{fontSize: 16}}/> <Moment format="DD-MM-YYYY HH:mm">{h.EnteredAt}</Moment></Typography>
                                          <Typography variant='body2'><AlarmOffIcon style={{fontSize: 16}}/> <Moment format="DD-MM-YYYY HH:mm">{leftAt}</Moment></Typography>
                                          <Typography variant='body1'><TimelapseIcon style={{fontSize: 16}}/> <Moment duration={h.EnteredAt} date={leftAt} format="d [dagen] h [uur] m [min]" trim></Moment></Typography>
                                          {/* <Typography variant='body1'><TimelapseIcon style={{fontSize: 16}}/> <Moment duration={h.EnteredAt} date={leftAt} format="d [dagen] h [uur] m [min]" trim ></Moment></Typography> */}
                                              <hr size="1" />
                                              <div style={{overflow: 'auto',textOverflow: 'ellipsis', maxHeight: '60px'}}>
                                              <Avatar src={this.props.historyOfTask.Theme.Avatar} style={{ width: 32, height: 32, float: 'left', marginRight: '10px', marginBottom: '10px' }} />
                                              {this.props.historyOfTask.Objective}
                                              </div>
                                      </Paper>
                                      </TimelineContent>
                                      
                                  </TimelineItem>
                              );
                          })
                      }
                      </Timeline>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onClose()} color="primary">DICHTBIJ</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

//export default withStyles(styles, { withTheme: true })(KanbanTaskHistoryDialog);
export default KanbanTaskHistoryDialog;