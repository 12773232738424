import { Card, CardContent } from '@mui/material';
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import Container from '@mui/material/Container';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { withSnackbar } from 'notistack';
import { BasePage } from './BasePage';
import { getBootstrapWidthXs } from '../utils';
import DidoHeart from './../components/DidoHeart';
import { withRouter } from 'react-router-dom';
import { NEW_INFO_PAGE_URL } from '../constants/common';

class InfoPageManagement extends BasePage {
    componentDidMount() {
        this.getInfoPages();
    }


    getInfoPages = () => this.getItems(Api.getInfoPages, null, res => this.setState({ items: res.data }), `Fout bij ophalen van pagina's`);

    deleteInfoPage = (id) => this.deleteItem(id, Api.deleteInfoPage, () => { this.getInfoPages(); this.props.loadRoutes() }, "Succesvol verwijderd", "Fout op verwijderpagina")

    editPage = (page) => { this.props.history.push(page.Url, page); }

    newPage = () => this.props.history.push(NEW_INFO_PAGE_URL)

    renderItem(page) {
        let width = getBootstrapWidthXs(this.state.width)
        return (
            <Grid item xs={width} key={page.Id}>
                <Card elevation={3}>
                    <CardContent>
                        <Grid container spacing={0} alignItems="center" justify="center" onClick={() => this.editPage(page)}>
                            <Grid item xs={12}><Typography variant='h6'>{page.Title}</Typography></Grid>
                            <Grid item xs={12}>{page.Url}</Grid>
                        </Grid>
                    </CardContent>
                    <CardActions disableSpacing style={{ borderTop: '1px #EEE solid', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="Bewerk" onClick={() => this.editPage(page)}>
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton aria-label="Delete" onClick={() => this.deleteInfoPage(page.Id)}>
                            <DeleteIcon />
                        </IconButton>
                    </CardActions>
                </Card>
            </Grid>
        );
    }

    render() {
        return (
            <div>
                <DidoDrawer pageTitle="Beheer van infopagina's" onAddFabClick={() => this.newPage()} />
                <DidoHeart loading={this.state.loading} />


                <Container maxWidth="xl" style={{ marginTop: '75px' }}>
                    <Grid spacing={3} container>
                        {
                            this.state.items.map(page => this.renderItem(page))
                        }
                    </Grid>
                    {/* <ThemeDialog
                            open={this.state.dialogOpen}
                            onDialogClosed={this.onDialogClosed}
                            currentItem={this.state.currentItem}
                        /> */}
                </Container>


            </div>);
    }

}

export default withSnackbar(withRouter(InfoPageManagement));