import { styled, Paper } from '@mui/material';
import  Modal  from '@mui/material/Modal';
import { Heart } from 'react-spinners-css';

export const ActivePaper = styled(Paper)(({ theme }) => ({
    marginTop: '5px',
    marginBottom: '5px',
    height: '50px',
    verticalAlign: 'middle',
    background: 'white',
    //width:'90%',
    '&:hover': {
        background: '#EEE'
    }
}));