import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import * as Api from '../services/api/common-api'
import history from '../utils/history';
import { withSnackbar } from 'notistack';
import { Heart } from 'react-spinners-css';
import { GreenButton } from './../components/GreenButton';


class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', width: window.innerWidth, loading: false };


        this.emailChange = this.emailChange.bind(this);
        this.tryLogin = this.tryLogin.bind(this);
        this.onWindowSizeChange = this.onWindowSizeChange.bind(this);
    }

    componentWillMount() {
        window.addEventListener('resize', this.onWindowSizeChange);
    }

    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowSizeChange);
    }


    tryLogin(e) {
        console.log("tryLogin")
        this.setState({ loading: true })
        //e.preventDefault();
        const data = { EmailAddress: this.state.email };
        Api.sendInvite(data).then(response => {

            console.log("sendInvite")
            if (response.data === true) {
                // console.log("window.location = '/check_inbox'")
                window.location = '/check_inbox';
                //this.setState({loading: false})
                //history.push("/check_inbox");
            }
            else {
                history.push("/user_not_found");
            }

        }).catch(err => {
            console.log("Err")
            console.log(err)
            this.setState({ loading: false })
            Api.logger.errorWithSnackbar(`Fout tijdens inloggen, controleer of het e-mailadres van de gebruiker geldig is`, this, err)
        });
    }


    emailChange(e) {
        this.setState({ email: e.target.value });
    }


    render() {
                
        return (
            this.state.loading ?
                <div align="center" style={{ marginTop: '300px' }}><Heart color="#7AE8C7" size={130} /></div> :
                <div>
                    <Grid container spacing={0} direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: '100vh' }}>


                        <Grid item alignItems="center">
                            <Paper style={{ textAlign: "center", verticalAlign: "center", width: "300px", height: '500px', marginTop: '20vh' }}>                                
                                <form >                                    
                                    <div >
                                        <img src="/img/color-logo.png" alt="Logo" width="205px" height="126px" style={{ textAlign: "center", marginTop: "7vh" }} />
                                    </div>
                                    <div style={{ textAlign: "center", marginTop: "10vh" }}>
                                        <TextField
                                            name="email"
                                            required
                                            id="standard-required"
                                            label="E-mail"
                                            defaultValue=""
                                            margin="dense"
                                            onChange={this.emailChange}
                                        />
                                    </div>
                                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                                        <GreenButton variant="contained" type="submit" onClick={this.tryLogin}>LOG IN</GreenButton>
                                    </div>
                                </form>
                            </Paper>
                        </Grid>                        
                    </Grid>
                </div>
        );
    }
}


//export default  withSnackbar(withRouter(withStyles(styles, { withTheme: true })(LoginPage)));
export default withSnackbar(LoginPage);