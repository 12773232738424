export const TOKEN_KEY = 'accessToken';
export const USER_KEY = 'didoReducer';
export const UNAUTHORIZED_HTTP_CODE = 401;
export const LOAD_REPOS = 'LOAD_REPOS';
export const LOAD_REPOS_SUCCESS = 'LOAD_REPOS_SUCCESS';
export const LOAD_REPOS_ERROR = 'LOAD_REPOS_ERROR';
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const SNACK_ERROR = { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'left' } };
export const SNACK_SUCCESS = { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'left' } };
export const SNACK_WARNING = { variant: 'warning', anchorOrigin: { vertical: 'bottom', horizontal: 'left' } };
export const FREEMIUM_SIGN_UP_TEXT = 'Freemium_SignUp_Text'
export const NEW_INFO_PAGE_URL = '/newpage';
export const MESSAGE_TYPE_ASSISTANT = 0;
export const MESSAGE_TYPE_HUMAN = 1;
export const CHAT_TEMPLATE_KEY = 'PromptTemplate';
export const CHAT_TEMPERATURE = 'ChatTemperature';
export const CHAT_MAX_TOKENS = 'ChatMaxTokens';