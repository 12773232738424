/* eslint-disable default-case */
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ExpandMore, MoreHoriz } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { ContextMenu } from '../components/ContextMenu';
import { withSnackbar } from 'notistack';
import {SNACK_SUCCESS, SNACK_WARNING} from '../constants/common';
import { Heart } from 'react-spinners-css';
import queryString from 'query-string'
import { ActivePaper } from './../components/ActivePaper';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { TileDialog } from './Dialog/TileDialog';
import { connect } from "react-redux";
import actions from "../actions.js";

class DialogTreePage extends React.Component {    
    IconExpandedState = new Map();

    contextMenuAddTile = {key : 'addTile', title: 'Add Tile'};
    contextMenuAddSubTile = {key : 'addSubTile', title: 'Add sub-tile'};
    contextMenuSuggestSubTiles = {key : 'suggestSubTiles', title: 'Suggest sub-tiles', disabled: true};
    contextMenuEditTile = {key : 'editTile', title: 'Bewerk'};
    contextMenuMoveUpTile = {key : 'moveUpTile', title: 'Move up'};
    contextMenuMoveDownTile = {key : 'moveDownTile', title: 'Move down'};
    contextMenuDelete = {key : 'deleteTile', title: 'Verwijderen'};


    contextMenuTree = [ this.contextMenuAddSubTile, this.contextMenuSuggestSubTiles, this.contextMenuEditTile, this.contextMenuMoveUpTile, this.contextMenuMoveDownTile, this.contextMenuDelete ]
    contextMenuFabBtn = [ this.contextMenuAddTile, this.contextMenuSuggestSubTiles ]

    constructor(props) {
        super(props);
        this.state = { tiles: [], dialogOpen: false, anchorEl : null, contextMenuOptions:[], currentItem:{} };
    }

    componentDidMount() {
        this.getDialogTree();
    }

    getDialogTree = () => {
        this.setState({loading: true})
        var parsed = queryString.parse(window.location.search)
        
        Api.getDialogTree(parsed.orgId).then(res => {
            this.applyIconExpandedState(res.data)

            this.setState({ tiles: res.data, loading: false });
        }).catch(err => {
            this.setState({loading: false})
            Api.logger.errorWithSnackbar(`Error while loading tiles list`, this, err)});
    }

    applyIconExpandedState = (items) => {
        for (var i = 0; i< items.length; i++) {
            var item = items[i];
            if (this.IconExpandedState.has(item.Id)) // item was expanded before
            {
                item.IconExpanded = this.IconExpandedState.get(item.Id);
                item.Childs.forEach((child) => child.Visible = this.IconExpandedState.get(item.Id))
            }
            if (item.Childs != null && item.Childs.length > 0) {
                this.applyIconExpandedState(item.Childs);
            }
        }
    }

    showContextMenu = (event, item, contextMenuOptions) =>             
            this.setState({anchorEl: event.currentTarget, contextMenuItem: item, contextMenuOptions:  contextMenuOptions})
    

    contextMenuClick = (event, item, action) => {
        
        this.setState({ anchorEl: null, currentContextMenuItem: action })

        if (action === this.contextMenuAddTile) {
            this.setState({currentItem : null, dialogOpen: true})
        }
        else if (action === this.contextMenuAddSubTile) {
            this.setState({currentItem : null, dialogOpen: true, parentItem: item})
        }
        else if (action === this.contextMenuEditTile) {
            this.setState({currentItem : item, dialogOpen: true})
        }
        else if (action === this.contextMenuMoveUpTile) {
            Api.moveTileUp({Id: item.Id, parentId: item.ParentId})
                .then(() => { this.getDialogTree() })
                .catch(err => Api.logger.errorWithSnackbar(`Error while move up item=${item.Id}`, this, err));
        }
        else if (action === this.contextMenuMoveDownTile) {
            Api.moveTileDown({Id: item.Id, parentId: item.ParentId})
                .then(() => { this.getDialogTree() })
                .catch(err => Api.logger.errorWithSnackbar(`Error while move down item=${item.Id}`, this, err));
        }
        else if (action === this.contextMenuDelete) {
            if (item.Childs == null || item.Childs.length === 0) {
                Api.deleteTile(item.Id)
                    .then(() => { this.props.enqueueSnackbar("Succesvol verwijderd", SNACK_SUCCESS); this.getDialogTree() })
                    .catch(err => Api.logger.errorWithSnackbar(`Error on delete item=${item.Id}`, this, err));
            }
            else {
                this.props.enqueueSnackbar('Verwijder eerst de onderliggende gegevens ' + item.Name, SNACK_WARNING)
            }
        }
    }

    onDialogClosed = (dialogResult) => {

        this.setState({ dialogOpen: false });
        
        console.log(dialogResult)
        if (dialogResult.isCanceled){
            return;
        }       

        const formData = new FormData();
        console.log(dialogResult.file)
        if (dialogResult.file != null) // was changes
            formData.append("FormFile", dialogResult.file)

        formData.append("Name", dialogResult.name)
        formData.append("Prompt", dialogResult.prompt)
        if (this.state.parentItem){
            formData.append("ParentId", this.state.parentItem.Id)
        }

        var parsed = queryString.parse(window.location.search)
        formData.append("OrganizationId", parsed.orgId)

        let promise; 

        if (!this.state.currentItem) { // dialog for new             
            promise = Api.createTileWithUpload(formData);
        }
        else {
            formData.append("Id", this.state.currentItem.Id)
            promise = Api.updateTileWithUpload(formData);
        }

        promise
            .then(() => {
                this.props.enqueueSnackbar("Wijzigingen zijn toegepast", SNACK_SUCCESS);
                this.getDialogTree()
            })
            .catch(err => Api.logger.errorWithSnackbar(`Error on operation with tile`, this, err))
            .finally(() => this.setState({parentItem: null}));



    }

    // dialogAction = (isCanceled, dialogResult) => {

    //     this.setState({ dialogOpen: false });

    //     if (isCanceled) {
    //         return;
    //     }

    //     let param = {
    //         name: dialogResult.name,
    //     }

    //     if (this.state.currentItem) {
    //         param.id = this.state.currentItem.Id
    //         param.type = this.state.currentItem.type;

    //     }
    //     if (this.state.parentItem) {
    //         param.ParentId = this.state.parentItem.Id;
    //     }


    //     // todo save expand state and apply them to new model OR update only changed values  
    //     this.state.currentContextMenuItem.handle(param)
    //     .then(() => {this.props.enqueueSnackbar("Wijzigingen zijn toegepast", SNACK_SUCCESS); this.getDialogTree()})
    //     .catch(err =>Api.logger.errorWithSnackbar(`Fout tijdens het maken/bijwerken `, this, err));
    // }

    

    toggleExpandIcon = (item) => {        
        if (item.Childs != null) {
            item.Childs.forEach((child) => child.Visible = !child.Visible)
        }
        item.IconExpanded = !item.IconExpanded;

        this.IconExpandedState.set(item.Id, item.IconExpanded)
        
        this.forceUpdate();
    }
    
    renderExpandIcon(item) {
        let icon;
        if (item.Childs.length === 0) { // if terminal leaf
            icon = <div>&nbsp;&nbsp;</div>
        }
        else {
            icon = (item.IconExpanded == null || item.IconExpanded === false) ?
                <NavigateNextIcon /> :
                <ExpandMore />
        }   

        return (
            <IconButton disabled={item.Childs == null || item.Childs.length === 0} onClick={() => this.toggleExpandIcon(item)}>{icon}</IconButton>
        )
    }

    setVisible = (item, itemLevel) => (itemLevel !== 0 && (item.Visible == null || item.Visible === false)) ? 'none' : '';

    renderItem(item, itemLevel, neighborCount) {
        //item.type = this.itemTypeDescr[itemLevel];
        return (
            <div key={item.Id}
            style={{paddingLeft: itemLevel > 0 ? '40px':0, display: this.setVisible(item, itemLevel)}}
            >
                <ActivePaper>
                    <Grid container spacing={0} alignItems="center" justify="center">                        
                        <Grid item xs={8} style={{  overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis' }}>
                            {this.renderExpandIcon(item)}
                            <IconButton disabled>
                                {
                                    item.Avatar ?
                                    <img src={item.Avatar}  alt="" width={32} height={32}/>:
                                    <LightbulbIcon/>
                                }
                                </IconButton>
                            {item.Name}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' ,  overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis' }}>
                            <Typography color={'textSecondary'} variant={'body2'}>{item.Hits} hits, {item.TotalChildsCount} sub-tile</Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={(e) => {
                                var menu = [...this.contextMenuTree];
                                if (item.Order === 0){                                    
                                    menu.find(item => item === this.contextMenuMoveUpTile).disabled = true;
                                }
                                else if (item.Order === neighborCount-1){
                                    menu.find(item => item === this.contextMenuMoveDownTile).disabled = true;
                                }
                                return this.showContextMenu(e, item, menu);
                            }}>
                                <MoreHoriz color={'primary'}  />
                                </IconButton>
                        </Grid>
                    </Grid>
                </ActivePaper>
                {
                    (item.Childs != null) ? item.Childs.map((childItem) => this.renderItem(childItem, itemLevel + 1, item.Childs.length)) : ""
                }
            </div>
        )
    }


    render() {
        var parsed = queryString.parse(window.location.search)
        return (
            <div style={{transform: 'translateZ(0px)', flexGrow: 1,}} >
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle={`Dialog tree for ${parsed.orgName}`} 
                onAddFabClick={(e) => this.showContextMenu(e, null, this.contextMenuFabBtn)
                 //(e) => this.contextMenuClose(e, null, this.contextMenuItemAddDomain)
                 } />
                
                {this.state.loading ? <div align="center" style={{ marginTop: '300px' }}><Heart color="#4AB897" size={100} /></div> :
                <div>
                <Container maxWidth="xl" style={{marginTop: '75px'}}>
                    {this.state.tiles.map((t) => this.renderItem(t, 0, this.state.tiles.length))}
                </Container>
                <ContextMenu  style={{ zIndex: 1100 }}
                    anchorEl={this.state.anchorEl}
                    contextMenuClose={this.contextMenuClick}
                    contextMenuItem={this.state.contextMenuItem}
                    contextMenuOptions={this.state.contextMenuOptions}
                />
                <TileDialog
                            open={this.state.dialogOpen}
                            onDialogClosed={this.onDialogClosed}
                            currentItem={this.state.currentItem}
                        />
                {/* <ActivitiesDialog
                    open={this.state.dialogOpen}                    
                    currentItem={this.state.currentItem}
                    actionTitle={this.state.actionTitle}
                    dialogAction={this.dialogAction}
                /> */}
                </div>
        }
            </div>);
    }
}

export default connect((state)=>{    
    return {        
        currentOrg: state.didoReducer.currentOrg
    }}, actions)(withSnackbar(DialogTreePage));