import { styled } from '@mui/material';
import  Modal  from '@mui/material/Modal';
import { Heart } from 'react-spinners-css';

export default function DidoHeart(props) {
    return (
        <Modal open={props.loading ?? false} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div align="center" style={{ outline: 'none' }}><Heart color="#65F2C8" size={100} /></div>
                </Modal>
    );
}