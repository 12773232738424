import React from 'react';
import * as Api from '../services/api/common-api'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { connect } from "react-redux";
import actions from "../actions.js";
import {withSnackbar} from 'notistack';

class OrgDropDown extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {  orgs: [], currentOrgGuid: '' };
    }

    componentDidMount() {
        //this.getAllOrgs();
    }

    getAllOrgs = () => {
        if (this.state.orgs.length === 0) {
            Api.getOrganizationsWithoutChilds()
            .then(res => { // todo take from redux props
                const orgGuid =  res.data.length > 0 ? res.data[0].Id : '';
                this.setState({ orgs: res.data, currentOrgGuid: orgGuid})
                this.props.onOrgSelected(orgGuid);

            }).catch(err =>Api.logger.errorWithSnackbar(`Error in getAllOrgs`, this, err));
        }
    }

    onChangeOrg = async (event) => {        
        await this.setState({currentOrgGuid : event.target.value});
        this.props.onOrgSelected(event.target.value)
    }


    render(){
        this.getAllOrgs();
        
        let minWidth = '15vw';
        if (window.innerWidth < 900)
            minWidth = '30vw'

        return (
            <Select style={{ marginLeft: '1vw', width: minWidth}}
                        value={this.state.currentOrgGuid}
                        onChange={(e) => this.onChangeOrg(e)}>
                        {this.state.orgs.map((d) => <MenuItem key={d.Id} value={d.Id}>{d.Name}</MenuItem>)}
                    </Select>
        )
    }
}


//export default OrgDropDown;

export default connect((state)=>{ 

    return {
        currentUser: state.didoReducer.currentUser
    }}, actions)(withSnackbar(OrgDropDown));